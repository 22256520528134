<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="table-top">
                <div class="table-top-buttons">
                    <button-field @click="addNewUser()"
                                  icon="plus"
                                  color="primary">{{$ml.get('ADD_USER')}}
                    </button-field>
                </div>
<!--                <div class="table-top-search">-->
<!--                    {{$ml.get('Search')}}-->
<!--                </div>-->
                <div class="search-container">
                    <font-awesome-icon icon="search"/>
                    <input type="text" maxlength="50" name="searchInput"
                           v-model="searchString"
                           class="search-input form-field"
                           :placeholder="$ml.get('search_by_name')"
                    />
                </div>

            </div>
            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <b-table
                        :fields="tableColumns"
                        :items="tableRows"
                        :empty-html="emptyHtml"
                        class="font-small-2 table-vertical-align-middle table-list"
                    >

                        <template #cell(attributes.avatar)="data">
                            <div class="position-relative">
                                <span class="profile-avatar text-center">
                                    <img v-if="data.item.attributes.avatar"
                                         :src="data.item.attributes.avatar._url"
                                         :alt="data.item.attributes.firstName + ' ' + data.item.attributes.lastName"
                                    >
                                    <font-awesome-icon v-else color="gray" icon="user" />
                                </span>
                            </div>
                        </template>

                        <template #cell(attributes.firstName)="data">
                            {{data.item.attributes.firstName}} {{data.item.attributes.lastName}}
                        </template>

                        <template #cell(attributes.createdAt)="data">
                            {{niceDate(data.item.attributes.createdAt)}}
                        </template>

                        <template #cell(attributes.onboarded)="data">
                            <div class="ellipsis" :class="data.item.attributes.onboarded ? 'onboarded' : 'pending'"><span class="status-text">{{niceStatus(data.item)}}</span><span v-if="hasTrial(data.item)" class="trial-subscript">{{$ml.get('trial_until')}}: {{niceDate(data.item.attributes.trialUntil)}}</span></div>
                        </template>

                        <template #cell(attributes.lastLoginAt)="data">
                            {{niceDate(data.item.attributes.lastLoginAt)}}
                        </template>

                        <template #cell(actions)="data">
                            <div class="table-action-btn-gorup">
                                <button-field @click="copyLink(data.item)"
                                              circle
                                              outline
                                              v-tooltip="`Copy invitation link`"
                                              icon="link"
                                              class="table-action-btn"
                                              color="secondary"
                                              :disabled="data.item.attributes.onboarded"
                                ></button-field>

                                <button-field @click="editUser(data.item.id)"
                                              circle
                                              outline
                                              v-tooltip="`Edit user`"
                                              icon="edit"
                                              class="table-action-btn"
                                              color="primary"></button-field>
                                <button-field @click="suspendUser(data.item.id)"
                                              circle
                                              outline
                                              :activated="isSuspended(data.item)"
                                              v-tooltip="suspendMesssage(data.item)"
                                              icon="moon"
                                              color="dark"
                                              class="table-action-btn"></button-field>
                                <button-field @click="deleteUser(data.item.id)"
                                              circle
                                              outline
                                              v-tooltip="$ml.get('tooltip_DELETE_USER')"
                                              icon="trash-alt"
                                              class="table-action-btn"
                                              :disabled="forbidDelete(data.item)"
                                              color="error"></button-field>
                            </div>
                        </template>

                    </b-table>

                </div>

            </div>
            <div class="table-bottom">
                <div class="table-bottom-buttons">
                    <button-field @click="bulkDelete"
                                  icon="trash-alt"
                                  color="error"
                                  :disabled="!selectedRowsTrue.length">{{$ml.get('DELETE_SELECTED')}}
                        ({{selectedRowsTrue.length}})
                    </button-field>
                </div>

                <div v-if="false" class="table-pagination">
                    <ul class="pagination">
                        <li><a href="#">« {{$ml.get('Prev')}}</a></li>
                        <li><a href="#">1</a></li>
                        <li><a class="active" href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><span>...</span></li>
                        <li><a href="#">18</a></li>
                        <li><a href="#">{{$ml.get('Next')}} »</a></li>
                    </ul>
                </div>

                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import Parse from "parse";
    export default {
        name: "DashboardClients",
        computed: {
            cardCompany(){
                return this.$store.state.cardCompany;
            },
            curUser() {
                return Parse.User.current();
            },

            tableRows() {
                return this.$store.state.visibleUsers;
            },
            selectedRowsTrue() {
                let selected = [];
                for (const key in this.selectedRows) {
                    if (this.selectedRows[key]) {
                        selected.push(key);
                    }
                }
                return selected;
            }
        },
        data() {
            return {
                tableColumns: [
                    { key: 'attributes.avatar', label: '', thClass: 'tbl-pic font-bebas-neue',tdClass:'td-avatar', sortable: false},
                    { key: 'attributes.firstName', label: this.$ml.get('NAMES'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    { key: 'attributes.username', label: this.$ml.get('LOGIN_EMAIL'),  thClass: 'font-bebas-neue font-small-4', sortable: false},
                    { key: 'attributes.createdAt', label: this.$ml.get('CREATED_ON'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    { key: 'attributes.onboarded', label: this.$ml.get('STATUS'),  thClass: 'font-bebas-neue font-small-4', sortable: false},
                    { key: 'attributes.lastLoginAt', label: this.$ml.get('LAST_LOGGED_ON'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    { key: 'attributes.loginCount', label: this.$ml.get('LOGIN_COUNT'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    {
                        key: 'actions',
                        label: this.$ml.get('ACTIONS'),
                        thClass: 'font-bebas-neue text-center font-small-4',
                        tdClass: 'text-right',
                        stickyColumn: true,
                        sortable: false
                    }
                ],
                emptyHtml: '<div class="text-center"><font-awesome-icon icon="user" size="3x" color="gray" /><p class="mt-2">No data found</p></div>',
                perPageOptions: [
                    {
                        value: 10,
                        text: 10
                    },
                    {
                        value: 25,
                        text: 25
                    },
                    {
                        value: 50,
                        text: 50
                    },
                    {
                        value: 100,
                        text: 100
                    },
                ],
                perPageValue: 25,
                selectedRows: {},
                sharing: false,
                wizzardOpened: true,
                searchString: null,
                timeout: null
            }
        },
        created() {
            this.$store.dispatch('getClientUsers',{isAdmin: this.curUser.attributes.isAdmin});
        },
        methods: {
            doSearch(searchString){
                this.$store.dispatch('getClientUsers', {searchTerm : searchString, isAdmin: this.curUser.attributes.isAdmin})
            },
            hasTrial(item){
                return !!item.attributes.trialUntil;
            },
            copyLink(item){

                let invitationLink = window.location.protocol + '//' + window.location.host
                +'/auth/onboard/'+item.id+'/'+item.attributes.passwordReset;

                navigator.clipboard.writeText(invitationLink);

                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('INVITATION_LINK_COPIED')
                });

            },
            niceDate(date){
                return moment(date).format('DD/MM/Y');
            },
            niceStatus(item){
                if(item.attributes.suspended) {
                    return this.$ml.get('USER_SUSPENDED');
                }else {
                    if(item.attributes.onboarded){
                        return this.$ml.get('ONBOARDED');
                    }else {
                        return this.$ml.get('INVITATION_PENDING');
                    }
                }
            },
            suspendedRow(item){
                if(this.isSuspended(item)){
                    return 'suspended';
                }else{
                    return '';
                }
            },
            suspendMesssage(item){
                if(this.isSuspended(item)){
                    return this.$ml.get('RESTORE_USER');
                }else {
                    return this.$ml.get('SUSPEND_USER');
                }
            },
            isSuspended(item){
                return item.attributes.suspended;
            },
            openEditCompany(compId){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientsForm', data: {id: compId}})
            },
            suspendUser(id){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserSuspend', data: {ids: [id]}})
            },
            forbidDelete(item){
                return Boolean(item.id == this.curUser.id);
            },
            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName})
            },
            addNewUser(){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserForm', data: {id: null, companyId: this.cardCompany.id}})
            },
            editUser(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserForm', data: {id: id}})
            },
            deleteUser(id) {
                // DashboardEmployeeCardsDelete
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserDelete', data: {ids: [id]}})
            },
            bulkDelete() {
                this.$store.commit('toggleDashboardModal', {
                    name: 'DashboardUserDelete',
                    data: {ids: this.selectedRowsTrue}
                })
            },
        },
        watch : {
            cardCompany(){
                this.$store.dispatch('getClientUsers' );
            },
            searchString(newSearch) {
                clearTimeout(this.timeout);
                let self = this;
                self.timeout = setTimeout(() => {
                    self.doSearch(newSearch);
                }, 300);
            },
        }
    }
</script>

<style scoped>

    span.status-text {
        position: relative;
        display: block;
    }

    .onboarded {
        color: var(--color-secondary);
    }

    .pending {
        color: var(--color-primary);
    }

    .per-page-select {
        width: 110px;
    }
    .suspended {
        opacity: 0.5;
    }
    .avatar {
        max-height: 32px;
        max-width: 32px;
    }
    .company-logo {
        max-height: 16px;
        max-width: 40px;
        float: left;
        margin-right: 5px;
        margin-top: -1px;
    }
</style>
