<template>
    <div class="qf-right-modal-scroll-wrapper">

        <div class="position-sticky">
            <h3 class="text-center">
                {{$ml.get('sidebars.noteForm.title')}}
            </h3>
            <b-row>
                <b-col cols="12">
                    <button-field color="secondary" :icon="'plus'" v-b-modal.modal-add-note>
                        {{$ml.get('forms.notes.addNote')}}
                    </button-field>
                </b-col>
                <b-col cols="12">
                    <hr class="mb-0"/>
                </b-col>
            </b-row>
        </div>


        <b-row class="qf-right-modal-scroll font-small-3 pb-2">
            <transition-group name="fade" mode="out-in" >
                <b-col cols="12" v-if="notes.length > 0" :key="'list'">
                    <b-card
                        class="mt-2 qf-small-titles"
                        v-for="note in notes" :key="note.id"
                    >
                        <b-card-title class="font-small-3 d-flex justify-content-between">
                            <div class="ecb-channel-info">
                                <font-awesome-icon :icon="channelLabel(note).icon"/>
                                <span class="ml-2">{{channelLabel(note).label}}</span>
                            </div>
                            <div class="ecb-note-controls">
                                <font-awesome-icon icon="edit" class="cursor-pointer text-primary ml-2" @click="editNote(note)"/>
                                <font-awesome-icon icon="trash" class="cursor-pointer text-danger ml-2" @click="openDeleteModal(note)"/>
                            </div>
                        </b-card-title>
                        <b-card-sub-title class="font-small-2 d-flex justify-content-between">
                            <div class="ecb-note-meta">
                                {{noteSubtitle(note)}}
                            </div>

                            <div v-if="note.attributes.attachment" class="ecb-attachment-preview">
                                <b-link :href="note.attributes.attachment._url"
                                        target="_blank"
                                        v-b-tooltip="$trimFilename(note.attributes.attachment._name)">
                                    <font-awesome-icon icon="paperclip"/>
                                    {{ $trimFilename(note.attributes.attachment._name) | trimString(30)}}
                                </b-link>
                            </div>
                        </b-card-sub-title>
                        <b-card-text v-html="note.attributes.content">

                        </b-card-text>
                    </b-card>
                </b-col >
                <b-col cols="12" v-else class="text-center mt-4 position-absolute" :key="'placeholder'">
                    <p class="font-medium-3 text-muted"><em>{{$ml.get('forms.notes.noNotesYet')}}</em></p>
                    <p class="font-small-3 text-muted">{{$ml.get('forms.notes.noNotesYetClarification')}}.</p>
                </b-col>
            </transition-group>

        </b-row>

        <b-modal id="modal-add-note"
            :cancel-variant="'outline-dark'"
            :ok-variant="'secondary'"
            @ok="saveNote"
            @close="resetForm"
            :title="form.id ? $ml.get('forms.notes.editNote') : $ml.get('forms.notes.addNote')"
        >
            <b-row>

            </b-row>
            <b-row>
                <b-col cols="6">
                    <b-form-group :label="$ml.get('forms.notes.noteChannel')">
                        <b-form-select v-model="form.channel" :options="availableChannels" :required="true"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group :label="$ml.get('forms.notes.staffMember')">
                        <b-form-select v-model="form.author" :options="availableChannelsAuthors" :required="true"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6">
                    <b-form-group :label="$ml.get('forms.notes.noteDate')">
                        <b-form-datepicker v-model="form.date" :required="true"></b-form-datepicker>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group :label="$ml.get('forms.notes.attachment')">
                        <b-form-file class="font-small-2" v-model="form.attachment"></b-form-file>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$ml.get('forms.notes.noteContent')">
                        <b-form-textarea v-model="form.content" rows="5"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>

        </b-modal>


        <b-modal id="confirmDelete"
            :cancel-variant="'outline-dark'"
            :ok-variant="'danger'"
             @ok="deleteNote"
             :ok-title="$ml.get('forms.notes.deleteNote')"
        >
            <b-row>
                <b-col cols="12">
                    <h2>{{$ml.get('modals.notes.deleteModal.areYouSure')}}</h2>
                    <p>{{$ml.get('modals.notes.deleteModal.noRevert')}}</p>
                </b-col>
            </b-row>
        </b-modal>

    </div>

</template>

<script>

import {VBModal} from "bootstrap-vue";
import moment from 'moment';

export default {
    name: "NoteForm",
    directives: {
        'b-modal': VBModal
    },
    computed: {
        curUser() {
            return this.$store.state.user;
        },
        clientId(){
            let companyObj = this.$store.getters.selectedModalData('agencyClients');
            if(companyObj) {
                return companyObj.id;
            }
            else {
                return null;
            }
        },
        selectedInvoice() {
            return this.$store.getters.selectedModalData('selectedInvoices');
        },

        isFormDisabled(){
            return this.errors.length > 0;
        },
    },
    data() {

        return {
            selectedNote: null,
            attachmentPreview: {},
            form: {
                id: null,
                content: '',
                attachment: null,
                date: new Date(),
                channel: 'email',
                author: this.$store.getters.getUser,
            },
            availableChannels:
                [
                    {value: 'call', text: this.$ml.get('labels.noteStatus.call')},
                    {value: 'email', text: this.$ml.get('labels.noteStatus.email')},
                    {value: 'meeting', text: this.$ml.get('labels.noteStatus.meeting')},
                    {value: 'other', text: this.$ml.get('labels.noteStatus.other')},
                ]
            ,
            availableChannelsAuthors: [],
            loading: false,
            notes: [],
            errors: []
        }
    },
    created() {
        this.getAllNotes();
        this.getAvailableAuthors();
    },
    methods: {
        editNote(note){
            this.selectedNote = note;

            this.form.id = note.id;
            this.form.date = new Date(note.attributes.date);
            this.form.content = note.attributes.content;
            this.form.channel = note.attributes.channel;
            this.form.author = note.attributes.author.id;
            this.attachmentPreview = note.attributes.attachment;

            this.$bvModal.show('modal-add-note');
            console.log('edit note');
        },
        openDeleteModal(note){
            this.selectedNote = note;
            this.$bvModal.show('confirmDelete');
        },
        deleteNote(){
            this.$store.dispatch('deleteNote', this.selectedNote).then(() => {
                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('notification.success_deleted'),
                });
                this.selectedNote = null;
                this.getAllNotes();
            });
        },
        saveNote(){
            this.$store.dispatch('saveNote', {companyId: this.clientId, ...this.form}).then(() => {
                this.getAllNotes();
                this.resetForm();
                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('notification_success_saved'),
                });
            });
        },
        resetForm() {
            this.form = {
                id: null,
                content: '',
                attachment: null,
                date: new Date(),
                channel: 'email',
                author: this.curUser.id
            }

            this.attachmentPreview = {};
        },
        channelLabel(note) {
            let channelLabels = {
                call: {label: this.$ml.get('labels.noteStatus.call'), icon: 'phone'},
                email: {label: this.$ml.get('labels.noteStatus.email'), icon: 'envelope'},
                meeting: {label: this.$ml.get('labels.noteStatus.meeting'), icon: 'calendar-alt'},
                other: {label: this.$ml.get('labels.noteStatus.other'), icon: 'comment'},
            }
            if(channelLabels[note.attributes.channel]) {
                return channelLabels[note.attributes.channel];
            } else {
                return {label: this.$ml.get('labels.noteStatus.other'), icon: 'comment'};
            }
        },

        noteSubtitle(note){
            return moment(note.attributes.date).format('DD/MM/Y') + ' by ' + note.attributes.author.attributes.firstName + ' ' + note.attributes.author.attributes.lastName;

        },
        getAvailableAuthors(){
            this.$store.dispatch('getCoreUsers').then((users) => {
                this.availableChannelsAuthors = users.map((user) => {
                    return {value: user.id, text: user.attributes.firstName + ' ' + user.attributes.lastName}
                });

                this.form.author = this.curUser.id;
            });

        },
        getAllNotes(){
            this.$store.dispatch('getClientNotes', {companyId: this.clientId}).then((notes) => {
                this.notes = [];
                this.notes = notes;
            });
        },
    },
    watch: {
        // errors(newValue){
        //   //console.log("New errors: ", newValue);
        // },
        fileTrigger: {
            handler(newVal) {
                if(newVal){
                    this.imgFiles[newVal.field] = newVal.file;
                    this.previewFiles[newVal.field] = newVal.name;
                    this.defaultsClicked = 0;
                }
            },
            deep: true,
        },
    }
}
</script>

<style>

.right-modal:has(.qf-right-modal-scroll) {
    overflow: hidden;
    padding-bottom: 0;
}
.qf-right-modal-scroll-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.qf-right-modal-scroll {
    flex-grow: 1;
    overflow-y: auto;
    height: 100%;
}
</style>
