<template>
    <form class="employee-onboard"
          method="post"
          :action="`${apiUrl}cards/preview/?pr=1&so=1&comp=`+form.companyId+`&fs=`+form.frameSecret"
          ref="cardPreview"
          target="cardPreview"
    >
        <div class="row" v-if="false">
            <div class="col text-center login-logo-row">
                <img src="../../../assets/qfecard-logo-white.svg" alt="logo">
            </div>
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-6 animated-fadeIn" v-if="state != 4 && state !== 0">
                    <div class="card-designs">
                        <div class="wrapper-phone iphone sc06">
                            <iframe class="cardPreview"
                                    name="cardPreview"
                                    :src="frameUrl"
                                    frameborder="0">
                            </iframe>
                        </div>
                    </div>
                </div>
                <div class="col-6 animated-fadeIn" v-if="state != 4 || state !== 0">
                        <div v-if="state == 1" class="step-holder animated-fadeIn">
                            <p class="mt-0 mb-4">Please fill your personal information to get your electronic business card</p>
                            <hr>
                            <div class="row mt-5">
                            <div class="col-6">
                                <input-field :id="`first-name`"
                                             :name="'first-name'"
                                             :value="form.firstName"
                                             :required="true"
                                             @validation-result="validationCollector"
                                             v-model="form.firstName">{{$ml.get('FIRST_NAME')}}
                                </input-field>
                            </div>
                            <div class="col-6">
                                <input-field :id="`last-name`"
                                             :name="'last-name'"
                                             :value="form.lastName"
                                             :required="true"
                                             @validation-result="validationCollector"
                                             v-model="form.lastName">{{$ml.get('LAST_NAME')}}
                                </input-field>
                            </div>
                        </div>
                            <div>
                            <input-field :id="`position`"
                                         :name="'position'"
                                         :value="form.position"
                                             :required="true"
                                         @validation-result="validationCollector"
                                         v-model="form.position">{{$ml.get('POSITION')}}
                            </input-field>
                        </div>
                            <div>
                            <input-field :id="`department`"
                                         :name="'department'"
                                         :value="form.department"
                                         v-model="form.department">{{$ml.get('DEPARTMENT')}}
                            </input-field>
                        </div>
                            <div class="row">
                            <div class="col-6">
                                <input-field :id="`phone`"
                                             :name="'phone'"
                                             :value="form.mobilePhone"
                                             :required="true"
                                             @validation-result="validationCollector"
                                             v-model="form.mobilePhone">{{$ml.get('PRIMARY_PHONE')}}
                                </input-field>
                            </div>
                            <div class="col-6">
                                <input-field :id="`office-phone`"
                                             :name="`office-phone`"
                                             :value="form.officePhone"
                                             v-model="form.officePhone">{{$ml.get('SECONDARY_PHONE')}}
                                </input-field>
                            </div>
                        </div>
                            <div>
                                <input-field :id="`email`"
                                             :name="'email'"
                                             :value="form.email"
                                             :required="true"
                                             @validation-result="validationCollector"
                                             v-model="form.email">{{$ml.get('EMAIL')}}
                                </input-field>
                            </div>
                            <div id="address-selector">
                                <label class="form-label">SELECT YOUR OFFICE ADDRESS</label>
                                <Select2
                                    v-model="form.officeId"
                                    :options="selectAddresses"
                                    :settings="{
                                        placeholder: `Select a state`,
                                        templateResult: addressPreview,
                                        templateSelection: selectionPreview,
                                        escapeMarkup: function(stuff){return stuff}
                                    }"
                                />
                            </div>
                            <div class="row pt-4 justify-content-center">
                                <div class="col-12">
                                    <input type="hidden" name="officeId" :value="form.officeId"/>
                                    <input type="hidden" name="form-preview" value="1"/>
                                    <button-field
                                        color="secondary"
                                        type="submit"
                                        :disabled="isFormDisabled || loading"
                                        icon="plus"
                                        :loading="loading"
                                        @click="createCard"
                                        block>{{$ml.get('CREATE_MY_BUSINESS_CARD')}}
                                    </button-field>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="state == 2" class="step-holder animated-fadeIn">
                            <div class="text-center">
                                <img src="../../../assets/applause.svg" width="100" alt="applause">
                                <p>We have sent your electronic business card to the email address you provided {{form.email}}.</p>
                                <p>Please follow the instructions in the email to install it to your phone. </p>
                            </div>
                            <div class="buttons-container">
                                <button-field
                                    @click="takeMeToCard()"
                                    color="secondary"
                                    type="button"
                                    icon="share-square"
                                    block> {{$ml.get('TAKE_ME_TO_CARD')}}
                                </button-field>
                                <button-field
                                    @click="resendCard()"
                                    color="primary"
                                    type="button"
                                    icon="redo"
                                    :disabled="resendDisabled"
                                    :loading="resendLoading"
                                    block>{{resendText}}
                                </button-field>
                            </div>
                        </div>
                        <div v-else-if="state == 3" class="step-holder animated-fadeIn">
                            <div class="text-center">
                                <img src="../../../assets/completeed.svg" width="50" alt="completeed">
                                <h3>Your business card already exists!</h3>
                                <p>It appears that we have already created a business card for you.</p><p>If you can't find the original e-mail, please click on the button below to have it resent to your e-mail at
                                   <strong>{{form.email}}</strong></p>
                            </div>
                            <div class="buttons-container">
                                <button-field
                                    v-if="!alreadyHasCard"
                                    @click="setNewState(1)"
                                    color="dark"
                                    type="button"
                                    icon="arrow-left"
                                    block>{{$ml.get('RETURN_TO_FORM')}}
                                </button-field>
                                <button-field
                                    @click="resendCard()"
                                    color="primary"
                                    type="button"
                                    icon="redo"
                                    :disabled="resendDisabled"
                                    :loading="resendLoading"
                                    block>{{resendText}}
                                </button-field>
                            </div>
                        </div>
                </div>
              <div class="col-12">
                <div class="pre-loading text-center animated-fadeIn" v-if="state === 0">
                    <div class="spinner"></div>
                    <div>Loading your on-boarding form...</div>
                </div>
                <div class="text-center" v-if="state == 4" >
                    <font-awesome-icon :icon="`unlink`" :class="`color-primary`" :size="`4x`"></font-awesome-icon>
                </div>
                <div class="text-center invalid-link mt-5 animated-fadeIn" v-if="state == 4" >This link has expired!</div>
                <p class="small text-center mt-5">powered by <a href="https://qfecard.com" style="text-decoration: none;" target="_blank">qfecard</a></p>
              </div>
            </div>
        </div>
        <div class="row">

        </div>
    </form>
</template>

<script>
    import Select2 from "v-select2-component";
    export default {
        name: "EmployeeOnboard",
        components: {
          Select2
        },
        data() {
            return {
                apiUrl: process.env.VUE_APP_BASE_API_URL,
                state: 0,
                form: {
                    id: null,
                    firstName: '',
                    lastName: '',
                    position:'',
                    department: '',
                    mobilePhone: '',
                    officePhone:  '',
                    email: '',
                    officeAddress: {
                        id: 0
                    },
                    select: '',
                    officeId: null,
                    companyId: null,
                    frameSecret: null,
                },
                loading: false,
                resendDisabled: false,
                resendLoading: false,
                alreadyHasCard: false,
                cardSecret: null,
                errors: [],
                compAddresses: [],
                resendText: this.$ml.get('RESEND_WELCOME_EMAIL')
            }
        },
        mounted() {
            let self = this;

            this.form.frameSecret = this.$route.params.frameSecret ? this.$route.params.frameSecret : null;
            this.form.companyId = this.$route.params.compId ? this.$route.params.compId : null;

            let requestData = {
                compId: this.form.companyId,
                frameSecret: this.form.frameSecret,
            }
            this.$store.dispatch('getOnboardAddresses', requestData).then((resp)=>{
                self.compAddresses = resp.data;
                if(self.compAddresses){
                    self.form.officeId = self.compAddresses[0].objectId;
                }
                this.getTokens();
            }).catch(()=>{
                //console.log(err);
                self.setNewState(4);
            });

        },
        computed: {
            selectAddresses(){
                let addresses = [
                    /*
                    {
                        id: 0,
                        text: "Select an address",
                        disabled: true,
                        fullData: {
                            officeLabel: this.$ml.get('SELECT_OFFICE_ADDRESS'),
                            logo: {
                                _url: require('../../assets/noun-empty.svg')
                            }
                        }
                    }
                     */
                ];
                if(this.compAddresses.length > 0){
                    let i = 0;
                    this.compAddresses.forEach((address) => {
                        let element = {
                            id: address.objectId,
                            text: address.officeLabel,
                            fullData: address,
                        };
                        if(!i){
                            element.selected = true;
                            i++;
                        }
                        addresses.push(element);
                    })
                }
                return addresses;
            },
            isFormDisabled(){
                return this.errors.length > 0;
            },
            frameUrl(){
                let url = `${this.apiUrl}cards/preview/?pr=1&so=1&comp=`+this.form.companyId+`&fs=`+this.form.frameSecret
                if(this.alreadyHasCard && this.cardSecret){
                    url = `${this.apiUrl}cards/preview/?u=`+this.form.id+`&s=`+this.cardSecret+`&fm=1`;
                }
                return url;
            }
        },
        methods: {

            refreshPreview(fast) {
                clearTimeout(this.timeout);
                if(fast){
                    this.$refs.cardPreview.submit();
                }else{
                    this.timeout = setTimeout(() => {
                        if(this.$refs.cardPreview)
                            this.$refs.cardPreview.submit();
                    }, 200);
                }
            },

            storeTokens(cardId, cardSecret){
                let qfeStorage = window.sessionStorage;
                // Save data to sessionStorage
                qfeStorage.setItem('cardId', cardId);
                qfeStorage.setItem('cardSecret', cardSecret);
            },
            getTokens(){
                let qfeStorage = window.sessionStorage;
                this.form.id = qfeStorage.getItem('cardId');
                this.cardSecret = qfeStorage.getItem('cardSecret');
                if(this.form.id || this.cardSecret){
                    this.setNewState(3);
                    this.alreadyHasCard = true;
                }else {
                    this.setNewState(1);
                }
            },
            setNewState(stepNum) {
                let self = this;
                //self.state = 0;
                self.state = stepNum;
            //     setTimeout(function(){
            //         self.state = stepNum;
            //     }, 600);
            },
            takeMeToCard(){
                let cardLink = process.env.VUE_APP_BASE_API_URL+'/cards/?u='+this.form.id+'&s='+this.cardSecret;
                window.open(cardLink, '_blank');
            },
            resendCard(){
                this.resendLoading = true;
                this.resendDisabled = true;
                let self = this;
                this.$store.dispatch('sendSingleInvite', this.form).then(()=>{
                    //console.log("Resend invite resp", resp);
                    self.$notify({
                        group: 'notification',
                        type: 'success',
                        title: self.$ml.get('notification_card_resend'),
                    });
                    self.resendLoading = false;
                    self.resendTimeout();
                }).catch((err)=>{
                    self.resendLoading = false;
                    self.resendTimeout();
                    let errMsg = err.response.data ? err.response.data.message : err.message
                    self.$notify({
                        group: 'notification',
                        type: 'error',
                        title: errMsg,
                    });
                });
            },
            resendTimeout(){

                let self = this;
                let secLimit = 30;
                self.resendDisabled = true;

                setInterval(()=>{
                    self.resendText = self.$ml.get('RESEND_WELCOME_EMAIL')+'('+secLimit+')';
                    secLimit--;
                    if(secLimit <= 0){
                        self.resendText = self.$ml.get('RESEND_WELCOME_EMAIL');
                        self.resendDisabled = false;
                        clearInterval();
                    }
                }, 1000);

            },
            addressPreview(choice){
                let markup = '';
                if(choice.fullData){
                    let item = choice.fullData;
                    //console.log("select item: ", item);

                    markup = `<div class="company address-wrapper">`;
                        markup += `<h3 class="address-label">`+item.officeLabel+`</h3>`;
                        markup += `<span class="address-details">`+item.street+`, `+item.city+`, `+item.country+`</span>`;
                    markup +=`</div>`;
                }
                return markup;
            },
            selectionPreview(selection){
                let markup = '';
                if(selection.fullData){
                    let item = selection.fullData;
                    markup = `<div class="company address-wrapper selection">`;
                        markup += `<h3 class="address-label">`+item.officeLabel+`</h3>`;
                        markup += `<span class="address-details">`+item.street+`,`+item.city+`</span>`;
                    markup +=`</div>`;
                }
                return markup;
            },
            validationCollector(inputValidation){
                Array.prototype.remove = function() {
                    var what, a = arguments, L = a.length, ax;
                    while (L && this.length) {
                        what = a[--L];
                        while ((ax = this.indexOf(what)) !== -1) {
                            this.splice(ax, 1);
                        }
                    }
                    return this;
                };

                if(inputValidation.valid){
                    this.errors.remove(inputValidation.name);
                }else {
                    if(this.errors.indexOf(inputValidation.name) === -1){
                        this.errors.push(inputValidation.name);
                    }
                }
            },
            createCard(){
                let self = this;
                this.loading = true;

                this.$store.dispatch('selfOnboardEmployee', this.form).then((response)=>{
                    //console.log("Response ", response);
                    if(response.data){
                        self.form.id = response.data.newId;
                        self.cardSecret = response.data.secret;
                        self.storeTokens(response.data.newId, response.data.secret);
                    }
                    self.$notify({
                        group: 'notification',
                        type: 'success',
                        title: self.$ml.get('card_created_success'),
                    });
                    self.setNewState(2);
                    self.loading = false;

                }).catch((err)=>{
                        self.$notify({
                            group: 'notification',
                            type: 'error',
                            title: err.response.data.message,
                        });

                        self.loading = false;

                        if(err.response.status == 409){
                            self.form.id = err.response.data.employeeId;
                            self.setNewState(3);
                        }
                });
            }
        },
        watch: {
            form: {
                handler() {
                    if(this.state === 1){
                        this.refreshPreview();
                    }
                },
                deep: true
            }
        }
    }
</script>

<style>

#address-selector {
    width: 100%;
    height: 50px;
    position: relative;
}

#address-selector .select2-container {
    width: 100% !important;
}
#address-selector .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 1;
}
#address-selector .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0 !important;
}
#address-selector .select2-container .select2-selection--single {
    height: 40px;
    border: 0;
    border-bottom: 1px solid #e2e2e2;
    border-radius: 0;
}
#address-selector .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
    width: 28px;
}
#address-selector .select2-results__option {
    font-size: 14px;
    margin-bottom: 4px;
}
.select2-dropdown, .select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: #e2e2e2 !important;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    font-size: 10px;
    color: #000 !important;
    top: -11px;
    position: absolute;
    left: 0;
}
#address-selector > label.form-label {
    font-size: 10px;
    top: -11px;
    position: absolute;
    left: 0;
}
#address-selector {
    margin-top: 11px;
}


@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

.pre-loading {
    color: var(--color-secondary-dark);
}

.invalid-link {
    color: var(--color-error-dark);
}

.form-label {
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
    color: var(--color-dark);
    /* transition: all 0.25s ease-in-out; */
    text-transform: uppercase;
    font-size: 12px;
    cursor: text;
    pointer-events: none;
}

.step-holder {
    min-width: 320px;

}

.animated-fadeIn {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}

.buttons-container {
    display: flex;
}

.address-wrapper {
    display: block !important;
}

.address-label {
    width: 100%;
}

.address-details {
    width: 100%;
    clear: both;
    font-size: 0.75em;
    color: #999999;
}

/* VICTOR STYLE FIXES
.select2-container {
    min-width: 100%;
}

.select2-container--default .select2-selection--single {
    min-height: 38px;
    padding: 2px;
}


.company h3 {
    margin-right: 5px;
}

.address-details {
    font-size: 0.75em;
}

.select2-results__option--highlighted .address-details {
    color: white;
}
*/


.color-error > .form-label{
    color: var(--color-error);
}
.employee-onboard{
    height: 100vh;
    background: #fff;
}
.container {
    max-width: 760px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.container > .row{
    width: 100%;
}
.wrapper-phone {
    width: 432px;
    height: 878px;
    overflow: hidden;
    position: relative;
    box-sizing: content-box;
    width: 216px;
    height: 439px;
    margin: 0 auto;
}
.iphone:after {
    content: '';
    background-image: url('/img/iphone-wrapper.png');
    background-repeat: no-repeat;
    background-size: 216px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
}

.iphone.template::after {
    pointer-events: auto;
}
.iphone > iframe,
.iphone img{
    position: relative;
    transform: scale(0.5);
    transform-origin: 0 0;
    box-sizing: content-box;
    top: 12px;
    left: 14px;
    width: 376px;
    height: 815px;
    /*background: #000;*/
}
.sc06.wrapper-phone{
    width: 262px;
    height: 536px;
}
.sc06.iphone:after{
    background-size: 260px;
}
.sc06.iphone > iframe,
.sc06.iphone img{
    transform: scale(0.6);
    top: 11px;
    left: 14px;
    width: 386px;
    height: 836px;
}
hr{border-color: #ddd}
.spinner{
    position: relative;
    width: 30px;
    height: 30px;
    margin: 0 auto 10px;
}
.spinner:before{
    content: '';
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border-top: 3px solid #efa633;
    border-right: 3px solid transparent;
    -webkit-animation: spinner .6s linear infinite;
    animation: spinner .6s linear infinite;
    z-index: 0;
}

@keyframes spinner {
    to {
        transform: rotate(360deg)
    }
}
</style>
