<template>

    <div class="">
        <div v-if="trialXPNiceDate">
            <p class="text-center small mb-0">Your account's trial period expires on: <strong class="text-red">{{trialXPNiceDate}}</strong></p>
            <p class="text-center small mt-0">To continue your subscription you need to activate your account and confirm your subscription.</p>
        </div>

        <div v-else>
            <p  v-if="userCompany.attributes.subscribedOn"
                class="text-center small">Your account verified and active since <strong class="text-secondary">{{userCompany.attributes.subscribedOn | dateFormat}}</strong></p>
        </div>

        <pricing-table class="mb-3" @level="setActiveLevel" @count="setActiveCardCount"></pricing-table>

        <p class="text-center small mt-0">Your account will be billed at the next billing cycle, on <strong>{{nextBillingDate}}</strong>.</p>
        <p class="text-center small my-0">Based on your current usage, your account is tier T{{activeLevel.tier}} and your estimated bill will be <strong class="text-primary">{{ {currency: 'EUR', amount:billableEstimate } | currency }}</strong></p>

        <div v-if="trialXPNiceDate">
            <div class="row justify-content-center mt-3 mb-4">
                <div class="col-6">
                    <button-field color="secondary"
                                  type="submit"
                                  block
                                  @click="confirmSubscription"
                                  icon="check"
                    >
                        {{$ml.get('CONFIRM_SUBSCRIPTION')}}
                    </button-field>
                </div>
                <div class="col-12 pt-2">
                    <hr/>
                    <p class="text-center small mb-0">Need a formal contract? Request it by <a href="#" @click="requestContract">following this link</a> and filling out the form.</p>
                </div>
            </div>

        </div>

        <div v-else>
            <div class="row justify-content-center mb-3">
                <div class="col-12">
                    <hr/>
                    <p class="small mb-0 text-center w-100">No longer interested in using Qfecard?<br/><a :href="mailtoLink">Click here to terminate your subscription</a></p>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

import PricingTable from "@/components/global/elements/PricingTable.vue";
import Parse from "parse";
import moment from "moment/moment";

export default {
    name: "ActivateSubscription",
    components: {PricingTable},
    data() {
        return {
            tiers: [],
            activeLevel: {},
            confirm: false,
            loading: false,
            activeCardCount: 0,
            level: {},
            termsLink: '../../assets/docs/Qfecard Terms and conditions.pdf',
        }
    },
    computed: {

        mailtoLink() {
            return `mailto:followup@qfecard.com?subject=${encodeURIComponent(this.emailSubject)}&body=${encodeURIComponent(this.emailBody)}`;
        },
        emailSubject() {
            return `Request for subscription termination and data deletion - Qfecard - ${this.userCompany.get('name')}`;
        },
        emailBody() {
            return `Dear Qfecard Team,

I would like to request the cancellation of my account subscription and the permanent deletion of all account data associated with Qfecard for:

User: ${this.currentUser.get('username')}
Email: ${this.currentUser.get('email')}
Company: ${this.userCompany.get('name')}

Please remove all personal information, digital business cards, and any other data related to this account from your systems.

Thank you for your prompt attention to this matter.

Best regards,
${this.currentUser.get('firstName')} ${this.currentUser.get('lastName')}`;
        },
        currentUser(){
            return Parse.User.current();
        },
        userCompany(){
            return this.$store.state.userCompany;
        },
        nextBillingDate() {
            const today = moment();
            const thirdOfThisMonth = moment().startOf('month').add(2, 'days'); // 3rd of current month

            if (today.isBefore(thirdOfThisMonth)) {
                // If today's date is before the 3rd of the current month
                return thirdOfThisMonth.format('D/M/YYYY');
            } else {
                // If today's date is on or after the 3rd of the current month
                const thirdOfNextMonth = thirdOfThisMonth.add(1, 'month');
                return thirdOfNextMonth.format('D/M/YYYY');
            }
        },
        billableEstimate(){
            return this.activeCardCount && this.activeLevel ? this.activeCardCount * this.activeLevel.price : 1.00;
        },

        currentCardCount(){
            if(this.$store.state.userCompany) {
                return this.$store.state.userCompany.attributes.cardCount ? this.$store.state.userCompany.attributes.cardCount : 0;
            } else {
                return 0;
            }
        },

        trialXPNiceDate() {
            let curUser = Parse.User.current();
            let userTrial = curUser && curUser.attributes.trialUntil ? moment(curUser.attributes.trialUntil) : null;
            let companyTrial = this.userCompany && this.userCompany.attributes.trialUntil ? moment(this.userCompany.attributes.trialUntil) : null;
            let maxTrial = null;

            // Check if both dates are valid
            if (userTrial && userTrial.isValid() && companyTrial && companyTrial.isValid()) {
                maxTrial = moment.max(userTrial, companyTrial);
            } else if (userTrial && userTrial.isValid()) {
                // Only user trial date is valid
                maxTrial = userTrial;
            } else if (companyTrial && companyTrial.isValid()) {
                // Only company trial date is valid
                maxTrial = companyTrial;
            }

            return maxTrial ? maxTrial.format('D/MM/Y') : false;
        },

    },
    methods: {
        requestContract(){

            let ulr_base = 'https://www.qfecard.com/confirm-registration/';
            let curUser = Parse.User.current();
            let compData = this.$store.state.userCompany ? this.$store.state.userCompany.attributes : {atrributes: {}};

            if(curUser){
                let params = {
                    email: curUser.attributes.email,
                    firstName: curUser.attributes.firstName,
                    lastName: curUser.attributes.lastName,
                    company: compData.name,
                    phone: compData.telephone,
                    address: compData.billingAddress,
                    billingEmail: compData.billingEmail,
                    vat: compData.vatNumber,
                    responsible: compData.responsible,
                    website: compData.website,
                    companyId: this.$store.state.userCompany.id,
                }

                if(compData.usedPromo && compData.usedPromo.get('code')){
                    params.promo = compData.usedPromo.attributes.code;
                }

                let paramsString = JSON.stringify(params);
                let encodedParams = btoa(paramsString);

                let url = ulr_base +'?data='+ encodedParams;

                window.open(url, '_blank');
            }


        },
        setActiveCardCount(count){
            this.activeCardCount = count;
        },
        setActiveLevel(level){
            this.activeLevel = level;
        },
        confirmSubscription(){
            this.$router.push({name: 'activate-subscription'});
        },
    }

}

</script>

<style scoped>

</style>
