import Parse from 'parse'

export const InvoiceService = {

    getInvoices(context) {
        let invoice = Parse.Object.extend('Invoice');
        let query = new Parse.Query(invoice);
        query.include(context.state.userCompany);
        query.equalTo('company', context.state.userCompany);
        query.descending('issuedOn');
        return new Promise((resolve, reject) => {
            query.find().then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    },

    getClientInvoices(context, filters) {
        let invoice = Parse.Object.extend('Invoice');
        let query = new Parse.Query(invoice);

        let startOfYear = new Date(filters.year, 0, 1); // January 1 of current year
        let endOfYear = new Date(filters.year, 11, 31, 23, 59, 59, 999); // December 31 of current year

        let companyPointer = {"__type": "Pointer", "className": "Company", "objectId": filters.id}
        query.equalTo('company', companyPointer);

        query.greaterThanOrEqualTo("issuedOn", startOfYear);
        query.lessThanOrEqualTo("issuedOn", endOfYear);

        query.descending('issuedOn');
        return new Promise((resolve, reject) => {
            query.find().then(response => {
                context.commit('setSelectedInvoices', response);
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    },

    saveInvoice(context, invoice) {

        let invoiceObj = Parse.Object.extend('Invoice');
        let invoiceObjInstance = new invoiceObj();

        if(invoice.id) {
            invoiceObjInstance.set('objectId', invoice.id);
        }

        invoiceObjInstance.set('company', invoice.company);

        if(invoice.attachment !== null && invoice.attachment.name) {
            let file = new Parse.File(invoice.attachment.name, invoice.attachment);
            invoiceObjInstance.set('attachment', file);
        }

        let month = parseInt(new Date(invoice.issuedOn).getMonth())+1;
        let year = parseInt(new Date(invoice.issuedOn).getFullYear());
        let issuedOn = new Date(invoice.issuedOn)

        invoiceObjInstance.set('number', invoice.number);
        invoiceObjInstance.set('issuedOn', issuedOn);
        invoiceObjInstance.set('month', month);
        invoiceObjInstance.set('year',  year);
        invoiceObjInstance.set('amount', parseFloat(invoice.amount));
        invoiceObjInstance.set('netAmount', parseFloat(invoice.netAmount));
        invoiceObjInstance.set('currency', invoice.currency);

        if(invoice.paidOn){
            invoiceObjInstance.set('paidOn', new Date(invoice.paidOn));
        }

        if(typeof invoice.revolutLink !== 'undefined') {
            invoiceObjInstance.set('revolutLink', invoice.revolutLink);
        }

        // invoiceObjInstance.set('notes', invoice.notes);
        return new Promise((resolve, reject) => {
            invoiceObjInstance.save().then(response => {
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        });
    },
    deleteInvoice(context, invoiceId) {
        let invoice = Parse.Object.extend('Invoice');
        let query = new Parse.Query(invoice);
        return new Promise((resolve, reject) => {
            query.get(invoiceId).then(response => {
                response.destroy().then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err);
                })
            }).catch(err => {
                reject(err);
            })
        });
    }
};
