

export default [
	{
		path: '/signup-auto',
		name: 'signup-auto',
		component: () => import('@/components/public/signup/SignupAuto.vue'),
		meta: {
			access: 'public',
		},
	},
]
