<template>
    <div>
        <b-row>
            <b-col cols="12">
                <h3 class="text-center">
                    {{$ml.get('PRICING')}}
                </h3>
                <div class="text-center">
                    <b-img :src="compLogo" thumbnail class="my-2 mx-auto" width="150"/>
                    <h4 class="mb-0 mt-1">{{compName}}</h4>
                </div>
            </b-col>
        </b-row>

        <div class="row pt-4 justify-content-center">
            <div class="col-12" v-for="tier in tiersLvls" :key="`pricing-tier-input-${tier}`">
                    <b-form-group :label="tierLabel(tier)">
                        <b-form-input
                            :id="`pricing-tier-${tier}`"
                            :name="`pricing-tier-${tier}`"
                            type="number"
                            :step="0.01"
                            :value="parseFloat(pricingTiersData[`pricingTier${tier}`], 10).toFixed(2)"
                            v-model.number="pricingTiersData[`pricingTier${tier}`]"
                        >
                        </b-form-input>
                    </b-form-group>
            </div>
        </div>
        <div class="row pt-2 justify-content-between">
            <div class="col-6">
                <button-field color="secondary"
                              :icon="'save'"
                              @click="savePrices"
                              block>{{$ml.get('SAVE_PRICES')}}
                </button-field>
            </div>
            <div class="col-6">
                <button-field color="dark"
                              :icon="'history'"
                              @click="setDefaultPrices"
                              block>{{$ml.get('RESET_PRICES')}}
                </button-field>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PricingForm",
    data() {
        let dataSet = this.$store.state.dashboardModalComponent.data.dataSet ? this.$store.state.dashboardModalComponent.data.dataSet : 'masterAgencies';
        let initialAttr = this.$store.getters.selectedModalData(dataSet);

        return {
            compName: initialAttr ? initialAttr.attributes.name : '',
            compLogo: initialAttr && initialAttr.attributes.logo ? initialAttr.attributes.logo._url : '',
            isAgency: initialAttr ? initialAttr.attributes.isAgency : false,
            form: {
                isAgency: initialAttr ? initialAttr.attributes.isAgency : false,
            },
            pricingTiersData: {
                pricingTier1: 3.0,
                pricingTier2: 2.8,
                pricingTier3: 2.6,
                pricingTier4: 2.4,
                pricingTier5: 2.2,
                threshold1: 100,
                threshold2: 500,
                threshold3: 2000,
                threshold4: 10000,
                threshold5: 1000000
            },
            defaultAgencyTiers: {
                pricingTier1: 2.40,
                pricingTier2: 2.24,
                pricingTier3: 2.08,
                pricingTier4: 1.92,
                pricingTier5: 1.76,
                threshold1: 100,
                threshold2: 500,
                threshold3: 2000,
                threshold4: 10000,
                threshold5: 1000000
            },
            defaultClientTiers: {
                pricingTier1: 2.00,
                pricingTier2: 1.90,
                pricingTier3: 1.80,
                pricingTier4: 1.70,
                pricingTier5: 1.50,
                threshold1: 100,
                threshold2: 500,
                threshold3: 2000,
                threshold4: 10000,
                threshold5: 1000000
            },
            pricingObj: {},
            tiersLvls: [1, 2, 3, 4, 5]
        }
    },
    computed: {
        pricingTiers() {
            return this.pricingTiersData;
        },
        source() {
            return this.$store.state.dashboardModalComponent.data.dataSet ? this.$store.state.dashboardModalComponent.data.dataSet : 'agencyClients';
        },
        id() {
            return this.$store.state.dashboardModalComponent.data.id;
        },
    },
    created() {
        this.fetchPricingTiers();
    },
    methods: {
        tierLabel(tier) {
            let from = this.pricingTiersData[`threshold${tier-1}`] ? this.pricingTiersData[`threshold${tier-1}`] : 1;
            let to = this.pricingTiersData[`threshold${tier}`]-1;

            if(to > 10000) {
                return 'Tier '+tier+': More than '+ from + ' cards';
            }else {
                return 'Tier '+tier+': From '+ from +' to '+ to + ' cards';
            }

        },
        setDefaultPrices() {
            if (this.form.isAgency) {
                this.pricingTiersData = {...this.defaultAgencyTiers};
            } else {
                this.pricingTiersData = {...this.defaultClientTiers};
            }
        },
        savePrices() {
            this.$store.dispatch('savePricingTiers', {
                prices: this.pricingObj,
                newPrices: this.pricingTiersData
            }).then(() => {
                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('notification_success_prices'),
                });
                this.$store.commit('toggleDashboardModal');
            });
        },
        fetchPricingTiers() {
            if (this.id) {
                this.$store.dispatch('getCompanyPricing', this.id).then((resp) => {
                    this.pricingObj = resp;
                    this.pricingTiersData = {...resp.attributes};
                });
            }
        },
    },
}
</script>

<style scoped>
.form-label {
    position: relative;
    width: 100%;
    color: var(--color-dark);
    text-transform: uppercase;
    font-size: 10px;
    cursor: text;
    pointer-events: none;
}
</style>
