<template>
    <div class="container">
        <div class="auth-form" :class="isSuspended ? '' : 'wide'">
            <div class="row header-row">
                <div class="col text-center login-logo-row">
                    <img src="../../assets/qfecard-logo-white.svg" alt="logo">
                </div>
                <div class="eco-log-out-control" :class="$route.name == 'activate-subscription' ? 'left' : ''">
                    <font-awesome-icon
                        v-if="$route.name == 'activate-subscription'"
                        icon="arrow-left"
                        class="text-white cursor-pointer"
                        @click="back"
                        v-tooltip="$ml.get('BACK')"
                        size="lg"></font-awesome-icon>

                    <font-awesome-icon
                        v-if="$route.name == 'suspended'"
                        icon="sign-out-alt"
                        class="text-white cursor-pointer"
                        @click="logout"
                        v-tooltip="$ml.get('SIGN_OUT')"
                        size="lg"></font-awesome-icon>
                </div>
            </div>

            <div class="form-fields">
                <div id="link-invalid" class="text-center">
                    <h3 class="my-0" v-if="isSuspended"><font-awesome-icon  icon="heart-broken"></font-awesome-icon> Your account is suspended!</h3>

                    <h3 class="my-0"  v-else-if="trialDaysRemaining > 0"><font-awesome-icon  icon="clock"></font-awesome-icon> Only {{trialDaysRemaining}} days remaining from your trial!</h3>
                    <h3 class="my-0"  v-else-if="trialDaysRemaining == 0"><font-awesome-icon  icon="exclamation-triangle"></font-awesome-icon> Your trial period expires today!</h3>
                    <h3 class="my-0"  v-else-if="trialXPNiceDate"><font-awesome-icon  icon="heart-broken"></font-awesome-icon> Your trial has ended!</h3>
                    <h3 class="my-0"  v-else><font-awesome-icon  icon="check"></font-awesome-icon> Your account is now active!</h3>

                    <div class="space"></div>
                    <div class="welcome-separator"></div>

                    <div class="" v-if="isSuspended">
                        <p class="text-center">It appears that your Qfecard account is no longer active.</p>
                        <p class="text-center">If you think this has happened in error, please contact the administrator who had on-boarded you or write to <a href="mailto:hello@qfecard.com">hello@qfecard.com</a>.</p>
                        <hr/>
                        <div class="row justify-content-center mb-3">
                            <div class="col-4">
                                <button-field color="secondary"
                                              type="submit"
                                              block
                                              @click="logout"
                                              icon="sign-out-alt"
                                >
                                    {{$ml.get('LOG_OUT')}}
                                </button-field>
                            </div>
                        </div>
                    </div>

                    <div class="" v-else-if="trialXPNiceDate">

                        <transition-group name="fade" class="col-12" tag="div" mode="out-in">
                            <div class="subscribe-step-holder pt-1 pb-3" v-if="subscribeStep == 1" key="review">
                                <b-row>

                                    <b-col cols="12" class="text-center pb-2">
                                        <b-img
                                            :src="userCompany.attributes.logo ? userCompany.attributes.logo.url() : '../../assets/qfecard-logo-white.svg'"
                                            class="company-logo">

                                        </b-img>
                                    </b-col>

                                    <b-col cols="12">
                                        <p class="text-center small mb-0" v-if="trialDaysRemaining > 0">Your account's trial period for <strong>{{userCompany.attributes.name}}</strong> will expire on: <strong class="text-danger">{{trialXPNiceDate}}</strong></p>
                                        <p class="text-center small mb-0" v-else-if="trialDaysRemaining == 0">Your account's trial period for <strong>{{userCompany.attributes.name}}</strong> expires <strong class="text-danger">today!</strong></p>
                                        <p class="text-center small mb-0" v-if="trialDaysRemaining < 0">Your account's trial period for <strong>{{userCompany.attributes.name}}</strong> had expired on: <strong class="text-danger">{{trialXPNiceDate}}</strong></p>
                                        <p class="text-center small mt-0">To continue using Qfecard you need to activate your account and confirm your subscription.</p>
                                    </b-col>
                                </b-row>


                                <b-row class="pb-3">
                                    <b-col cols="12">
                                        <p class="text-center small mb-0">Please review your billing details and proceed to the next step.</p>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12">
                                        <validation-observer ref="companyDetails">
                                            <b-row>
                                                <b-col cols="6">
                                                    <b-form-group label="Company legal name" class="required">
                                                        <validation-provider
                                                            name="company name"
                                                            rules="required"
                                                            v-slot="{ errors }">
                                                            <b-form-input
                                                                v-model="form.name"
                                                                placeholder="Company name"
                                                                :state="errors.length > 0 ? false : null"
                                                                required
                                                            ></b-form-input>
                                                            <div class="position-relative"><small class="text-danger qf-input-error">{{ errors[0] }}</small></div>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="6">
                                                    <b-form-group label="VAT Number" class="required">
                                                        <validation-provider
                                                            rules="required"
                                                            name="VAT number"

                                                            v-slot="{ errors }">
                                                            <b-form-input v-model="form.vatNumber"
                                                                          placeholder="VAT Number"
                                                                          :state="errors.length > 0 ? false : null"
                                                            >
                                                            </b-form-input>
                                                            <div class="position-relative"><small class="text-danger qf-input-error">{{ errors[0] }}</small></div>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="6">
                                                    <b-form-group label="Responsible person">
                                                        <b-form-input v-model="form.responsible"
                                                                      placeholder="Responsible person">
                                                        </b-form-input>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="6">
                                                    <b-form-group label="Billing Email" class="required">
                                                        <validation-provider
                                                            rules="required|email"
                                                            name="billing email"
                                                            v-slot="{ errors }">
                                                            <b-form-input v-model="form.billingEmail"
                                                                          placeholder="Billing Email"
                                                                          :state="errors.length > 0 ? false : null"
                                                            >
                                                            </b-form-input>
                                                            <div class="position-relative"><small class="text-danger qf-input-error">{{ errors[0] }}</small></div>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col cols="6">
                                                    <b-form-group label="Billing Address" class="required">
                                                        <validation-provider
                                                            rules="required"
                                                            name="billing address"
                                                            v-slot="{ errors }">
                                                            <b-form-input v-model="billingAddress"
                                                                          placeholder="Billing Address"
                                                                          :state="errors.length > 0 ? false : null"
                                                            >
                                                            </b-form-input>
                                                            <div class="position-relative"><small class="text-danger qf-input-error">{{ errors[0] }}</small></div>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col cols="6">
                                                    <b-form-group label="Preferred contract language">
                                                        <b-form-select label="Language" v-model="lang" :options="languageOptions" required></b-form-select>
                                                    </b-form-group>
                                                </b-col>

                                            </b-row>
                                        </validation-observer>
                                    </b-col>
                                </b-row>


                                <b-row>
                                    <b-col cols="12">
                                        <button-field color="secondary"
                                                      class="w-25"
                                                      type="submit"
                                                      block
                                                      @click="reviewBilling"
                                                      icon="arrow-right"
                                        >
                                            {{$ml.get('buttons.NEXT')}}
                                        </button-field>
                                    </b-col>
                                </b-row>


                                <b-row class="pt-3">
                                    <b-col cols="12">
                                        <p class="text-center text-muted font-small-1 mb-0">
                                            In compliance with the EU's GDPR legislation, your account data and all records associated with it, including all existing digital business cards will be permanently deleted 1 year after your trial had expired. If you do not plan to use the services offered by Qfecard and would like to have all of your account data permanently erased now, write to us
                                            <a :href="mailtoLink">by clicking here</a>.
                                        </p>
                                    </b-col>
                                </b-row>


                            </div>

                            <div class="subscribe-step-holder pt-2 pb-3"  v-if="subscribeStep == 2" key="confirm">


                                <pricing-table class="mb-3" @level="setActiveLevel" @count="setActiveCardCount"></pricing-table>

                                <b-row>
                                    <b-col cols="12">
                                        <p class="text-center small my-0">Your account will be billed at the next billing cycle, starting on {{nextBillingDate}}.</p>
                                        <p class="text-center small mt-0">Based on your current usage, your estimated bill will be <span class="text-primary">{{ {currency: 'EUR', amount:billableEstimate } | currency }}</span></p>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12">
                                        <div class="my-3">
                                                <b-form-checkbox
                                                    v-model="confirm"
                                                    name="confirm"
                                                    :value="true"
                                                    required
                                                    >
                                                    I agree to become an active customer of Qfecard in accordance<br/> with the standard
                                                    <b-link :href="termsLink" target="_blank">License agreement</b-link>
                                                </b-form-checkbox>

                                        </div>
                                    </b-col>
                                    <b-col cols="12">
                                        <button-field color="secondary"
                                                      type="submit"
                                                      class="w-50"
                                                      block
                                                      @click="confirmSubscription"
                                                      icon="check"
                                                      :disabled="!confirm"
                                        >
                                            {{$ml.get('CONFIRM_SUBSCRIPTION')}}
                                        </button-field>
                                    </b-col>
                                </b-row>

                            </div>

                        </transition-group>

                    </div>

                    <div class="pt-3" v-else>
                        <p class="text-center my-1">You can now enjoy the full functionality of the platfrom!</p>
                        <div class="row justify-content-center my-3 pt-3">
                            <div class="col-4">
                                <button-field color="secondary"
                                              type="submit"
                                              block
                                              @click="backToDashboard"
                                              icon="home"
                                >
                                    {{$ml.get('BACK_TO_DASHBOARD')}}
                                </button-field>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import Parse from "parse";
    import PricingTable from "@/components/global/elements/PricingTable.vue";
    import {VBTooltip} from "bootstrap-vue";
    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import { required, email, phoneNumber, positive } from '@/utils/validations/validations';
    import Api from "@/store/services/Api";

    export default {
        name: "ProfileSuspended",
        components: {
            PricingTable,
            ValidationObserver,
            ValidationProvider
        },
        directives: {
            'b-tooltip': VBTooltip
        },
        setup() {
            return {

                required,
                email,
                phoneNumber,
                positive
            }
        },
        data() {
            return {
                form: {
                    name: '',
                    vatNumber: '',
                    responsible: '',
                    billingEmail: '',
                    billingAddress: '',
                    licenseId: null,
                },
                billingAddress: '',
                lang: 'en',
                languageOptions: [
                    { text: 'English', value: 'en' },
                    { text: 'German', value: 'de' },
                    { text: 'Bulgarian', value: 'bg' },
                ],
                subscribeStep: 1,
                confirm: false,
                loading: false,
                activeCardCount: 0,
                termsLink: '../../assets/docs/Qfecard Terms and conditions.pdf',
                level: {},
            }
        },
        created() {
            this.fetchTermsDocument();
        },
        computed: {
            userCompany(){
                return this.$store.state.userCompany;
            },
            isSuspended(){
                let curUser = Parse.User.current();
                let compData = this.$store.state.userCompany.attributes;

                if((curUser && curUser.attributes.suspended) || (compData && compData.suspended)){
                    return true;
                }else {
                    return false;
                }
            },
            trialDaysRemaining(){
                let companyTrial = this.userCompany && this.userCompany.attributes.trialUntil ? moment(this.userCompany.attributes.trialUntil) : null;
                if (companyTrial) {
                    let remainingTrial = companyTrial.diff(moment(), 'days');
                    return remainingTrial;
                } else {
                    return 0;
                }
            },

            currentUser() {
                return Parse.User.current();
            },
            emailSubject() {
                return `Request for Account Data Deletion - Qfecard - ${this.userCompany.get('name')}`;
            },
            emailBody() {
                return `Dear Qfecard Team,

I would like to request the permanent deletion of all account data associated with Qfecard for:

User: ${this.currentUser.get('username')}
Email: ${this.currentUser.get('email')}
Company: ${this.userCompany.get('name')}

Please remove all personal information, digital business cards, and any other data related to this account from your systems.

Thank you for your prompt attention to this matter.

Best regards,
${this.currentUser.get('firstName')} ${this.currentUser.get('lastName')}`;
            },
            mailtoLink() {
                return `mailto:followup@qfecard.com?subject=${encodeURIComponent(this.emailSubject)}&body=${encodeURIComponent(this.emailBody)}`;
            },

            billableEstimate(){
                return this.activeCardCount && this.activeLevel ? this.activeCardCount * this.activeLevel.price : 1.00;
            },

            currentCardCount(){
                return this.$store.state.userCompany.attributes.cardCount ? this.$store.state.userCompany.attributes.cardCount : 0;
            },

            trialXPNiceDate() {
                let curUser = Parse.User.current();
                let userTrial = curUser && curUser.attributes.trialUntil ? moment(curUser.attributes.trialUntil) : null;
                let companyTrial = this.userCompany && this.userCompany.attributes.trialUntil ? moment(this.userCompany.attributes.trialUntil) : null;

                let maxTrial = null;

                // Check if both dates are valid
                if (userTrial && userTrial.isValid() && companyTrial && companyTrial.isValid()) {
                    maxTrial = moment.max(userTrial, companyTrial);
                } else if (userTrial && userTrial.isValid()) {
                    // Only user trial date is valid
                    maxTrial = userTrial;
                } else if (companyTrial && companyTrial.isValid()) {
                    // Only company trial date is valid
                    maxTrial = companyTrial;
                }

                return maxTrial ? maxTrial.format('D/MM/Y') : false;
            },

            nextBillingDate() {
                const today = moment();
                const thirdOfThisMonth = moment().startOf('month').add(2, 'days'); // 3rd of current month

                if (today.isBefore(thirdOfThisMonth)) {
                    // If today's date is before the 3rd of the current month
                    return thirdOfThisMonth.format('D/M/YYYY');
                } else {
                    // If today's date is on or after the 3rd of the current month
                    const thirdOfNextMonth = thirdOfThisMonth.add(1, 'month');
                    return thirdOfNextMonth.format('D/M/YYYY');
                }
            }

        },

        methods: {
            reviewBilling(){
                this.$refs.companyDetails.validate().then(success => {
                    if (success) {
                        this.subscribeStep = 2;
                    }
                });
            },
            confirmSubscription(){
                this.$store.commit('setGlobalLoader', true);
                this.form.nextBillingDate = moment(this.nextBillingDate, 'D/M/YYYY').toDate();
                this.form.lang = this.lang;
                this.form.billingAddress = this.billingAddress;

                this.$store.dispatch('confirmSubscription', this.form).then(()=>{
                    this.$notify({
                        group: 'notification',
                        type: 'success',
                        text: this.$ml.get('notification.subscription_confirmed'),
                    });

                    this.$router.push({name: 'home'});
                    this.$store.commit('setGlobalLoader', false);

                    let formData = new FormData();
                    formData.append('action', 'sendSubscribeEmails');

                    let payload = {
                        senderId : this.currentUser.id,
                        compId: this.userCompany.id,
                        frameSecret: this.userCompany.attributes.frameSecret,
                        nextBillingDate: this.nextBillingDate,
                    }

                    formData.append('data', JSON.stringify(payload));

                    Api().post('helpers/api.php', formData).then((response) => {
                        console.log('Subscription confirmed', response);
                    }).catch((error) => {
                        console.error('Error while confirming subscription', error);
                    });
                }).catch((error) => {
                    this.$notify({
                        group: 'notification',
                        type: 'danger',
                        text: error.message,
                    });
                    this.$store.commit('setGlobalLoader', false);
                });
            },
            fetchTermsDocument(lang='en'){
                let query = new Parse.Query('ProtectedDoc');
                query.equalTo('type', 'subscribe_terms');
                query.equalTo('language', lang);
                query.descending('revision');
                query.first().then((doc) => {
                    if(doc){
                        this.termsLink = doc.attributes.attachment.url();
                        this.form.licenseId = doc.id;
                    }
                }).catch((error) => {
                    console.error('Error while fetching terms document', error);
                });
            },
            backToDashboard(){
                this.$router.push({name: 'home'});
            },
            setActiveCardCount(count){
                this.activeCardCount = count;
            },
            setActiveLevel(level){
                this.activeLevel = level;
            },
            openSubscribe(){

                let ulr_base = 'https://www.qfecard.com/subscribe';
                let curUser = Parse.User.current();
                let compData = this.$store.state.userCompany.attributes;

                let params = {
                    email: curUser.attributes.email,
                    firstName: curUser.attributes.firstName,
                    lastName: curUser.attributes.lastName,
                    company: compData.name,
                    phone: compData.telephone,
                    address: compData.billingAddress,
                    billingEmail: compData.billingEmail,
                    vat: compData.vatNumber,
                    responsible: compData.responsible,
                    website: compData.website,
                    companyId: this.$store.state.userCompany.id,
                }

                if(compData.usedPromo && compData.usedPromo.get('code')){
                    params.promo = compData.usedPromo.attributes.code;
                }

                let paramsString = JSON.stringify(params);
                let encodedParams = btoa(paramsString);

                let url = ulr_base +'?data='+ encodedParams;

                window.open(url, '_blank');

            },
            back(){
                this.$router.go(-1);
            },

            logout() {
                this.$store.dispatch('logout').then(() => {
                    this.$store.commit('setIsAuthenticated', false);
                    this.$store.commit('setUser', null);
                    this.$router.push('/auth');
                });
            },
        },
        watch: {
            billingAddress: function (newAddress) {
                if(newAddress.includes('Bulgaria')){
                    this.lang='bg';
                }else if (
                    newAddress.includes('Germany') ||
                    newAddress.includes('Deutschland') ||
                    newAddress.includes('Austria') ||
                    newAddress.includes('Switzerland') ||
                    newAddress.includes('Liechtenstein')
                ){
                    this.lang = 'de';
                }else {
                    this.lang = 'en';
                }
            },
            lang: function (val) {
                this.fetchTermsDocument(val);
            },
            userCompany: {
                handler: function (val) {
                    if(val && val.id){
                        this.billingAddress = val.attributes.billingAddress;
                        this.form = {
                            name: val.attributes.billingName ? val.attributes.billingName : val.attributes.name,
                            vatNumber: val.attributes.vatNumber === 'N/A' ? '' : val.attributes.vatNumber,
                            responsible: val.attributes.responsible,
                            billingEmail: val.attributes.billingEmail,
                        }
                    }
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style>
.company-logo {
    max-width: 120px;
    max-height: 120px;
    margin: 0 auto;
}
</style>


<style scoped>
    .header-row {
        position: relative;
    }
    .eco-log-out-control {
        position: absolute;
        top: 12px;
        right: 10px;
        padding: 10px;
        cursor: pointer;
    }

    .eco-log-out-control.left {
        right: unset;
        left: 10px;
    }

    .auth-form.wide {
        max-width: 720px;
    }

    .container {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .welcome-separator {
        width: 100%;
        border-top: 1px solid var(--color-secondary);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .space {
        width: 100%;
        background: transparent;
        opacity: 0;
        width: 100%;
        height: 10px;
    }

    .welcome-message {
        font-size: 0.8em;
    }

    .form-loader, .form-fields {
        min-height: 290px;
    }

    .invitation-loading {
        width: 100%;
        text-align: center;
        padding-top: 100px;
    }

    .company-logo{
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #000000;
    }
</style>
