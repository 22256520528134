import Parse from 'parse'
//import moment from 'moment'

export const PromoService = {

    getPromos(context, data) {
        let Promo = Parse.Object.extend('Promo');
        let query = new Parse.Query(Promo);
        query.descending('createdAt');
        query.limit(1000);

        if(data && data.promoCode){
            let regEx = '.*'+data.promoCode+".*";
            query.matches('code', regEx, 'i');
        }

        if(data && !data.showExpired){
            query.greaterThanOrEqualTo('validUntil', new Date());
        }

        return new Promise((resolve, reject) => {
            query.find().then(response => {
                context.commit('setPromos', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },
    savePromo(context, promo) {


        let promoObj = Parse.Object.extend("Promo");
        let newPromo = new promoObj();

        if(promo.id) {
            newPromo.set('id', promo.id);
        }

        // Ensure `promo.validUntil` is a Date object
        const validUntilDate = promo.validUntil instanceof Date
            ? promo.validUntil
            : new Date(promo.validUntil);
        //
        // console.log('validUntilDate', validUntilDate);
        //

        newPromo.set('code', promo.code);
        newPromo.set('validUntil', validUntilDate);
        newPromo.set('description', promo.description);
        newPromo.set('emailText', promo.emailText);
        newPromo.set('allowAgency', promo.allowAgency);
        newPromo.set('allowTrial', promo.allowTrial);

        return new Promise((resolve, reject) => {
            newPromo.save().then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },

    deletePromo(context, id) {
        let Promo = Parse.Object.extend('Promo');
        let query = new Parse.Query(Promo);

        return new Promise((resolve, reject) => {
            query.get(id).then(response => {
                response.destroy().then(() => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => {
                reject(error);
            });
        });
    }

};
