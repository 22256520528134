<template>
    <div class="billing">
        <div class="invoices">
            <div class="row">
                <div class="col-6">
                    <div>
                        <vue-apex-charts
                            v-if="series[0].data.length > 0"
                            id="revenue-report-chart"
                            type="line"
                            height="360"
                            :options="chartOptions2"
                            :series="series"
                        />
                    </div>

                </div>
                <div class="col-6">
                    <h4 class="pt-0 mt-0 mb-0">{{$ml.get('MONTHLY_COST')}}</h4>
                    <p class="small" v-html="$ml.get('MONTHLY_ADMIN_SUMMARY_TEXT')"></p>
                    <table class="table-list">
                        <thead class="font-bebas-neue">
                            <tr>
                                <th>{{$ml.get('MONTH')}}</th>
                                <th>{{$ml.get('TOTAL_ACTIVE_CARDS')}}</th>
                                <th>{{$ml.get('INVOICED_TOTAL')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  v-for="(stat, index) in statsList" :key="'statRow-'+index" :class="isCurrentMonthRow(stat) ? 'cur-month-row': ''">
                                <td class="py-2 px-1">{{ stat.month }}
                                    <span v-if="isCurrentMonthRow(stat)" class="more-info" v-tooltip="$ml.get('tooltip_estimated_usage')">
                                        <font-awesome-icon icon="info-circle"/>
                                    </span>
                                </td>
                                <td class="py-2 px-1">{{ stat.count }}</td>
                                <td class="py-2 px-1">€ {{calcRowTotal(stat)}}</td>
                            </tr>
                            <tr class="total-row">
                                <td colspan="2" class="py-2 px-1 text-align-left">Total</td>
                                <td>€ {{calcTotal(statsList)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import VueApexCharts from "vue-apexcharts";

export default {
    name: "ClientStats",
    components: {
        VueApexCharts,
    },
    props: {
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 200
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        curCompanyId() {
            if(this.$store.state.cardCompany){
                return this.$store.state.cardCompany.id;
            }else{
                return null;
            }
        },
        colors() {
            let colorSets = [
                '#6DA317',
                '#ff9f43',
                '#C21146',
            ]
            return colorSets;
        },

        chartOptions2() {

            let colors = this.colors;

            let chartOptions = {

                chart: {
                    height: 350,
                    type: 'line',
                    curve: 'smooth',

                    toolbar: { show: false }
                },
                stroke: {
                    curve: 'smooth'
                },
                dataLabels: {
                    enabled: true,
                },
                markers: {
                    size: 3,
                },
                title: {
                    text: this.$ml.get(`MONTHLY_USAGE`),
                    align: 'left'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                yaxis: {
                    min: this.minCountEver-10 > 0 ? this.minCountEver-10 : 0,
                    max: this.maxCountEver+10,
                    title: {
                        text: this.$ml.get(`MAX_ACTIVE_CARS`),
                    },

                },

                colors,

            }
            return chartOptions
        },
    },
    watch: {
        curCompanyId: function(newVal) {
            if (newVal) {
                //console.log('curCompanyId', newVal);
                this.fetchAdminStats();
            }
        },

    },
    mounted() {
        if (this.curCompanyId) {
            this.fetchAdminStats(this.curCompanyId);
        }
    },
    data() {
        return {
            curCompany: {},
            statsCount: 0,
            year: new Date().getFullYear(),
            statsList: [],
            chartId: 'line-chart',
            maxCountEver: 50,
            minCountEver: 0,
            priceBrackets: [
                {
                    min: 0,
                    max: 39,
                    price: 1.60
                },
                {
                    min: 40,
                    max: 99,
                    price: 1.52
                },
                {
                    min: 100,
                    max: 499,
                    price: 1.44
                },
                {
                    min: 500,
                    max: 999,
                    price: 1.36
                },
                {
                    min: 1000,
                    max: 1999,
                    price: 1.28
                },
                {
                    min: 2000,
                    max: 9999,
                    price: 1.12
                },
            ],
            currentPieResults: [],
            series: [
                {
                    name: 'Card Usage',
                    data: []
                }
            ],
        }
    },
    methods : {
        groupResultsByMonth(results) {
            let grouped = results.reduce((acc, result) => {
                let month = result.attributes.month; // Ensure this is a number from '1' to '12'
                if (!acc[month]) {
                    acc[month] = [];
                }
                acc[month].push(result);
                return acc;
            }, {});

            // Convert the object into an array of objects with month and results keys
            let groupedArray = Object.keys(grouped).map(month => ({
                month: month,
                results: grouped[month]
            }));

            // Sort the array by month number (1 to 12)
            groupedArray.sort((a, b) => parseInt(a.month, 10) - parseInt(b.month, 10));

            return groupedArray;
        },

        fetchInvoices(){
            this.$store.dispatch('getAllInvoices').then(response => {
                this.invoices = response;
            });
        },


        fetchAdminStats() {
            let agencyClients = this.$store.state.cardCompany.attributes.relatedCompanies.map(company => company.id);

            this.$store.dispatch('getAllAgencyStats', { companies: agencyClients }).then(response => {
                this.statsCount = response.length;
                console.log('response', response);
                // Sort stats by year and month before processing
                let sortedResponse = response.sort((a, b) => {
                    let dateA = new Date(a.attributes.year, a.attributes.month - 1);
                    let dateB = new Date(b.attributes.year, b.attributes.month - 1);
                    return dateA - dateB;
                });

                // Aggregate counts by year-month
                let aggregatedStats = {};
                sortedResponse.forEach(stat => {
                    let yearMonth = stat.attributes.year + '-' + stat.attributes.month;
                    if (!aggregatedStats[yearMonth]) {
                        aggregatedStats[yearMonth] = { count: 0, monthOnly: stat.attributes.month, year: stat.attributes.year };
                    }
                    aggregatedStats[yearMonth].count += stat.attributes.cardCount;
                });

                // Convert the aggregated object to an array
                this.statsList = Object.values(aggregatedStats);

                // Calculate max and min counts ever
                let counts = this.statsList.map(stat => stat.count);
                this.maxCountEver = Math.max(...counts);
                this.minCountEver = Math.min(...counts);

                // Prepare data points for the chart
                let monthNames = {
                    '1': 'January', '2': 'February', '3': 'March', '4': 'April',
                    '5': 'May', '6': 'June', '7': 'July', '8': 'August',
                    '9': 'September', '10': 'October', '11': 'November', '12': 'December'
                };

                let dataPoints = this.statsList.map(stat => {
                    return {
                        x: monthNames[stat.monthOnly],
                        y: stat.count
                    };
                });

                // Update series data for the chart
                this.series = [
                    { name: 'Card Usage', data: dataPoints }
                ];
            });
        },


        isCurrentMonthRow(stat){
            if(moment(stat.month).format('MM') == moment().format('MM')) {
                return true;
            }else {
                return false;
            }
        },
        calcRate(stat){
            if(stat){
                let rate = this.priceBrackets.find((bracket) => {
                    return bracket.min <= stat.count && bracket.max >= stat.count;
                });
                return rate.price.toFixed(2);
            }else {
                return 0;
            }
        },
        calcRowTotal(stat){
            let rate = this.calcRate(stat);
            let total = rate * stat.count;
            return total.toFixed(2);
        },
        calcTotal(stats){
            let total = 0;
            stats.forEach((stat) => {
                let rate = this.calcRate(stat);
                total += rate * stat.count;
            });
            return total.toFixed(2);
        },
        nextMultipleOfTen(x) {
            return x % 10 === 0 ? x + 10 : x + (10 - x % 10);
        },

    }
}
</script>

<style scoped>

.cur-month-row {
    color: var(--color-primary);
}

.cur-month-row .more-info {
    position: initial;
}

.total-row td {
    font-size:1.1em;
    color: black;
}

.text-align-right {
    text-align: right;
}
</style>
