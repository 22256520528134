<template>
    <div id="spinner" class="spinner-standard" :class="show ? '' : 'hidden'">
        <div class="spinner-core">
            <div class="loadingio-spinner-eclipse-nq4q5u6dq7r">
                <div class="ldio-x2uulkbinc">
                    <div></div>
                </div>
            </div>
            <div class="spinner-loader-text">{{text}}</div>
        </div>
    </div>
</template>

<script>

 export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: 'Processing...'
        }
    },
 }

</script>


<style scoped>
#spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    background: rgba(255, 255, 255, 0.75);
    justify-content: center;
    align-items: center;
    z-index: 9999;
    left: 0;
}

.spinner-loader-text {
    position: absolute;
    top: 48%;
    font-size: 0.8rem;
    color: #6c757d;
    width: 100px;
    text-align: center;
    left: calc(50% - 50px);
}



@keyframes ldio-x2uulkbinc {
    0% { transform: rotate(0deg) }
    50% { transform: rotate(180deg) }
    100% { transform: rotate(360deg) }
}
.ldio-x2uulkbinc div {
    position: absolute;
    animation: ldio-x2uulkbinc 1s linear infinite;
    width: 89.6px;
    height: 89.6px;
    top: 95.19999999999999px;
    left: 95.19999999999999px;
    border-radius: 50%;
    box-shadow: 0 4.4799999999999995px 0 0 #6da317;
    transform-origin: 44.8px 47.04px;
}
.loadingio-spinner-eclipse-nq4q5u6dq7r {
    width: 280px;
    height: 280px;
    display: inline-block;
    overflow: hidden;
}
.ldio-x2uulkbinc {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-x2uulkbinc div { box-sizing: content-box; }
</style>
