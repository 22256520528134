<template>
    <div class="eco-content-scroll">
        <div class="eco-content-space h100 pt-1">

            <div class="billing">
                <div class="invoices">

                    <div class="table-top text-center justify-content-between px-0">
                        <button-field @click="addInvoice()"
                                      icon="plus"
                                      color="primary">{{$ml.get('ADD_NEW_INVOICE')}}
                        </button-field>

                        <div class="">
                            <div>{{$ml.get('CLIENT_INVOICES')}}</div>
                            <div class="small font-bebas-neue">
                                {{$ml.get('TOTAL')}}: {{ {amount: totalAmount, currency: mostCommonCurrency} | currency }}
                            </div>

                        </div>

                        <div class="year-selector">
                            <select-field
                                :id="`invoice-years`"
                                :name="`invoice-years`"
                                :value="filterYear"
                                :options="availableYears"
                                v-model="filterYear">{{$ml.get('FISCAL_YEAR')}}
                            </select-field>

                        </div>
                    </div>
                    <div class="table-list-wrapper">

                        <table class="table-list">
                            <thead class="font-bebas-neue">
                            <tr>
                                <th>{{$ml.get('INVOICE_N')}}</th>
                                <th>{{$ml.get('ISSUED_ON')}}</th>
                                <th>{{$ml.get('FILE')}}</th>
                                <th>{{$ml.get('NET_AMOUNT')}}</th>
                                <th>{{$ml.get('GROSS_AMOUNT')}}</th>
                                <th>{{$ml.get('STATUS')}}</th>
                                <th class="actions">{{$ml.get('ACTIONS')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="invoice in invoices" :key="invoice.id">
                                <td>
                                    <div>{{invoice.attributes.number}}</div>
                                </td>
                                <td>
                                    <div>{{invoice.attributes.issuedOn | dateFormat}}</div>
                                </td>
                                <td>
                                    <div v-if="invoice.attributes.attachment">
                                        <a :href="invoice.attributes.attachment._url"
                                           :download="invoice.attributes.attachment._name"
                                           target="_blank">
                                            {{invoice.attributes.attachment._name | trimFilename}}
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div>{{ {amount: invoice.attributes.netAmount, currency: invoice.attributes.currency } | currency }}</div>
                                </td>
                                <td>
                                    <div>{{ {amount: invoice.attributes.amount, currency: invoice.attributes.currency } | currency }}</div>
                                </td>
                                <td>
                                    <b-badge v-if="invoice.attributes.paidOn" class="text-white">{{$ml.get('STATUS_PAID')}}</b-badge>
                                    <div class="trial-subscript text-secondary">
                                        <span v-if="invoice.attributes.paidOn">{{$ml.get(`Paid_on`)}}: {{invoice.attributes.paidOn | dateFormat}}</span>
                                    </div>
                                    <b-badge v-if="!invoice.attributes.paidOn" class="text-white" variant="danger">{{$ml.get('STATUS_OPEN')}}</b-badge>
                                </td>


                                <td>
                                    <div class="table-action-btn-gorup text-center">

                                        <button-field @click="markAsPaid(invoice)"
                                                      circle
                                                      outline
                                                      icon="check"
                                                      :disabled="invoice.attributes.paidOn ? true : false"
                                                      class="table-action-btn"
                                                      v-tooltip="invoice.attributes.paidOn ? 'Already paid' : 'Mark as paid'"
                                                      color="secondary"></button-field>

                                        <button-field @click="editInvoice(invoice)"
                                                      circle
                                                      outline
                                                      icon="edit"
                                                      class="table-action-btn"
                                                      v-tooltip="'Edit invoice'"
                                                      color="primary"></button-field>

                                        <button-field @click="openInvoice(invoice)"
                                                      circle
                                                      outline
                                                      icon="download"
                                                      class="table-action-btn"
                                                      v-tooltip="'Download invoice'"
                                                      color="dark"></button-field>


                                        <button-field @click="deleteInvoice(invoice)"
                                                      circle
                                                      outline
                                                      icon="trash-alt"
                                                      class="table-action-btn"
                                                      v-tooltip="$ml.get('tooltip_DELETE_INVOICE')"
                                                      color="error"></button-field>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>


                <div class="company-billing-info pt-2" v-if="company!==null">
                    <div class="table-top text-center justify-content-between px-0 pt-4">
                        {{$ml.get('CLIENT_BILLING_INFORMATION')}}
                        <button-field @click="editClientModal"
                                      circle
                                      outline
                                      icon="edit"
                                      class="table-action-btn"
                                      v-tooltip="'Edit invoice'"
                                      color="primary"></button-field>
                    </div>
                    <div class="company-data">
                        <div><label class="font-bebas-neue">{{$ml.get('COMPANY_NAME')}}</label><span>{{company.attributes.name}}</span>
                        </div>
                        <div><label class="font-bebas-neue">{{$ml.get('VAT_NUMBER')}}</label><span>{{company.attributes.vatNumber}}</span>
                        </div>
                        <div><label class="font-bebas-neue">{{$ml.get('BILLING_ADDRESS')}}</label><span>{{company.attributes.billingAddress}}</span>
                        </div>
                        <div><label
                            class="font-bebas-neue">{{$ml.get('RESPONSIBLE')}}</label><span>{{company.attributes.responsible}}</span>
                        </div>
                        <div><label
                            class="font-bebas-neue">{{$ml.get('EMAIL')}}</label><span>{{company.attributes.billingEmail}}</span>
                        </div>
                        <div><label
                            class="font-bebas-neue">{{$ml.get('TELEPHONE')}}</label><span>{{company.attributes.telephone}}</span>
                        </div>
                    </div>

                    <div class="table-bottom table-action-btn-gorup text-center"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DashboardBilling",
    data() {
        return {
            //invoices: [],
            filterYear: new Date().getFullYear(),
            availableYears: [
                {text: new Date().getFullYear(), value: new Date().getFullYear()},
                {text: new Date().getFullYear() - 1, value: new Date().getFullYear() - 1},
                {text: new Date().getFullYear() - 2, value: new Date().getFullYear() - 2},
            ]
        }
    },
    created() {
        this.fetchInvoices(this.curCompanyId);
    },
    methods: {
        addInvoice() {
            this.$store.commit('toggleDashboardModal', {name: 'Invoice_Form', data: null})
        },
        editInvoice(invoice) {
            this.$store.commit('toggleDashboardModal', {name: 'Invoice_Form', data: invoice})
        },

        markAsPaid(invoice) {
            this.$store.commit('toggleDashboardModal', {name: 'Invoice_MarkAsPaid', data: invoice})
        },

        deleteInvoice(invoice) {
            this.$store.commit('toggleDashboardModal', {name: 'Invoice_Delete', data: invoice})
        },

        openInvoice(invoice) {
            window.open(invoice.attributes.attachment._url, '_blank');
        },

        fetchInvoices(companyId) {
            if(!companyId){
                return;
            }
            this.$store.dispatch('getClientInvoices', {id: companyId, year: this.filterYear}).then(() => {
                //this.invoices = response;
            }).catch(function(e){
                self.$checkTokenExp(e)
            });
        },

        editClientModal() {
            this.$store.commit('toggleDashboardModal', {name: 'DashboardBillingForm', data: this.company})
        }
    },
    computed: {
        curCompanyId() {
            if(this.$store.state.cardCompany){
                return this.$store.state.cardCompany.id;
            }else{
                return null;
            }
        },
        company() {
            return this.$store.getters.getCardCompany;
        },
        invoices() {
            return this.$store.state.selectedInvoices;
        },
        totalAmount() {
            let total = 0;
            this.invoices.forEach((invoice) => {
                total += parseFloat(invoice.attributes.amount);
            });
            return total.toFixed(2);
        },
        mostCommonCurrency(){
            let currency= 'EUR';
            let allCurrencies = [];
            this.invoices.forEach((invoice) => {
                allCurrencies.push(invoice.attributes.currency);
            });
            let counts = {};
            allCurrencies.forEach(function(x) { counts[x] = (counts[x] || 0)+1; });
            let max = 0;
            for (let key in counts) {
                if (counts[key] > max) {
                    max = counts[key];
                    currency = key;
                }
            }
            return currency;
        },
    },
    watch: {
        curCompanyId: function(newVal) {
            if (newVal) {
                this.fetchInvoices(newVal);
            }
        },
        filterYear() {
            this.fetchInvoices(this.curCompanyId);
        }
    },
}
</script>

<style scoped>

.year-selector {
    width: 160px;
    text-align: left;
}

.year-selector .form-component {
    min-height: 33px;
}

.table-action-btn {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: var(--color-dark);
    border: 1px solid var(--color-dark);
    border-radius: 50%;
    text-decoration: none;
    /* transition: all 0.25s ease-in-out; */
    background: transparent;
    font-size: 10px;
}

.table-action-btn:hover,
.table-action-btn:focus,
.table-action-btn:active {
    color: var(--color-white);
    background: var(--color-dark);
}
.eco-content-scroll{
    padding: 10px;
}
.eco-content-space {
    background: #FFFFFF;
    padding: 25px;
}
</style>
