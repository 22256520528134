import Parse from 'parse';

export const CompanyService = {
    getCompany(context, data) {

        let forceRequest = data && data.force ? data.force : false;
        let currentCompany = context.state.userCompany;

        if(currentCompany && (currentCompany.id || currentCompany.objectId) && !forceRequest ){

            return new Promise((resolve) => {
                resolve(currentCompany)
            })

        } else if(context.state.user) {

            let compPointer = context.state.user.get('company');
            let theId = null;

            if(compPointer){
                theId = compPointer.id ? compPointer.id : compPointer.objectId;
            }

            if(theId){
                const compObject = Parse.Object.extend("Company");
                const compQuery = new Parse.Query(compObject);
                compQuery.include('impersonating');
                compQuery.include('usedPromo');
                compQuery.include('clientOf');

                return new Promise((resolve, reject) => {
                    compQuery.get(theId).then(response => {
                        context.commit('setUserCompany', response);
                        resolve(response);
                    }).catch(err => {
                        reject(err);
                    });
                });
            }

        } else {
            return new Promise((resolve) => {
                resolve(null)
            })
        }

    },
    saveCompany(context, data) {
        return new Promise((resolve, reject) => {
            for (const key in data) {
                context.state.userCompany.set(key, data[key]);
            }
            context.state.userCompany.save().then(response => {
                context.commit('setUserCompany', response);
                resolve(response);
            }).catch(err => {
                reject(err);
            });
        });
    },
};
