<template>
    <div class="eco-content-scroll">
        <div class="eco-content-space mh100">
            <div class="row">
                <!-- USER PROFILE -->
                <div class="col-12 col-md-4  text-center">
                    <div class="col">
                        <div class="pl-0 pb-1 table-top justify-content-start">
                            <font-awesome-icon icon="user" class="mr-2"></font-awesome-icon>
                            <span class="pt-1">{{$ml.get('PROFILE')}}</span>
                        </div>
                    </div>

                    <!-- Profile INFO -->
                    <div class="p-2">
                        <div class="profile-avatar profile-avatar-xl mx-auto">
                            <img v-if="user.attributes.avatar"
                                 :src="user.attributes.avatar._url"
                                 :alt="user.attributes.username"
                                 class="profile-settings-avatar">
                            <font-awesome-icon v-else icon="user" class="profile-settings-avatar"></font-awesome-icon>
                        </div>
                        <div class="pt-2">
                            <div>{{user.attributes.firstName}} {{user.attributes.lastName}}</div>
                            <span class="trial-subscript ">{{user.attributes.email}}</span>
                        </div>
                    </div>

                    <!-- EDIT CONTROLS -->
                    <div class="p-2 d-flex w-100">
                        <div class="mb-2 text-center w-50 pr-1">
                            <button-field color="secondary"
                                          icon="edit"
                                          block
                                          @click="toggleModal('DashboardProfileForm')">
                                {{$ml.get('EDIT_INFORMATION')}}
                            </button-field>
                        </div>
                        <div class="mb-2 text-center w-50 pl-1">
                            <button-field color="primary"
                                          icon="key"
                                          block
                                          @click="toggleModal('DashboardProfilePassword')">
                                {{$ml.get('CHANGE_PASSWORD')}}
                            </button-field>
                        </div>
                    </div>

                </div>



                <transition-group name="fade" class="col-12 col-md-8" tag="div" mode="out-in" >

                    <!-- CAN UPGRADE -->
                    <div  key="upgrade" v-if="userCompany && userCompany.attributes.canUpgrade && !userCompany.attributes.isAgency && !isInReview">
                        <div class="row">
                            <div class="col-12">
                                <div class="pl-0 pb-1 table-top text-left justify-content-start">
                                    <font-awesome-icon icon="star" class="mr-2"></font-awesome-icon> <span class="pt-1">{{$ml.get('UPGRADE_TO_AGENCY')}}</span>
                                </div>
                            </div>
                            <div class="col-12 small">
                                <p v-html="$ml.get('CONTRACTS_DETAILS')"></p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 ">
                                <h4 class="mb-0">1. Request an upgrade to agency</h4>
                                <p class="mt-1 small">Click on the button billow to submit a formal request to upgrade to an "Agency" profile for your company.</p>

                                <div class="row">
                                    <div class="col-12 text-center">
                                        <div class="my-3 text-center">
                                            <checkbox-field v-model="agreedAgency" color="secondary">I have read and agree to the <a href="../../../public/helpers/card_render.php" target="_blank">agency terms</a></checkbox-field>
                                        </div>
                                        <button-field color="secondary"
                                                      icon="arrow-up"
                                                      block
                                                      :disabled="hasRequestedUpgrade || !agreedAgency"
                                                      class="max-width-300"
                                                      @click="openUpgrade()">
                                            {{$ml.get('REQUEST_UPGRADE')}}
                                        </button-field>
                                    </div>
                                </div>

                                <h4 class="mb-0">2. Sign the contract</h4>
                                <p class="mt-1 small">After reviewing your application our team will prepare the contract and send it to you for digital signature via e-mail. Please sign the document and once this is done your Agency account will be activated within one working day.</p>

                            </div>

                        </div>

                    </div>

                    <!-- UPGRADE IN REVIEW -->
                    <div class="text-center"  key="review" v-if="isInReview">

                        <div class="row">
                            <div class="col-12">
                                <div class="pl-0 table-top text-center">
                                    {{$ml.get('UPGRADE_TO_AGENCY')}}
                                </div>
                            </div>
                            <div class="col-12">
                                <div id="in-review" class="pt-4">
                                    <div class="text-center mb-2">
                                        <img src="/img/icons/application-in-review.svg" class="application-icon"/>
                                    </div>
                                    <h4>Your application is currently being reviewed!</h4>
                                    <p class="small" v-if="hasRequestedUpgrade">You have submitted your upgrade application on: <strong class="text-primary">{{upgradeDate | dateFormat}}</strong></p>
                                    <p class="small">Our team will get back to you over e-mail with a link to the contract to sign via <a href="https://www.docusign.com/" target="_blank">https://www.docusign.com/</a></p>
                                    <p class="small">In the meantime if you have any questions or comments, don't hesitate to contact us at <a href="../../../public/helpers/card_render.php">hello@qfecard.com</a></p>
                                    <p class="small">Thank you for your patience!</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- AGENCY APPROVED -->
                    <div class="text-center"  key="approved" v-if="userCompany && userCompany.attributes.isAgency">
                        <div class="row">
                            <div class="col-12">
                                <div class="pl-0 table-top text-center">
                                    {{$ml.get('YOUR_AGENCY_PROFILE')}}
                                </div>
                            </div>
                        </div>

                        <div class="row pt-4">
                            <div class="col-12 text-left">

                                <div class="row">
                                    <div class="col-4">
                                        <div class="text-center my-agency">
                                            <div class="agency-block">
                                                <div class="client-avatar lg-avatar">
                                                    <img v-if="compLogo"
                                                         :src="compLogo" class="company-logo"/>
                                                </div>
                                                <div class="agency-icon">
                                                    <img src="/img/icons/agency-active.svg" class="agency-icon"/>
                                                </div>
                                            </div>

                                            <h4 class="mb-0 mt-1 text-center">{{userCompany.attributes.name}}</h4>
                                            <h5 class="my-0">qfecard Agency</h5>
                                        </div>

                                    </div>
                                    <div class="col-8">
                                        <div class="agency-info-item mb-2" v-if="userCompany.attributes.agencyContract">
                                            <span class="info-label">{{$ml.get('SIGNED_CONTRACT')}}</span>
                                            <a :href="userCompany.attributes.agencyContract._url" target="_blank">{{userCompany.attributes.agencyContract._name | trimFilename}}</a>
                                        </div>
                                        <div class="agency-info-item mb-2">
                                            <span class="info-label">{{$ml.get('AGENCY_SINCE')}}</span>
                                            <span>{{userCompany.attributes.verifiedOn | dateFormat}}</span>
                                        </div>
                                        <div class="agency-info-item mb-2">
                                            <span class="info-label">{{$ml.get('PRICING_TIER')}}</span>
                                            <span>Tier {{tierLvl.level}}</span>
                                        </div>


                                    </div>
                                </div>

                            </div>
                            <!-- TIER TABLE -->
                            <div class="col-4">


                            </div>
                        </div>

                    </div>

                </transition-group>

            </div>
        </div>
    </div>
</template>

<script>
import Parse from "parse";

export default {
    name: "DashboardProfile",
    computed: {
        isInReview(){
            if(this.userCompany && this.hasRequestedUpgrade && !this.userCompany.attributes.verified) {
                return true;
            } else {
                return false;
            }
        },

        hasRequestedUpgrade(){
            if(this.userCompany && this.userCompany.attributes.contractRequested){
                return true;
            }else {
                return false;
            }
        },

        upgradeDate(){
            if(this.userCompany && this.userCompany.attributes.contractRequested){
                return this.userCompany.attributes.contractRequested;
            }else {
                return false;
            }
        },

        user() {
            return this.$store.state.user
        },
        userCompany() {
            return this.$store.state.userCompany
        },
        tierLvl() {
            if (this.userCompany && this.userCompany.attributes.currentCardCount) {
                return this.$getPricingTier(this.userCompany.attributes.currentCardCount);
            } else {
                return 1;
            }
        },

        compLogo() {
            if (this.userCompany && this.userCompany.attributes.logo) {
                //console.log(this.userCompany.attributes.logo)
                return this.userCompany.attributes.logo._url;
            } else {
                return require('@/assets/images/logo-placeholder.svg');
            }
        },
        disabledSubmit() {
            if (this.signedFile && this.panelFile) {
                return false;
            } else {
                return true;
            }
        }
    },
    data() {
        return {
            form: {
                newPassword: '',
                newPasswordConfirm: ''
            },
            agreedAgency: false,
            hasError: false,
            loading: false,
            signedFile: null,
            panelFile: null,
            submittedOK: false
        }
    },
    methods: {
        confirmAgency(){
            this.confirmAgency = true;
        },
        uploadFiles(){
            this.$store.dispatch('uploadAgencyFiles', {
                signedFile: this.signedFile.file,
                panelFile: this.panelFile.file
            }).then(() => {
                this.$notify({
                    group: 'app',
                    type: 'success',
                    title: 'Success',
                    text: 'Files uploaded successfully'
                });
                this.submittedOK = true;
                this.$store.dispatch('getCompany');
            })
        },
        toggleModal(componentName) {
            this.$store.commit('toggleDashboardModal', {name: componentName, data: null});
        },
        openUpgrade(){

            let ulr_base = 'https://www.qfecard.com/agency';
            let curUser = Parse.User.current();
            let compData = this.$store.state.userCompany.attributes;

            let params = {
                email: curUser.attributes.email,
                firstName: curUser.attributes.firstName,
                lastName: curUser.attributes.lastName,
                company: compData.name,
                phone: compData.telephone,
                address: compData.billingAddress,
                billingEmail: compData.billingEmail,
                vat: compData.vatNumber,
                responsible: compData.responsible,
                website: compData.website,
                companyId: this.$store.state.userCompany.id,
            }

            if(compData.usedPromo && compData.usedPromo.get('code')){
                params.promo = compData.usedPromo.attributes.code;
            }

            let paramsString = JSON.stringify(params);
            let encodedParams = btoa(paramsString);

            let url = ulr_base +'?data='+ encodedParams;

            window.open(url, '_blank');

            this.startPolling();

        },
        startPolling() {
            const self = this;

            // Define the polling function
            function poll() {
                self.$store.dispatch('getCompany', {force: true}).then(companyData => {
                    // Check if the company data meets your condition to stop polling
                    if (companyData.attributes.contractRequested) {
                        // Stop polling
                        return;
                    }

                    // Schedule the next poll after 5 seconds
                    self.pollTimeout = setTimeout(poll, 5000);
                }).catch(error => {
                    console.error('Error during polling:', error);
                    // Optionally retry or handle errors differently
                    self.pollTimeout = setTimeout(poll, 5000);
                });
            }

            // Start the polling
            poll();
        },


    },

}
</script>

<style scoped>

.max-width-300{
    max-width: 300px;
}

.justify-content-start {
    justify-content: flex-start;
}

.info-label {
    position: relative;
    width: 100%;
    color: var(--color-dark);
    text-transform: uppercase;
    font-size: 12px;
    cursor: text;
    pointer-events: none;
    display: block;
}

.agency-info-item {
    font-size: 14px;
}

.application-icon {
    width: 150px;
}

.agency-icon {
    width: 32px;
    position: absolute;
    top: 0px;
    right: -5px;
}

.agency-block {
    width: 85px;
    position: relative;
    display: inline-block;
}

.profile-settings-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    color: var(--color-grey);
}
svg.profile-settings-avatar {
    padding: 10px;
}
.eco-content-scroll{
    padding: 10px;
}
.eco-content-space {
    background: #FFFFFF;
    padding: 25px;
}
.contracts-list{
    padding-left: 15px;
}
.contracts-list li{
    margin: 5px 0;
}
</style>
