import Parse from 'parse'

export const CardConfigService = {
    getCardConfig(context) {
        let cardConfig = Parse.Object.extend('CardConfig');
        let query = new Parse.Query(cardConfig);
        query.equalTo('company', context.state.cardCompany);
        query.include('designVer');
        query.include('template');
        return new Promise((resolve, reject) => {
            query.first().then(response => {
                context.commit('setCardConfig', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getCardTemplates(context) {
        let cardConfig = Parse.Object.extend('CardTemplate');
        let query = new Parse.Query(cardConfig);
        query.descending('createdAt');
        //query.containedIn('allowedFor', context.state.userCompany.id);
        return new Promise((resolve, reject) => {
            query.find().then(response => {
                context.commit('setCardTemplates', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },
    getFontFamilies(context) {

        let chunkSize = 200;
        let totalFonts = 1360;

        let steps = Math.ceil(totalFonts/chunkSize);
        let curStep = 1;

        let parsePromises = [];

        while (curStep <= steps) {
            let fontFamily = Parse.Object.extend('FontFamily');
            let query = new Parse.Query(fontFamily);

            query.limit(chunkSize);
            query.exclude(['css','version','relatedFonts','kind']);
            query.skip( (curStep-1)*chunkSize);

            parsePromises.push(query.find());

            curStep++;
        }

        //return new Promise((resolve, reject) => {

            Promise.all(parsePromises)
            .then(responses => {
                var merged = [].concat.apply([], responses);
                context.commit('setFontFamilies', merged);
            }).catch(
                //console.log("Fetch fonts error: ", error.message)
            )
        //});
    },
    getInitialTemplate(context) {
        let cardTemplate = Parse.Object.extend('CardTemplate');
        let query = new Parse.Query(cardTemplate);
        query.equalTo('nice_name', 'XX_ecobcard');
        return new Promise((resolve, reject) => {
            query.first().then(response => {
                context.commit('setInitialTemplate', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },
    saveCardConfig(context, data) {
        let cardConfigRecord = {};
        if(data.id === null){
            let cardConfig = Parse.Object.extend('CardConfig');
            cardConfigRecord = new cardConfig();
        }else{
            cardConfigRecord = context.state.cardConfig
        }

        for (const key in data.form) {
            let infoToSave = data.form[key];

            if(key === 'sizeLogo' || key === 'sizeTitle' || key ==='baseFontSize') {
                infoToSave = parseInt(data.form[key]);
            }

            if(key === 'relatedFont'){
                let pointer = {"__type": "Pointer", "className": "FontFamily", "objectId": infoToSave}
                cardConfigRecord.set('relatedFont', pointer);
                //debugger;
            } else {
                cardConfigRecord.set(key, infoToSave);
            }

        }

        for (const k in data.imgFiles){
            if(data.imgFiles[k] !== null){
                let file = new Parse.File(data.imgFiles[k].name, data.imgFiles[k].file);
                cardConfigRecord.set(data.imgFiles[k].field, file);
            }
        }

        cardConfigRecord.set('company', context.state.cardCompany);
        let pointer = {"__type": "Pointer", "className": "CardTemplate", "objectId": data.templateId}
        cardConfigRecord.set('template', pointer);
        cardConfigRecord.set('designVer', Number(data.designVer)+1);

        return new Promise((resolve, reject) => {
            cardConfigRecord.save().then(response => {
                context.dispatch('getCardConfig');
                resolve(response);
            }).catch(err => {
                reject(err);
            })
        })

    },
};
