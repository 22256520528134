<template>
    <div class="dropzone-container"
         :class="[assignClasses, borderClass]">
        <div class="dropzone-content font-bebas-neue"
             :class="{'dropzone-hover': fileDragOver}"
             @dragenter="fileDragOver=true" @dragend="fileDragOver=false"
             @dragleave="fileDragOver=false">

            <div class="dropzone-preview-wrap" v-if="!fileSizeNotice && !mimeTypeNotice">
                <div v-if="!imageBase64 && !fileName.length"
                     class="dropzone-text">
                    <slot></slot><span v-if="required" class="asterix">*</span>
                </div>
                <div v-else-if="fileName.length"
                     class="dropzone-text">{{fileName}}
                </div>
                <img :src="imageBase64" alt="image" v-else-if="imageBase64">
                <img :src="value" alt="image" v-else>
            </div>
            <div v-else class="dropzone-error">
                <span class="error-notice" v-if="mimeTypeNotice">Wrong file type was selected.</span>
                <span class="error-notice" v-if="fileSizeNotice">File is too big! It must be less than {{maxFileSize}} MB</span>
            </div>


            <div class="clear-control" v-if="this.fileName.length || hasFile" >
                <b-icon icon="x-circle-fill" @click="clearAndReset"></b-icon>
            </div>

            <input type="file"
                   :name="name"
                   :accept="accept"
                   :id="id"
                   ref="realFileInput"
                   @change="filesChange($event)">
            <input type="hidden"
                   :name="`preview-`+name"
                   :value="imageBase64"
                   v-if="imageBase64 !== null">
        </div>

    </div>
</template>

<script>
    export default {
        name: "DropzoneField",
        props: {
            name: String,
            error: {
                type: String,
                default: ''
            },
            color: {
                type: String,
                default: ''
            },
            accept: {
                type: String,
                default: '*'
            },
            maxFileSize: {
                type: Number,
                default: Number.MAX_SAFE_INTEGER // By default, there is no limit on file size
            },
            id: String,
            required: {
                type: Boolean,
                default: false
            },
            extValidator: {
                type: Function,
                default: null
            }
        },
        computed: {
            borderClass() {
                return this.invalid ? 'border-red' : '';
            },
            assignClasses() {
                let resultClasses = '';
                if (this.error.length) {
                    resultClasses += 'color-error';
                } else {
                    switch (this.color) {
                        case 'primary':
                            resultClasses += 'color-primary';
                            break;
                        case 'secondary':
                            resultClasses += 'color-secondary';
                            break;
                        case 'error':
                            resultClasses += 'color-error';
                            break;
                        default:
                            break;
                    }
                }
                return resultClasses;
            },
        },
        data() {
            return {
                fileDragOver: false,
                imageBase64: null,
                fileName: '',
                invalid: false,
                fileSizeNotice: false,
                mimeTypeNotice: false,
                hasFile: false,
            }
        },
        methods: {
            sanitizeFileName(fileName) {
                return fileName.replace(/[^a-zA-Z0-9.-]/g, '_');
            },

            clearAndReset() {
                this.imageBase64 = null;
                this.fileName = '';
                this.invalid = this.required ? true : false;
                this.fileSizeNotice = false;
                this.mimeTypeNotice = false;
                this.hasFile = false;
                this.$refs.realFileInput.value = '';
            },

            internalValidator(fileObject) {
                let result  = true;

                this.fileSizeNotice = false;
                this.mimeTypeNotice = false;

                if(this.required && !(typeof fileObject === 'object' || this.fileName.length > 0)) {
                    result = false;
                }

                //check if the file size is over the limit
                if(fileObject){

                    if(fileObject.size > this.maxFileSize * 1024 * 1024) {
                        result = false;
                        this.fileSizeNotice = true;
                    }
                    if(this.accept !== '*' && !this.isMimeTypeAccepted(fileObject.type)) {
                        result = false;
                        this.mimeTypeNotice = true;
                    }
                }

                this.invalid = !result;
                //console.log(this.name+ " Is invalid: ", this.invalid);
                // debugger;

                return result;
            },

            isMimeTypeAccepted(mimeType) {
                let acceptedTypes = this.accept.split(',');
                for (let i = 0; i < acceptedTypes.length; i++) {
                    let type = acceptedTypes[i].trim();
                    let regex = new RegExp(`^${type.replace('*', '.+')}$`);
                    if (regex.test(mimeType)) {
                        return true;
                    }
                }
                return false;
            },
            filesChange(event) {
                if (event.target.files[0]) {
                    this.hasFile = true;
                    let validationResult = this.internalValidator(event.target.files[0]);

                    this.$emit('validation-result', {'name': this.name, 'valid': validationResult});

                    if(validationResult){
                        let fileType = event.target.files[0].type;
                        this.fileDragOver = false;

                        // Sanitize the file name
                        let sanitizedFileName = this.sanitizeFileName(event.target.files[0].name);

                        if (fileType.includes('image')) {
                            this.fileName = '';
                            let reader = new FileReader();
                            let file = event.target.files[0];

                            if (event.target.files[0]) {
                                reader.readAsDataURL(file);
                            }
                            reader.onloadend = () => {
                                this.imageBase64 = reader.result;
                                this.$emit('input', {
                                    field: this.$props.name,
                                    name: sanitizedFileName,  // Use sanitized file name
                                    file: event.target.files[0],
                                    url: this.imageBase64
                                });
                            };
                        } else {
                            this.imageBase64 = null;
                            this.fileName = sanitizedFileName; // Use sanitized file name
                            this.$emit('input', {
                                field: this.$props.name,
                                name: sanitizedFileName,  // Use sanitized file name
                                file: event.target.files[0],
                                url: this.imageBase64
                            });
                        }
                    }
                }
            },

        },
        mounted() {
            this.$emit('validation-result', {'name': this.name, 'valid': this.internalValidator()});
        }
    }
</script>

<style scoped>

    .clear-control {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        cursor: pointer;
        z-index: 999;
    }

    .border-red .dropzone-content,
    .border-red .dropzone-content:hover{
        border-color: red;
    }

    .asterix, .error-notice {
        color: var(--color-error);
    }
    .dropzone-container {
        flex: 1;
        height: 115px;
        position: relative;
        margin-bottom: 10px;
    }

    .dropzone-content {
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border: 1px dashed var(--color-grey);
        color: var(--color-dark);
        /*border-radius: 10px;*/
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--color-grey);
        /* transition: all 0.25s ease-in-out; */
    }

    .dropzone-content img {
        padding: 10px;
        object-fit: contain;
        display: block;
        height: 100%;
        width: 100%;
        max-width: 100%;
    }

    .dropzone-content.dropzone-hover,
    .dropzone-content:hover {
        border-color: var(--color-dark);
        color: var(--color-dark);
    }

    .dropzone-content > input[type=file] {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }

    .dropzone-text {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .color-primary .dropzone-content {
        border: 1px dashed var(--color-primary);
        color: var(--color-primary);
    }

    .color-primary .dropzone-content.dropzone-hover,
    .color-primary .dropzone-content:hover {
        border-color: var(--color-primary-dark);
        color: var(--color-primary-dark);
    }

    .color-secondary .dropzone-content {
        border: 1px dashed var(--color-secondary);
        color: var(--color-secondary);
    }

    .color-secondary .dropzone-content.dropzone-hover,
    .color-secondary .dropzone-content:hover {
        border-color: var(--color-secondary);
        color: var(--color-secondary);
    }

    .color-error .dropzone-content {
        border: 1px dashed var(--color-error);
        color: var(--color-error);
    }

    .color-error .dropzone-content.dropzone-hover,
    .color-error .dropzone-content:hover {
        border-color: var(--color-error-dark);
        color: var(--color-error-dark);
    }
    .dropzone-text span.asterix {
        position: absolute;
        top: 0;
        right: 2px;
    }

    .dropzone-preview-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        display: contents;
    }

    .dropzone-error {
        display: grid;
    }

</style>
