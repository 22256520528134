<template>
    <transition enter-active-class="animated slideInRight faster"
                leave-active-class="animated slideOutRight faster"
                mode="out-in">
        <div class="right-modal"
             ref="dashboardModal"
             v-if="dashboardModal"
             >
            <!--             v-click-outside="clickOutsideDashboardModal"-->
            <div class="ecb-close" @click="closeModal">
                <font-awesome-icon icon="times"/>
            </div>
            <component :is="activeComponent"></component>
        </div>
    </transition>
</template>

<script>
    import DashboardEmployeeCardsForm from '../modals/EmployeeCards_Form'
    import DashboardEmployeeCardsImport from '../modals/EmployeeCards_Import'
    import DashboardEmployeeCardsDelete from '../modals/EmployeeCards_Delete'
    import DashboardClientsForm from '../modals/Clients_Form'
    import DashboardAgencySettingsForm from '../modals/AgencySettings_Form'
    import PricingForm from '../modals/Pricing_Form.vue'
    import DashboardClientsDelete from '../modals/Clients_Delete'
    import DashboardClientSuspend from '../modals/ClientSuspend'
    import DashboardCompanyCoordinatesForm from '../modals/CompanyCoordinates_Form'
    import DashboardCompanyCoordinatesDelete from '../modals/CompanyCoordinates_Delete'
    import DashboardBillingForm from '../modals/Billing_Form'
    import DashboardProfilePassword from '../modals/Profile_Password'
    import DashboardProfileForm from '../modals/Profile_Form'
    import DashboardCardDesignForm from '../modals/CardDesign_Form'
    import EmployeeCards_Share from '../modals/EmployeeCards_Share'
    import DashboardUserDelete from "@/components/modals/User_Delete";
    import DashboardUserForm from "@/components/modals/User_Form";
    import DashboardUserSuspend from "@/components/modals/User_Suspend";
    import Invoice_Delete from "@/components/modals/Invoice_Delete.vue";
    import Invoice_Form from "@/components/modals/Invoice_Form.vue";
    import Invoice_MarkAsPaid from "@/components/modals/Invoice_MarkAsPaid.vue";
    import ProtectedDoc_Form from "@/components/modals/ProtectedDoc_Form.vue";
    import NoteForm from "@/components/modals/Note_Form.vue";

    export default {
        name: "DashboardRightModal",
        components: {
            DashboardEmployeeCardsForm,
            DashboardEmployeeCardsImport,
            DashboardEmployeeCardsDelete,
            DashboardClientsForm,
            DashboardAgencySettingsForm,
            DashboardClientsDelete,
            DashboardCompanyCoordinatesForm,
            DashboardCompanyCoordinatesDelete,
            DashboardBillingForm,
            DashboardProfilePassword,
            DashboardProfileForm,
            DashboardCardDesignForm,
            EmployeeCards_Share,
            DashboardClientSuspend,
            DashboardUserForm,
            DashboardUserSuspend,
            DashboardUserDelete,
            Invoice_Delete,
            Invoice_Form,
            Invoice_MarkAsPaid,
            PricingForm,
            ProtectedDoc_Form,
            NoteForm
        },
        computed: {
            dashboardModal() {
                return this.$store.state.dashboardModalComponent.state;
            },
            activeComponent(){
                return this.$store.state.dashboardModalComponent.name;
            },
        },
        methods: {
            closeModal() {
                this.$store.commit('toggleDashboardModal')
            },
            clickOutsideDashboardModal() {
                if (this.dashboardModal && !this.$refs.dashboardModal.classList.contains('animated')) {
                    this.$store.commit('toggleDashboardModal');
                }
            }
        }
    }
</script>

<style scoped>

</style>
