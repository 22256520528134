<template>
    <div class="form-component" :class="assignClasses">
        <input class="form-field"
               autocapitalize="none"
               autocorrect="off"
               :type="type"
               :step="step"
               :name="name"
               :required="required"
               :id="id"
               :value="value"
               :disabled="disabled"
               @input="updateValue($event.target.value)">
        <label class="form-label"
               :for="id"><slot></slot><span v-if="required" class="asterix">*</span></label>
        <span class="form-error font-bebas-neue">{{error}}</span>
    </div>
</template>

<script>
    export default {
        name: "InputField",
        props: {
            name: String,
            type: {
                type: String,
                default: 'text'
            },
            step: {
                type: Number,
                default: 1
            },
            error: {
                type: String,
                default: ''
            },
            color: {
                type: String,
                default: ''
            },
            id: String,
            value: {
                type: [String, Number, Date],
                default: ''
            },
            required: {
                type: Boolean,
                default: false
            },
            extValidator: {
                type: Function,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },

        },

        computed: {
            isValid() {
                let result = false;
                if(this.internalValidator(this.value)){
                    result = true;
                }
                return result;
            },
            assignClasses() {
                let resultClasses = '';
                if (this.isValid) {
                    resultClasses += 'form-has-value ';
                }
                if (!this.isValid) {
                    resultClasses += 'color-error';
                } else {
                    switch (this.color) {
                        case 'primary':
                            resultClasses += 'color-primary';
                            break;
                        case 'secondary':
                            resultClasses += 'color-secondary';
                            break;
                        case 'error':
                            resultClasses += 'color-error';
                            break;
                        default:
                            break;
                    }
                }
                return resultClasses;
            }
        },
        methods: {
            internalValidator(value) {
                if (!this.required && (value === '' || value === null || value === undefined)) {
                    return true;
                }

                if (this.extValidator) {
                    return this.extValidator(value);
                }

                if (this.required) {
                    if (typeof value === 'string') {
                        return value.trim().length > 0;
                    } else if (typeof value === 'number') {
                        return !isNaN(value);
                    } else if (value instanceof Date) {
                        return !isNaN(value.getTime());
                    } else {
                        return value !== null && value !== undefined;
                    }
                }

                return true;
            },

            updateValue(value) {
                let typedValue;

                switch (typeof this.value) {
                    case 'number':
                        typedValue = Number(value);
                        if (isNaN(typedValue)) typedValue = null;
                        break;
                    case 'string':
                        typedValue = value;
                        break;
                    default:
                        // For objects such as Date, or in case no specific type is needed
                        typedValue = value;
                        break;
                }

                // Emit the value in its typed form
                this.$emit('input', typedValue);
                this.$emit('validation-result', {'name': this.name, 'valid': this.internalValidator(value)});
            }
        },
        mounted() {
            this.$emit('validation-result', {'name': this.name, 'valid': this.internalValidator(this.value)});
        }

    }
</script>

<style>

    .asterix {
        color: var(--color-error);
    }

    .form-component {
        min-height: 50px;
        position: relative;
        padding-top: 14px;

        /*border: 1px solid red;*/
    }

    .form-field {
        width: 100%;
        border: 0px solid var(--color-grey);
        border-bottom-width: 1px;
        background: transparent;
        font-size: 14px;
        color: var(--color-dark);
        /* transition: all 0.25s ease-in-out; */
    }

    .form-field:focus {
        box-shadow: none;
        outline: none;
        color: var(--color-dark);
        border-bottom-color: var(--color-dark);
    }

    .form-label {
        position: absolute;
        top: 15px;
        left: 0;
        width: 100%;
        color: var(--color-dark);
        /* transition: all 0.25s ease-in-out; */
        text-transform: uppercase;
        font-size: 12px;
        cursor: text;
        pointer-events: none;
    }

    .color-error > .form-label,
    .form-has-value > .form-label {
        top: 0;
        font-size: 10px;
    }

    .form-field:focus ~ .form-label {
        top: 0;
        font-size: 10px;
    }

    .form-error {
        opacity: 0;
        font-size: 12px;
        text-transform: uppercase;
        color: var(--color-error);
        /* transition: all 0.25s ease-in-out; */
    }

    .color-error > .form-field:focus ~ .form-label,
    .color-error > .form-label,
    .color-error > .form-field,
    .color-error > .form-error {
        color: var(--color-error);
    }

    .color-error > .form-field {
        border-bottom-color: var(--color-error);
    }

    .color-error > .form-error {
        opacity: 1;
    }

    .color-error > .form-field:focus ~ .form-error,
    .color-error > .form-field:focus ~ .form-label {
        color: var(--color-error-dark);
    }

    .color-error > .form-field:focus {
        color: var(--color-error-dark);
        border-bottom-color: var(--color-error-dark);
    }

    .color-primary > .form-field:focus ~ .form-label,
    .color-primary > .form-label,
    .color-primary > .form-field,
    .color-primary > .form-error {
        color: var(--color-primary);
    }

    .color-primary > .form-field {
        border-bottom-color: var(--color-primary);
    }

    .color-primary > .form-field:focus ~ .form-label {
        color: var(--color-primary-dark);
    }

    .color-primary > .form-field:focus {
        color: var(--color-primary-dark);
        border-bottom-color: var(--color-primary-dark);
    }

    .color-secondary > .form-field:focus ~ .form-label,
    .color-secondary > .form-label,
    .color-secondary > .form-field,
    .color-secondary > .form-error {
        color: var(--color-secondary);
    }

    .color-secondary > .form-field {
        border-bottom-color: var(--color-secondary);
    }

    .color-secondary > .form-field:focus ~ .form-label {
        color: var(--color-secondary-dark);
    }

    .color-secondary > .form-field:focus {
        color: var(--color-secondary-dark);
        border-bottom-color: var(--color-secondary-dark);
    }

</style>
