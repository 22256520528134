<template>
    <div class="eco-content-space h100 overflow-y-auto">
        <div class="white-box">
            <b-row class="pt-2">
                <b-col cols="3">
                    <b-form-group label="Type of report" class="bootstrapped-v-select filter-v-select">
                        <b-form-radio-group id="mode-selector"
                                            v-model="toggleMode"
                                            :options="modeOptions"
                                            button-variant="secondary"
                                            size="sm"
                        >

                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col cols="2">
                    <b-form-group label="Year" class="bootstrapped-v-select filter-v-select">
                        <b-form-select
                            :options="availableYears"
                            v-model="selectedYear"
                        >

                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="2">
                    <b-form-group label="Month" class="bootstrapped-v-select filter-v-select">
                        <b-form-select
                            :options="availableMonths"
                            v-model="selectedMonth"
                        >

                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group label="Agency" class="bootstrapped-v-select filter-v-select">
                        <Select2
                            v-model="selectedClientOfId"
                            :options="generalSelectCompanies"
                            :placeholder="$ml.get('COMPANY')"
                            :settings="{
                                    templateResult: companyPreview,
                                    templateSelection: selectionPreview,
                                    escapeMarkup: function(stuff){return stuff}
                                }"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

        <div class="white-box">
            <div class="table-top">
                <div class="table-top-buttons">
                </div>
                <div class="table-count">
                    <span class="card-count">{{$ml.get('TOTAL_CARD_COUNT')}}: {{resultsFrom}} - {{resultsTo}} / {{totalResults}}</span>
                </div>
                <div class="search-container">
                    <font-awesome-icon icon="search"/>
                    <input type="text" maxlength="20" name="searchInput"
                           v-model="searchString"
                           class="search-input form-field"
                           :placeholder="$ml.get('search_by_name')"
                    />
                </div>
            </div>

            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <b-table
                        :fields="tableColumns"
                        :items="statsRows"
                        :empty-html="emptyHtml"
                        class="font-small-2 table-vertical-align-middle table-list"
                    >

                        <template #cell(attributes.relatedCompany.attributes.logo)="data">
                            <div class="circular-logo">
                                <img v-if="data.item.attributes.relatedCompany.attributes.logo"
                                     :src="data.item.attributes.relatedCompany.attributes.logo._url"/>
                            </div>
                        </template>

                        <template #cell(attributes.cardCount)="data">
                            <div class="ellipsis">
                                {{data.item.attributes.cardCount}}
                            </div>
                        </template>

                        <template #cell(pricing.price)="data">
                            <div class="ellipsis">
                                <span class="text-muted">T{{ data.item.pricing.tier }}:    </span>
                                <span>{{ {currency: 'EUR', amount: data.item.pricing.price }  | currency }}/card </span>
                            </div>
                        </template>

                        <template #cell(billable)="data">
                            {{ {currency: 'EUR', amount: data.item.billable }  | currency }}
                        </template>

                        <template #cell(invoice)="data">
                            <b-link v-if="data.item.attributes.relatedInvoice"
                                    target="_blank"
                                    :href="data.item.attributes.relatedInvoice.attributes.attachment._url">
                                {{data.item.attributes.relatedInvoice.attributes.number}}
                            </b-link>
                        </template>

                        <template #cell(status)="data">
                            <div v-if="data.item.attributes.relatedInvoice">
                                <b-badge v-if="data.item.attributes.relatedInvoice.attributes.paidOn" class="text-white">{{$ml.get('STATUS_PAID')}}</b-badge>
                                <div class="trial-subscript text-secondary">
                                    <span v-if="data.item.attributes.relatedInvoice.attributes.paidOn" class="text-secondary" variant="secondary">{{$ml.get(`Paid_on`)}}: {{data.item.attributes.relatedInvoice.attributes.paidOn | dateFormat}}</span>
                                </div>
                                <b-badge v-if="!data.item.attributes.relatedInvoice.attributes.paidOn" class="text-white" variant="danger">{{$ml.get('STATUS_OPEN')}}</b-badge>
                            </div>
                        </template>

                        <template #cell(actions)="data">
                            <div class="table-action-btn-gorup">
                                <button-field @click="syncWithStripe(data.item)"
                                              circle
                                              outline
                                              icon="sync"
                                              color="primary"
                                              v-tooltip="`Sync with Stripe`"
                                              ></button-field>


                                <button-field @click="addInvoice(data.item)"
                                              circle
                                              outline
                                              v-tooltip="`Add new invoice for ${data.item.attributes.relatedCompany.attributes.name}`"
                                              icon="plus"
                                              class="table-action-btn"
                                              color="secondary"
                                              :disabled="hasInvoice(data.item)"
                                ></button-field>

                                <button-field @click="editPricing(data.item.attributes.relatedCompany.id)"
                                              circle
                                              outline
                                              v-tooltip="`Set pricing`"
                                              icon="dollar-sign"
                                              class="table-action-btn"
                                              color="dark"
                                ></button-field>

                                <button-field @click="seeBilling(data.item)"
                                              circle
                                              outline
                                              v-tooltip="`Go to billing history`"
                                              icon="external-link-alt"
                                              class="table-action-btn"
                                              color="dark"
                                ></button-field>

                            </div>
                        </template>

                    </b-table>
                </div>
            </div>

            <div class="table-bottom">
                <div class="table-pagination">
                    <a href="#" @click="changeCurPage('prev')" :class="{ 'disable-click' : curPageValue==1 }">« {{$ml.get('Prev')}}</a>
                    <select-field :name="`curPage`"
                                  :id="`curPage`"
                                  :options="curPageOptions"
                                  :value="curPageValue"
                                  v-model="curPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PAGE')}}:
                    </select-field>
                    <a href="#" @click="changeCurPage('next')" :class="{ 'disable-click' : curPageValue==maxPages }">{{$ml.get('Next')}} »</a>
                </div>
                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>
            </div>

        </div>



        <div class="employee-cards h100">

            <div class="qf-charts pt-3" v-if="false">
                <div class="qf-stats-container">
                    <vue-apex-charts
                        id="bars"
                        v-if="series.length > 0"
                        height="200"
                        :options="chartOptions"
                        :series="series"
                    />
                </div>
                <div class="qf-stats-container">
                    <vue-apex-charts
                        id="maps"
                        v-if="hasMapEntries"
                        height="200"
                        :options="mapOptions"
                        :series="mapSeries"
                    />
                </div>
            </div>

            <div class="qf-table-mode-controls text-center py-2">

                <b-form-group
                    :label="$ml.get(`ALLTIME_OR_MONTHLY`)"
                >
                    <b-form-radio-group
                        id="list-selector"
                        v-model="toggleMode"
                        :options="listOptions"
                        button-variant="secondary"
                        size="sm"
                        name="radio-btn-outline"
                        buttons
                    ></b-form-radio-group>
                </b-form-group>
            </div>
        </div>

        <b-modal id="invoice-create-preview"
            :ok-variant="`secondary`"
            :ok-title="$ml.get('CREATE_INVOICE')"
            :cancel-variant="`dark-outline`"
            @ok="createStripeInvoice"

        >
            <p>You are about to create and send out a Strip invoice with the following information:</p>
            <div class="company-preview-block" v-if="previewObject.statsId">
                <b-img :src="previewObject.company.attributes.logo._url" fluid thumbnail></b-img>
                <h4>{{previewObject.company.attributes.name}}</h4>
            </div>

            <div class="billing-info" v-if="previewObject.statsId">
                <b-row>
                    <b-col cols="6">
                        <b-form-group label="Card count">
                            <b-form-input
                                v-model="previewObject.cardCount"
                                readonly
                            ></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Pricing tier">
                            <b-form-input
                                v-model="previewObject.pricing.tier"
                                readonly
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

            </div>


        </b-modal>


    </div>

</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Select2 from 'v-select2-component';
import Parse from "parse";
import Api from "@/store/services/Api";

export default {
    name: "PromoList",
    components: {
        VueApexCharts,
        Select2
    },
    data(){
        return {
            something: false,
            previewObject: {},

            selectedClientOfId: null,
            generalChoices: [],
            companyPlaceholder: require('../../assets/icon_bw.png'),


            modeOptions: [
                { text: 'Per agency', value: 'allTime' },
                { text: 'Global', value: 'monthly' }
            ],

            selectedYear : new Date().getFullYear(),
            selectedMonth: new Date().getMonth() + 1,


            monthOptions:[],
            statsRows: [],


            toggleMode: 'allTime',
            listOptions: [
                { text: 'All time', value: 'allTime' },
                { text: 'Current month', value: 'monthly' }
            ],


            searchString: '',
            currentPromo: {
                attributes: {

                }
            },
            emptyHtml: '<div class="empty-table">No results found</div>',
            selectAllStatus: false,
            selectedRows: {},
            tableColumns: [
                { key: 'attributes.relatedCompany.attributes.logo', label: '', thClass: 'tbl-pic font-bebas-neue',tdClass:'td-avatar', sortable: false},
                { key: 'attributes.relatedCompany.attributes.name', label: 'Company name',  thClass: 'font-bebas-neue font-small-4', tdClass: 'td-elipsis-150', sortable: true},
                { key: 'attributes.cardCount', label: 'Cards', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'pricing.price', label: 'Pricing Tier', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'billable', label: 'Billable Amount', thClass: 'font-bebas-neue font-small-4', sortable: true},

                { key: 'invoice', label: 'Invoice',  thClass: 'font-bebas-neue font-small-4', sortable: false},
                { key: 'status', label: 'Status', thClass: 'font-bebas-neue font-small-4', sortable: true},

                {
                    key: 'actions',
                    label: 'Actions',
                    thClass: 'font-bebas-neue text-center font-small-4',
                    tdClass: 'text-right',
                    sortable: false
                }
            ],
            monthlyColumns: [
                { key: 'checkbox', label: 'Select', class: 'checkbox-column'},
                { key: 'attributes.logo', label: '', thClass: 'tbl-pic font-bebas-neue',tdClass:'td-avatar', sortable: false},
                { key: 'attributes.name', label: 'Company name',  thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.billingStart', label: 'Billing start',  thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.totalInvoiced', label: 'Billed', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.totalPaid', label: 'Paid', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.totalUsed', label: 'Usage', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'balance', label: 'Balance', thClass: 'font-bebas-neue font-small-4', sortable: true},
                {
                    key: 'actions',
                    label: 'Actions',
                    thClass: 'font-bebas-neue text-center font-small-4',
                    tdClass: 'text-right',
                    sortable: false
                }
            ],

            /* PAGINATION STUFF */
            curPageValue: 1,
            totalResults: 0,

            perPageOptions: [
                {
                    value: 5,
                    text: 5
                },
                {
                    value: 10,
                    text: 10
                },
                {
                    value: 25,
                    text: 25
                },
                {
                    value: 50,
                    text: 50
                },
                {
                    value: 100,
                    text: 100
                },
            ],
            curPageOptions: [
                {
                    value: 1,
                    text: 1
                }
            ],
            perPageValue: 10,

            report: {
                total_paid: 100,
                total_not_paid: 13,
            },

            mapOptions: {
                chart: {
                    type: 'treemap',
                    height: '100%',
                    width: '100%'
                },
                legend: {
                    show: false
                },

            },
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: '100%',
                    width: '100%'
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        // dataLabels: {
                        //     position: 'top',
                        // },
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function(val) {
                        return "€ "+val.toFixed(2);
                    },
                    style: {
                        colors: ['#fff'] // This will set the color of the percentage text. Change as needed.
                    }
                },
                labels: [
                    'PAID',
                    'NOT_PAID'
                ],
                colors: [
                    '#28c77a', //Paid
                    '#ff9800', //Partial
                    '#00cfe8', //Overpaid

                    '#ea5455', //Not paid
                    '#79082A', //Overdue
                    'rgba(185,185,195,0.6)', //Void
                ],
                // responsive: [{
                //     breakpoint: 480,
                //     options: {
                //         legend: {
                //             position: 'bottom'
                //         }
                //     }
                // }]
            },
            mapEntries: [],


        }
    },

    created(){
        this.getAllAgencies();
    },
    computed: {
        availableYears(){
          let curYear = new Date().getFullYear();
          let startYear = 2022;
          let years = [];
          for(let i = startYear; i <= curYear; i++){
             years.push(i);
          }
          return years;
        },

        availableMonths() {
            let months = [];
            const monthFormatter = new Intl.DateTimeFormat('en', { month: 'long' });

            if (this.selectedYear == new Date().getFullYear()) {
                let curMonth = new Date().getMonth();
                for (let i = 0; i <= curMonth; i++) {
                    months.push({
                        value: i + 1,
                        text: monthFormatter.format(new Date(2023, i)) // Arbitrary year
                    });
                }
            } else {
                for (let i = 0; i < 12; i++) {
                    months.push({
                        value: i + 1,
                        text: monthFormatter.format(new Date(2023, i)) // Arbitrary year
                    });
                }
            }

            return months;
        },

        userCompany() {
            return this.$store.state.userCompany;
        },
        isCore(){
            if(this.$store.state.userCompany && this.$store.state.userCompany.attributes){
                return this.$store.state.userCompany.attributes.core;
            }else {
                return false;
            }
        },
        generalSelectCompanies() {
            let selectData = [];

            if (this.generalChoices) {
                this.generalChoices.forEach(oneAgency => {
                    let oneLine = {
                        id: oneAgency.id,
                        text: oneAgency.attributes.name,
                        fullData: oneAgency.attributes,
                        companyObj: oneAgency,
                        isAgency: true
                    };
                    selectData.push(oneLine);
                });
            }
            return selectData;

        },
        series() {
            let series = []

            if (this.report) {

                series = [
                {
                    data: [200, 50, 60]
                },
                {
                    data: [44]
                },
                {
                    data: [0,44]
                }
                ]
            }
            return series
        },
        mapSeries() {
            let mapSeries =  [
                { data: []}
            ];

            if(this.tableRows.length > 0) {
                this.tableRows.forEach((item) => {
                    mapSeries[0].data.push({
                        x: item.attributes.name,
                        y: item.attributes.totalUsed
                    })
                });
            }

            console.log("mapSeries: ", mapSeries)
            return mapSeries;
        },
        hasMapEntries () {
            return this.mapSeries[0].data.length > 0;
        },
        labels() {
            // let labels = []
            //
            // if (this.report) {
            //     labels = [
            //         'PAID',
            //         'NOT_PAID'
            //     ]
            // }
            return [
                'BILLABLE THIS MONTH',
                'INVOICED',
                'PAID',
            ]
        },
        tableRows() {
            let rows = this.$store.state.privateAgencyClients;
            if( this.$store.state.userCompany
                && this.$store.state.userCompany.attributes.isAgency
                && !this.$store.state.userCompany.attributes.core
            ){
                const isUserCompanyPresent = rows.some(item => item.id === this.$store.state.userCompany.id);
                if (!isUserCompanyPresent) {
                    rows.push(this.$store.state.userCompany);
                }
            }

            let suspended = rows.filter((item) => {
                return item.attributes.suspended;
            });

            let active = rows.filter((item) => {
                return !item.attributes.suspended;
            });

            return this.showSuspended ? suspended : active;
        },


        resultsFrom() {
            if(this.$store.state.employeeCardCount == 0) {
                return 0;
            }
            else {
                return ((this.curPageValue-1)*this.perPageValue)+1;
            }

        },
        resultsTo() {
            let pageMaxResults = this.curPageValue*this.perPageValue;

            if(pageMaxResults > this.$store.state.employeeCardCount){
                return this.$store.state.employeeCardCount;
            }else{
                return pageMaxResults;
            }
        },
    },
    watch: {
        selectedYear(newYear) {
            if(newYear != new Date().getFullYear()){
                this.selectedMonth = 12;
            }
            this.fetchData();
        },
        selectedMonth() {
            this.fetchData();
        },
        selectedClientOfId() {
            this.fetchData();
        }
    },
    methods: {
        editPricing(id = null) {
            this.$store.commit('toggleDashboardModal', {name: 'PricingForm', data: {id: id, dataSet: 'agencyClients'}})
        },
        computePricing(item){
            let pricing = item.attributes.relatedCompany.attributes.relatedPricing;
            let cardCount = item.attributes.cardCount;
            let activeLevel = {};

            if(pricing){
                let scopeMaxLevel = 5;
                let min = 1;
                //let self = this;


                for (let i = 1; i <= scopeMaxLevel; i++) {
                    if (pricing.attributes[`pricingTier${i}`]) {
                        let level = {
                            tier: i,
                            from: min,
                            to: pricing.attributes[`threshold${i}`] - 1,
                            price: pricing.attributes[`pricingTier${i}`],
                            active: cardCount >= min && cardCount <= pricing.attributes[`threshold${i}`] - 1
                        }
                        if (level.active) {
                            activeLevel = level;
                        }
                        min = pricing.attributes[`threshold${i}`];
                    }
                }
            }
            return activeLevel;
        },
        hasInvoice(item){
            return item.attributes.relatedInvoice ? true : false;
        },

        createStripeInvoice(){
            let payload = {
                compId: this.previewObject.company.id,
                monthlyStatId: this.previewObject.statsId,
            }
            let formData = new FormData();
            formData.append('action', 'makeStripeInvoice');
            formData.append('key', 'qf1291!?');
            formData.append('data', JSON.stringify(payload));

            this.$store.commit('setGlobalLoaderMessage', 'Creating stripe invoice...');
            this.$store.commit('setGlobalLoader', true);


            Api().post('helpers/api.php', formData)
                .then((response) => {
                    console.log('response', response);
                    this.$notify({
                        group: 'notification',
                        type: 'success',
                        text: 'Invoice created successfully'
                    });
                    this.fetchData()
                })
                .catch((error) => {
                    console.log('error', error);
                }).finally(() => {
                    this.$store.commit('setGlobalLoaderMessage', '');
                    this.$store.commit('setGlobalLoader', false);
                });
        },

        addInvoice(item){
            this.previewObject.company = item.attributes.relatedCompany;
            this.previewObject.cardCount = item.attributes.cardCount;
            this.previewObject.statsId = item.id;
            this.previewObject.pricing = this.computePricing(item);
            this.previewObject.billable = item.pricing.price * item.attributes.cardCount;
            console.log('previewObject', this.previewObject);
            this.$bvModal.show('invoice-create-preview');
        },

        syncWithStripe(item){
            let formData = new FormData;
            formData.append('action', 'syncWithStripe');
            formData.append('key', 'qf1291!?');
            formData.append('stripeId', item.attributes.relatedCompany.attributes.stripeId);
            formData.append('objectId', item.attributes.relatedCompany.id);


            this.$store.commit('setGlobalLoaderMessage', 'Syncing with stripe...');
            this.$store.commit('setGlobalLoader', true);

            Api().post('helpers/api.php', formData)
                .then((response) => {
                    console.log('response', response);
                    this.$notify({
                        group: 'notification',
                        type: 'success',
                        text: 'Synced with stripe successfully'
                    });
                })
                .catch((error) => {
                    this.$notify({
                        group: 'notification',
                        type: 'error',
                        text: 'Error syncing with stripe'
                    });
                    console.log('error', error);
                }).finally(() => {
                    this.$store.commit('setGlobalLoaderMessage', '');
                    this.$store.commit('setGlobalLoader', false);
                });
        },

        seeBilling(item){
            this.$store.commit('setCardCompany', item.attributes.relatedCompany);
            this.$router.push({name: 'invoices'});
        },

        fetchData(){
            let payload = {
                year: this.selectedYear,
                month: this.selectedMonth,
                agencyId: this.selectedClientOfId
            }

            this.$store.dispatch('getBillingData', payload)
                .then(
                (data) =>{
                    console.log('months', data);
                    data.forEach((item) => {
                        item.pricing = this.computePricing(item);
                        item.billable = item.pricing.price * item.attributes.cardCount;
                        if(item.attributes.relatedCompany.attributes.isAgency){
                            item._rowVariant = 'info';
                        }
                    });

                    this.statsRows = data;

                    this.totalResults = data.length;
                    this.curPageOptions = [];
                    for(let i = 1; i <= Math.ceil(this.totalResults/this.perPageValue); i++){
                        this.curPageOptions.push({value: i, text: i});
                    }
                }
            );
        },
        selectionPreview(selection) {
            let markup = '';

            if (selection.children && selection.children.length > 0) {
                // It's an agency with children
                let agency = selection.companyObj.attributes;
                if (!agency.logo) {
                    agency.logo = {
                        _url: this.companyPlaceholder
                    }
                }

                markup += `<div class="agency selection">`;
                markup +=   `<span class="profile-avatar">`;
                markup +=       `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                markup +=   `</span>`;
                markup +=   `<h4>` + selection.text + `</h4>`;
                markup += `</div>`;
            } else if (selection.fullData) {
                let item = selection.fullData;

                if (!item.logo) {
                    item.logo = {
                        _url: this.companyPlaceholder
                    }
                }

                markup += selection.fullData.suspended ? `<div class="company selection suspended">` : `<div class="company selection">`;

                markup += `<span class="profile-avatar">`;
                markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                markup += `</span>`;
                if(item.clientOf && !selection.fullData.isAgency){
                    markup += `<div class="item-name">`;
                    markup += `<h4>` + item.name + `</h4>`;
                    markup += `<div class="client-of-name">`+item.clientOf.attributes.name+`</div>`;
                    markup += `</div>`;
                }else {
                    markup += `<h4>` + item.name + `</h4>`;
                }
                markup += `</div>`;
            }

            return markup;
        },
        companyPreview(choice) {
            let markup = '';

            if (choice.isAgency) {
                let agency = choice.fullData;
                if (!agency.logo) {
                    agency.logo = {
                        _url: this.companyPlaceholder
                    }
                }

                markup += `<div class="agency" data-agency-id="${choice.id}">`;
                markup += `<span class="profile-avatar">`;
                markup += `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                markup += `</span>`;
                markup += `<h4 class="d-inline">` + choice.text + `</h4>`;
                markup += `</div>`;
            } else if (choice.fullData) {
                let item = choice.fullData;

                if (!item.logo) {
                    item.logo = {
                        _url: this.companyPlaceholder
                    }
                }

                if (item.suspended) {
                    markup = `<div class="company suspended">`;
                } else {
                    markup += choice.isOwnCompany ? `<div class="company own">` : `<div class="company">`;
                }

                markup += `<span class="profile-avatar">`;
                markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                markup += `</span>`;

                if(item.clientOf && !item.isAgency){
                    markup += `<div class="item-name">`;
                    markup += `<h4>` + item.name + `</h4>`;
                    markup += `<div class="client-of-name">`+item.clientOf.attributes.name+`</div>`;
                    markup += `</div>`;
                }else {
                    markup += `<h4>` + item.name + `</h4>`;
                }
                markup += `</div>`;
            }

            return markup;
        },
        getAllAgencies() {
            if(this.isCore) {
                let compQuery = new Parse.Query('Company');
                compQuery.equalTo('isAgency', true);
                compQuery.notEqualTo('core', true);
                compQuery.descending('priority');

                if(!this.showSuspended){
                    compQuery.notEqualTo('suspended', true);
                }

                compQuery.include('relatedCompanies');
                compQuery.find().then((agencies) => {

                    // Sort the agencies array
                    const sortedAgencies = agencies.sort((a, b) => {
                        // Get priority values
                        const priorityA = a.get('priority') || 0;
                        const priorityB = b.get('priority') || 0;

                        // Compare priorities
                        if (priorityA !== priorityB) {
                            return priorityB - priorityA; // Descending order by priority
                        }

                        // If priorities are the same or undefined, sort alphabetically by name
                        const nameA = a.get('name') ? a.get('name').toLowerCase() : '';
                        const nameB = b.get('name') ? b.get('name').toLowerCase() : '';

                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0; // Names are equal
                    });

                    // Log sorted agencies
                    sortedAgencies.forEach(agency => {
                        let agencyObj = agency.attributes;
                        // Get and sort related companies (clients) alphabetically by name
                        let sortedClients = (agencyObj.relatedCompanies || []).sort((clientA, clientB) => {
                            const nameA = clientA.get('name') ? clientA.get('name').toLowerCase() : '';
                            const nameB = clientB.get('name') ? clientB.get('name').toLowerCase() : '';

                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            return 0; // Names are equal
                        });

                        agency.sortedClients = sortedClients;


                    });
                    this.generalChoices = sortedAgencies;
                    this.selectedClientOfId = sortedAgencies[0].id;
                    this.fetchData();
                });

            }
            else {
                let agencyObj = this.userCompany.attributes;
                let sortedClients = (agencyObj.relatedCompanies || []).sort((clientA, clientB) => {
                    const nameA = clientA.get('name') ? clientA.get('name').toLowerCase() : '';
                    const nameB = clientB.get('name') ? clientB.get('name').toLowerCase() : '';

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0; // Names are equal
                });

                this.userCompany.sortedClients = sortedClients;
                this.generalChoices = [this.userCompany];
            }
        },



        /*PAGINATION */
        maxPages() {
            return Math.ceil(this.totalResults/this.resultsPerPage);
        },
        changeCurPage(direction) {

            if(direction == 'next') {
                if(this.curPageValue < this.maxPages){
                    this.curPageValue++;
                }
            }else if (direction == 'prev') {
                if(this.curPageValue > 1){
                    this.curPageValue--;
                }
            }

        },
    }

}
</script>

<style>
.company-logo {
    max-height: 20px;
    max-width: 50px;
}

.qf-stats-container {
    max-width: 800px;
    width: 100%;
    min-width: 500px;
}

.qf-charts {
    width: 100%;
    display: flex;
}

</style>
