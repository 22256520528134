import Parse from 'parse'

export const OfficeAddressService = {
    getOfficeAddresses(context) {
        let companyAddresses = Parse.Object.extend('OfficeAddress');
        let query = new Parse.Query(companyAddresses);
        query.equalTo('company', context.state.cardCompany);
        return new Promise((resolve, reject) => {
            query.find().then(response => {
                context.commit('setOfficeAddresses', response);
                resolve(response)
            }).catch(error => {
                reject(error);
            })
        });
    },
    saveOfficeAddress(context, data) {
        let officeAddresses = Parse.Object.extend('OfficeAddress');
        if (data.id === null) {
            let officeAddressRecord = new officeAddresses();
            let compPointer = {"__type": "Pointer", "className": "Company", "objectId": data.compId ? data.compId : context.state.cardCompany.id}
            return new Promise((resolve, reject) => {
                officeAddressRecord.set('officeLabel', data.officeLabel);
                officeAddressRecord.set('officePhone', data.officePhone);
                officeAddressRecord.set('website', data.website);
                officeAddressRecord.set('street', data.street);
                officeAddressRecord.set('zip', data.zip);
                officeAddressRecord.set('city', data.city);
                officeAddressRecord.set('state', data.state);
                officeAddressRecord.set('country', data.country);

                officeAddressRecord.set('socialYouTube', data.socialYouTube);
                officeAddressRecord.set('socialTwitter', data.socialTwitter);
                officeAddressRecord.set('socialInstagram', data.socialInstagram);

                officeAddressRecord.set('company', compPointer);

                officeAddressRecord.save().then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                context.getters.selectedModalData('officeAddresses').set('officeLabel', data.officeLabel);
                context.getters.selectedModalData('officeAddresses').set('officePhone', data.officePhone);
                context.getters.selectedModalData('officeAddresses').set('website', data.website);
                context.getters.selectedModalData('officeAddresses').set('street', data.street);
                context.getters.selectedModalData('officeAddresses').set('city', data.city);
                context.getters.selectedModalData('officeAddresses').set('zip', data.zip);
                context.getters.selectedModalData('officeAddresses').set('state', data.state);
                context.getters.selectedModalData('officeAddresses').set('country', data.country);

                context.getters.selectedModalData('officeAddresses').set('socialYouTube', data.socialYouTube);
                context.getters.selectedModalData('officeAddresses').set('socialTwitter', data.socialTwitter);
                context.getters.selectedModalData('officeAddresses').set('socialInstagram', data.socialInstagram);

                context.getters.selectedModalData('officeAddresses').set('company', context.state.cardCompany);
                context.getters.selectedModalData('officeAddresses').save().then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
            });
        }
    },
    deleteOfficeAddress(context) {
        return new Promise((resolve, reject) => {
                context.getters.selectedModalData('officeAddresses').destroy().then(response => {
                    context.dispatch('getOfficeAddresses');
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
            }
        );
    }
};
