<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="table-top">
                <div class="table-top-buttons">
                    <button-field @click="editEmployee()"
                                  icon="plus"
                                  color="primary">{{$ml.get('ADD_EMPLOYEE')}}
                    </button-field>
                    <button-field @click="toggleModal('DashboardEmployeeCardsImport')"
                                  icon="file-import"
                                  color="secondary">{{$ml.get('IMPORT_FROM_CSV')}}
                    </button-field>
                </div>
                <div class="table-count">
                    <span class="card-count">{{$ml.get('TOTAL_CARD_COUNT')}}: {{resultsFrom}} - {{resultsTo}} / {{totalResults}}</span>
                </div>
                <div class="search-container">
                    <font-awesome-icon icon="search"/>
                    <input type="text" maxlength="20" name="searchInput"
                           v-model="searchString"
                           class="search-input form-field"
                           :placeholder="$ml.get('search_by_name')"
                    />
                </div>
            </div>

            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <b-table
                        :fields="tableColumns"
                        :items="tableRows"
                        :empty-html="emptyHtml"
                        class="font-small-2 table-vertical-align-middle table-list"
                    >
                        <template v-slot:head(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectAllStatus"
                                @change="toggleSelectAll"
                                :indeterminate="someRowsSelected && !allRowsSelected"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedRows[data.item.id]"
                                @change="onRowSelect(data.item.id, $event)"
                                :value="true"
                                :unchecked-value="false"
                                variant="secondary"
                                class="accent-secondary b-table-checkbox"
                            />
                        </template>

                        <template #cell(attributes.profilePicture)="data">
                            <div class="position-relative">
                                <span class="status-circle"
                                      :class="sentStatusClass(data.item)"
                                      v-tooltip="sentOnTooltip(data.item)"
                                >
                                </span>
                                <span class="profile-avatar text-center cursor-pointer" @click="previewCards(data.item)">
                                    <img v-if="data.item.attributes.profilePicture"
                                            :src="data.item.attributes.profilePicture._url"
                                            :alt="data.item.attributes.firstName + ' ' + data.item.attributes.lastName"
                                     >
                                    <font-awesome-icon v-else color="gray" icon="user" />
                                </span>
                            </div>
                        </template>

                        <template #cell(attributes.officeAddress)="data">
                            <div class="ellipsis" v-if="data.item.attributes.officeAddress">{{data.item.attributes.officeAddress.attributes.officeLabel}}</div>
                        </template>

                        <template #cell(attributes.position)="data">
                            {{data.item.attributes.position}}
                        </template>

                        <template #cell(attributes.mobilePhone)="data">
                            {{data.item.attributes.mobilePhone}}
                        </template>

                        <template #cell(attributes.email)="data">
                            {{data.item.attributes.email}}
                        </template>

                        <template #cell(attributes.createdAt)="data">
                            <div class="ellipsis">{{niceDate(data.item.attributes.createdAt)}}</div>
                        </template>

                        <template #cell(actions)="data">
                            <div class="table-action-btn-gorup">
                                <button-field :disabled="sharing"
                                              circle
                                              outline
                                              @click="previewCards(data.item)"
                                              icon="eye"
                                              class="table-action-btn"
                                              v-tooltip="'Preview Card and Data'"
                                              color="dark"></button-field>
                                <button-field :disabled="sharing"
                                              circle
                                              outline
                                              @click="copyCardLink([data.item.id], [data.item.attributes.cardToken])"
                                              icon="copy"
                                              class="table-action-btn"
                                              v-tooltip="'Copy Installation Link'"
                                              color="dark"></button-field>
                                <button-field :disabled="sharing || !designReady"
                                              circle
                                              outline
                                              @click="shareCards([data.item.id])"
                                              icon="paper-plane"
                                              class="table-action-btn"
                                              v-tooltip="designReady ? 'Send Installation Link per E-mail' : 'Sending cards will be enabled once design is ready'"
                                              color="secondary"></button-field>
                                <button-field @click="editEmployee(data.item.id)"
                                              circle
                                              outline
                                              icon="edit"
                                              class="table-action-btn"
                                              v-tooltip="'Edit information'"
                                              color="primary"></button-field>
                                <button-field @click="deleteEmployee(data.item.id)"
                                              circle
                                              outline
                                              icon="trash-alt"
                                              class="table-action-btn"
                                              v-tooltip="'Delete card'"
                                              color="error"></button-field>
                            </div>
                        </template>

                    </b-table>
                </div>
            </div>

            <div class="table-bottom">
                <div class="table-bottom-buttons">
                    <button-field icon="paper-plane"
                                  color="secondary"
                                  :disabled="!selectedRowsTrue.length || sharing || !designReady"
                                  :loading="sharing"
                                  v-tooltip="designReady ? '' : 'Sending cards will be enabled once design is ready'"
                                  @click="shareCards(selectedRowsTrue)">{{$ml.get('SEND_SELECTED')}}
                        ({{selectedRowsTrue.length}})
                    </button-field>
                    <button-field @click="bulkDelete"
                                  icon="trash-alt"
                                  color="error"
                                  :disabled="!selectedRowsTrue.length">{{$ml.get('DELETE_SELECTED')}}
                        ({{selectedRowsTrue.length}})
                    </button-field>
                </div>
                <div class="table-pagination">
                    <a href="#" @click="changeCurPage('prev')" :class="{ 'disable-click' : curPageValue==1 }">« {{$ml.get('Prev')}}</a>
                    <select-field :name="`curPage`"
                                  :id="`curPage`"
                                  :options="curPageOptions"
                                  :value="curPageValue"
                                  v-model="curPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PAGE')}}:
                    </select-field>
                    <a href="#" @click="changeCurPage('next')" :class="{ 'disable-click' : curPageValue==maxPages }">{{$ml.get('Next')}} »</a>
                </div>

                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>
            </div>
        </div>

        <b-modal id="preview-card"
             size="lg"
            :ok-only="true"
            :ok-variant="'secondary'"
            :ok-title="'Close'"
            :title="`Card Preview`"
            @ok="clearPreviewParams"
        >

            <template v-slot:modal-footer="{ ok }">
                <b-button @click="ok" variant="outline-dark">Close</b-button>
                <b-button @click="copyCardLink(previewParams.id,previewParams.token )" variant="secondary">Copy Link to Clipboard</b-button>
            </template>
            <b-row>
                <b-col cols="5">
                    <div class="wrapper-phone iphone">
                        <iframe
                                class="cardPreview"
                                :src="apiUrl+'cards/'+previewParams.id+'/'+previewParams.token+'?fm=1'" width="100%" height="100%" frameborder="0"></iframe>
                    </div>
                </b-col>
                <b-col>
                    <div class="d-flex align-items-center">
                        <p class="pt-2 font-small-3">Below is the full list of data that your business partners will receive when they scan the QR code of this business card.</p>
                    </div>
                    <b-table
                        id="preview-data-table"
                        :fields="cardPreviewColumns"
                        :items="cardPreviewRows">
                        <template #cell(info)="data">
                            <span class="font-small-2">{{data.item.info}}</span>
                        </template>

                        <template #cell(value)="data">
                            <b-link v-if="data.item.isLink" class="font-small-2" target="_blank" :href="data.item.value">{{data.item.value | stripGetParams}}</b-link>
                            <div v-else class="font-small-2">{{data.item.value | stripGetParams}}</div>
                        </template>

                    </b-table>
                    <div class="d-flex align-items-center">
                        <p class="pt-2 font-small-3">Click on <span class="text-secondary">"Copy Link to Clipboard"</span> to get the <strong>installation link</strong> below, so that you can then send it via instant messaging.</p>
                    </div>

                </b-col>
            </b-row>
        </b-modal>

        <b-modal id="design-not-ready"
            :ok-only="userCompany.attributes.status === '01_new' || userCompany.attributes.status === 'x_prio'"
            :ok-variant="'secondary'"
            :ok-title="(userCompany.attributes.status === '01_new' || userCompany.attributes.status === 'x_prio') ? 'OK' : 'Start trial'"
            @ok="noticeAction"
            :cancel-variant="`outline-dark`"
        >
            <div class="text-center" v-if="userCompany.attributes.status == '01_new' || userCompany.attributes.status === 'x_prio'">
                <font-awesome-icon
                    icon="exclamation-triangle"
                    class="text-primary mb-2"
                    size="2x" />
                <h5 class="pb-3">Business card Design not ready yet!</h5>
                <p class="text-dark font-small-3">Your digital business card design is currently being prepared!</p><p class="font-small-3">Card preview, sharing and installation is therefore not yet available! You will receive a notification e-mail once everything is ready.</p>
            </div>

            <div class="text-center" v-else>
                <font-awesome-icon
                    icon="exclamation-triangle"
                    class="text-primary mb-2"
                    size="2x" />
                <h5 class="pb-3">Account trial period has to be activated!</h5>
                <p class="font-small-3">Card preview, sharing and installation is therefore not yet available! Click on the button below to start your trial and enable all features.</p>
            </div>

        </b-modal>

    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "DashboardEmployeeCards",
        computed: {
            userCompany() {
                return this.$store.state.userCompany;
            },
            designReady() {
                if(this.userCompany && this.userCompany.attributes.status !== '01_new' && this.userCompany.attributes.status !== 'x_prio'){
                    return true;
                }else{
                    return false;
                }
            },
            currentPageItems() {
                return this.tableRows.slice(
                    (this.curPageValue - 1) * this.perPageValue,
                    this.curPageValue * this.perPageValue
                );
            },
            allRowsSelected() {
                return this.currentPageItems.length > 0 && this.currentPageItems.every(item => this.selectedRows[item.id]);
            },
            someRowsSelected() {
                const selectedCount = this.currentPageItems.filter(item => this.selectedRows[item.id]).length;
                return selectedCount > 0 && selectedCount < this.currentPageItems.length;
            },
            tableRows() {
                return this.$store.state.employeeCards;
            },
            resultsPerPage() {
                return this.$store.state.employeeCardsPP;
            },
            totalResults() {
                return this.$store.state.employeeCardCount;
            },
            resultsFrom() {
                if(this.$store.state.employeeCardCount == 0) {
                    return 0;
                }
                else {
                    return ((this.curPageValue-1)*this.perPageValue)+1;
                }

            },
            resultsTo() {
                let pageMaxResults = this.curPageValue*this.perPageValue;

                if(pageMaxResults > this.$store.state.employeeCardCount){
                    return this.$store.state.employeeCardCount;
                }else{
                    return pageMaxResults;
                }

            },
            getCurPageValue() {
                return this.$store.state.employeeCardPage;
            },
            curPageOptions() {
                let maxPageCount = Math.ceil(this.totalResults/this.resultsPerPage);
                let options = [
                        {
                        value: 1,
                        text: 1
                    }];
                let i = 2;
                while (i <= maxPageCount) {
                    options.push({
                        value: i,
                        text: i
                    });
                    i++;
                }
                return options;
            },
            maxPages() {
              return Math.ceil(this.totalResults/this.resultsPerPage);
            },
            selectedRowsTrue() {
                let selected = [];
                for (const key in this.selectedRows) {
                    if (this.selectedRows[key]) {
                        selected.push(key);
                    }
                }
                return selected;
            }
        },
        data() {
            return {
                cardPreviewColumns: [
                    { key: 'info', label: 'Info', thClass: 'd-none', tdClass: 'font-small-3' },
                    { key: 'value', label: 'Value', thClass: 'd-none', tdClass: 'font-small-3' }
                ],
                cardPreviewRows: [],
                previewCardData: {attributes: {}},
                apiUrl: process.env.VUE_APP_BASE_API_URL,
                previewParams: {},
                selectAllStatus: false,
                selectedRows: {},
                toggleAllRows: false,
                perPageOptions: [
                    {
                        value: 5,
                        text: 5
                    },
                    {
                        value: 10,
                        text: 10
                    },
                    {
                        value: 25,
                        text: 25
                    },
                    {
                        value: 50,
                        text: 50
                    },
                    {
                        value: 100,
                        text: 100
                    },
                ],
                tableColumns: [
                    { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
                    { key: 'attributes.profilePicture', label: '', thClass: 'tbl-pic font-bebas-neue',tdClass:'td-avatar', sortable: false},
                    { key: 'attributes.firstName', label: 'First name',  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    { key: 'attributes.lastName', label: 'Last name',  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    { key: 'attributes.position', label: 'Position', thClass: 'font-bebas-neue font-small-4', tdClass:'td-elipsis', sortable: true},
                    { key: 'attributes.mobilePhone', label: 'Mobile phone', thClass: 'font-bebas-neue font-small-4', sortable: false},
                    { key: 'attributes.email', label: 'Email', thClass: 'font-bebas-neue font-small-4', tdClass:'td-elipsis', sortable: true},
                    { key: 'attributes.officeAddress', label: 'Office', thClass: 'font-bebas-neue font-small-4', sortable: false},
                    { key: 'attributes.createdAt', label: 'Created at', thClass: 'font-bebas-neue font-small-4', sortable: true},
                    {
                        key: 'actions',
                        label: 'Actions',
                        thClass: 'font-bebas-neue text-center font-small-4',
                        tdClass: 'text-right',
                        sortable: false
                    }
                ],
                emptyHtml: '<div class="text-center"><font-awesome-icon icon="user" size="3x" color="gray" /><p class="mt-2">No data found</p></div>',

                curPageValue: 1,
                perPageValue: 25,
                sharing: false,
                searchString: null,
            }
        },
        mounted() {
            let self = this;
            this.$store.dispatch('getCompany').then(() => {
                self.$store.dispatch('getEmployeeCardCount').then(() => {
                    self.$store.dispatch('getEmployeeCards').then(() => {
                    });
                    self.$store.dispatch('getCompAddresses').then(() => {
                    });
                });
            });
            this.$store.commit('closeRightModal');
        },
        methods: {
            noticeAction(){
                if(this.userCompany.attributes.status == '01_new' || this.userCompany.attributes.status == 'x_prio'){
                    this.$bvModal.hide('design-not-ready');
                }else{
                    this.$bvModal.hide('design-not-ready');
                    this.$bvModal.show('activate-trial');
                }
            },

            copyCardLink(ids, token){
                let self = this;
                if(self.designReady){
                    let url = process.env.VUE_APP_BASE_API_URL+"cards/"+ids+"/"+token;
                    navigator.clipboard.writeText(url).then(function() {
                        self.$bvToast.toast('Card link copied to clipboard!', {
                            title: 'Success',
                            variant: 'success',
                            solid: true,
                            autoHideDelay: 2500
                        });
                    }, function() {
                        self.$bvToast.toast('Failed to copy card link to clipboard!', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                            autoHideDelay: 3000
                        });
                    });
                } else {
                    self.$bvModal.show('design-not-ready');
                }
            },
            toggleSelectAll() {
                const newState = !this.allRowsSelected;
                this.currentPageItems.forEach(item => {
                    this.$set(this.selectedRows, item.id, newState);
                });
                this.updateSelectAllStatus();
            },
            onRowSelect(id, event) {
                // Safely check if event and event.target exist before accessing properties
                const isChecked = event && event.target && event.target.checked !== undefined
                    ? event.target.checked
                    : this.selectedRows[id]; // Default to the current state if event is not well-formed

                this.$set(this.selectedRows, id, isChecked);
                this.updateSelectAllStatus();
            },
            updateSelectAllStatus() {
                if (this.allRowsSelected) {
                    this.selectAllStatus = true;
                } else if (this.someRowsSelected) {
                    this.selectAllStatus = null; // Set to null to reflect the indeterminate state
                } else {
                    this.selectAllStatus = false;
                }
            },
            onSelectAll(val) {
                const currentPageItems = this.tableRows.slice(
                    (this.curPageValue - 1) * this.perPageValue,
                    this.curPageValue * this.perPageValue
                );

                currentPageItems.forEach(item => {
                    this.$set(this.selectedRows, item.id, val);
                });

                this.selectAllStatus = val;
            },
            sentStatusClass(item){
                if(item.attributes && item.attributes.sentAt){
                    return 'active';
                }else{
                    return 'not-sent';
                }
            },
            sentOnTooltip(item){
                if(item.attributes && item.attributes.sentAt){
                    return this.$ml.get('Sent_on') +' '+ this.niceDate(item.attributes.sentAt);
                }else{
                    return this.$ml.get('Card_not_yet_sent');
                }
            },
            niceDate(date){
                return moment(date).format('DD/MM/Y');
            },
            changeCurPage(direction) {

                if(direction == 'next') {
                    if(this.curPageValue < this.maxPages){
                        this.curPageValue++;
                    }
                }else if (direction == 'prev') {
                    if(this.curPageValue > 1){
                        this.curPageValue--;
                    }
                }

            },
            updateResultsPP(){
                let self = this;
                self.$store.dispatch('getEmployeeCardCount').then(() => {
                    self.$store.dispatch('getEmployeeCards').then(() => {
                    });
                    self.$store.dispatch('getCompAddresses').then(() => {
                    });
                });
            },

            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName})
            },
            editEmployee(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardEmployeeCardsForm', data: {id: id}})
            },
            deleteEmployee(id) {
                // DashboardEmployeeCardsDelete
                this.$store.commit('toggleDashboardModal', {name: 'DashboardEmployeeCardsDelete', data: {ids: [id]}})
            },

            bulkDelete() {
                this.$store.commit('toggleDashboardModal', {
                    name: 'DashboardEmployeeCardsDelete',
                    data: {ids: this.selectedRowsTrue}
                })
            },
            shareCards(ids) {
                this.$store.commit('toggleDashboardModal', {name: 'EmployeeCards_Share', data: {ids: ids}})
            },
            clearPreviewParams(){
                this.previewCardData = {};
                this.previewParams = {};
                this.$bvModal.hide('preview-card');
            },
            previewCards(card) {
                if(this.designReady){
                    this.previewCardData = card;
                    this.cardPreviewRows = [
                        {info: 'First Name: ', value: card.attributes.firstName},
                        {info: 'Last Name: ', value: card.attributes.lastName},
                        {info: 'Position: ', value: card.attributes.position},
                        {info: 'Department: ', value: card.attributes.department},
                        {info: 'Mobile Phone: ', value: card.attributes.mobilePhone},
                        {info: 'Office Phone: ', value: card.attributes.officePhone ? card.attributes.officePhone : card.attributes.officeAddress.attributes.officePhone},
                        {info: 'Email: ', value: card.attributes.email},
                        {info: 'Website: ', value: card.attributes.officeAddress.attributes.website, isLink: true},
                        {info: 'Office Address: ', value: card.attributes.officeAddress.attributes.street+' '+card.attributes.officeAddress.attributes.zip+' '+card.attributes.officeAddress.attributes.city+' '+card.attributes.officeAddress.attributes.country},
                        {info: 'Notes (keywords): ', value: card.attributes.notes},
                    ];

                    if(card.attributes.officeAddress.attributes.socialInstagram){
                        this.cardPreviewRows.push({info: 'Instagram: ', value: card.attributes.officeAddress.attributes.socialInstagram});
                    }
                    if(card.attributes.socialTwitter){
                        this.cardPreviewRows.push({info: 'X (Company): ', value: card.attributes.socialTwitter, isLink: true});
                    }
                    if(card.attributes.socialTwitter){
                        this.cardPreviewRows.push({info: 'X (Personal): ', value: card.attributes.socialTwitter, isLink: true});
                    }
                    if(card.attributes.officeAddress.attributes.socialLinkedIn){
                        this.cardPreviewRows.push({info: 'LinkedIn(Company): ', value: card.attributes.officeAddress.attributes.socialLinkedIn, isLink: true});
                    }
                    if(card.attributes.socialLinkedIn){
                        this.cardPreviewRows.push({info: 'LinkedIn(Personal): ', value: card.attributes.socialLinkedIn, isLink: true});
                    }

                    this.previewParams = {id: card.id, token: card.attributes.cardToken};
                    this.$bvModal.show('preview-card');

                    //window.open(process.env.VUE_APP_BASE_API_URL+"cards/"+ids+"/"+token, '_blank');
                } else {
                    this.$bvModal.show('design-not-ready');
                }
            },
            doSearch(searchString){
                this.$store.dispatch('getEmployeeCards', {searchTerm : searchString})
            }
        },
        watch : {
            selectedRows: {
                handler() {
                    this.updateSelectAllStatus();
                },
                deep: true
            },
            selectAllStatus() {
                this.$nextTick(() => {
                    if (this.allRowsSelected) {
                        this.selectAllStatus = true;
                    } else if (this.someRowsSelected) {
                        this.selectAllStatus = null; // Represents indeterminate state
                    } else {
                        this.selectAllStatus = false;
                    }
                });
            },
            toggleAllRows(newVal){
                if(newVal){
                    this.selectedRows = {};
                    for (const key in this.tableRows) {
                        this.selectedRows[this.tableRows[key].id] = true;
                    }
                }else {
                    this.selectedRows = {};
                }
            },
            searchString(newSearch){
                clearTimeout(this.timeout);
                let self = this;
                self.timeout = setTimeout(() => {
                    self.doSearch(newSearch);
                }, 300);
            },
            perPageValue(newVal){
                this.$store.commit('setEmployeeCardsPP', newVal);
                this.$store.dispatch('setEmployeeCardPage', 1);
                this.curPageValue = 1;
                this.updateResultsPP();
            },
            curPageValue(newPageVal){
                this.$store.commit('setEmployeeCardPage', newPageVal);
                this.updateResultsPP();
                this.updateSelectAllStatus();
            },
        }
    }
</script>

<style scoped>

.wrapper-phone {
    width: 432px;
    height: 878px;
    overflow: hidden;
    position: relative;
    box-sizing: content-box;
    width: 216px;
    height: 439px;
    margin: 0 auto;
}
.iphone:after {
    content: '';
    background-image: url('/img/iphone-wrapper.png');
    background-repeat: no-repeat;
    background-size: 216px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
}
.iphone.template::after {
    pointer-events: auto;
}
.iphone > iframe,
.iphone img{
    position: relative;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    top: 9px;
    left: 11px;
    width: 387px;
    height: 834px;
    transition: none;
}
.template > svg {
    position: absolute;
    z-index: 2;
    font-size: 48px;
    color: var(--color-secondary);
    top: calc(50% - 24px - 20px);
    right: calc(50% - 24px);
    background: #fff;
    border-radius: 50%;
    border: 3px solid #ffffff;
    display: block;
}
.iphone::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 3px solid #efa633;
    border-right: 3px solid transparent;
    animation: spinner .6s linear infinite;
    z-index: 0;
}
@keyframes spinner {
    to {
        transform: rotate(360deg)
    }
}
.button-group-wrapper {
    flex-wrap: wrap;
    width: 100%;
}

</style>
