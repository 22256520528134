<template>
    <div class="billing">
        <div class="invoices">
            <div class="row">
                <div class="col-6">
                    <h4 class="pt-0 mt-0 mb-0">{{$ml.get('MONTHLY_USAGE_STATISTICS')}}</h4>
                    <p class="small" v-html="$ml.get('MONTHLY_USAGE_SUMMARY_TEXT')"></p>
                    <table class="table-list">
                        <thead class="font-bebas-neue">
                        <tr>
                            <th>{{$ml.get('MONTH')}}</th>
                            <th>{{$ml.get('MAXIMUM_ACTIVE_CARDS')}}</th>
                            <th>{{$ml.get('REACHED_ON')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr  v-for="stat in statsList" :key="stat.month+'-'+new Date('Y')" :class="isCurrentMonthRow(stat) ? 'cur-month-row': ''">
                            <td class="py-2 px-1">{{ stat.month }}
                                <span v-if="isCurrentMonthRow(stat)" class="more-info" v-tooltip="$ml.get('tooltip_estimated_usage')">
                                        <font-awesome-icon icon="info-circle"/>
                                    </span>
                            </td>
                            <td class="py-2 px-1">{{ stat.count }}</td>
                            <td class="py-2 px-1">{{ stat.reachedOn | dateFormat}}</td>
                        </tr>
                        <tr v-if="false" class="total-row">
                            <td colspan="3" class="py-2 px-1 text-align-right">Total</td>
                            <td>€ {{calcTotal(statsList)}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-6">
                    <div>
                        <vue-apex-charts
                            v-if="series[0].data.length > 0"
                            id="revenue-report-chart"
                            type="line"
                            height="360"
                            :options="chartOptions2"
                            :series="series"
                        />

                    </div>


                    <div v-if="series[0].data.length == 0" class="text-center pt-4">
                        <h4 class="placeholder-no-data mb-2">{{$ml.get('NO_DATA_YET')}}</h4>
                        <p class="text-grey-dark"><em>{{$ml.get('No_data_details')}}</em></p>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
//import moment from "moment";
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LinearScale,
    CategoryScale,
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    LinearScale,
    CategoryScale,
)

export default {
    name: "CardStats",
    components: {
        VueApexCharts
    },
    props: {
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 200
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        curCompanyId() {
            if(this.$store.state.cardCompany){
                return this.$store.state.cardCompany.id;
            }else{
                return null;
            }
        },

        colors() {
            let colorSets = [
                    '#6DA317',
                    '#ff9f43',
                    '#C21146',
                ]
            return colorSets;
        },

        chartOptions2() {

            let colors = this.colors;

            let chartOptions = {

                chart: {
                    height: 350,
                    type: 'line',
                    curve: 'smooth',
                    toolbar: { show: false }
                },
                stroke: {
                    curve: 'smooth'
                },
                dataLabels: {
                    enabled: true,
                },
                markers: {
                    size: 3,
                },
                title: {
                    text: this.$ml.get(`MONTHLY_USAGE`),
                    align: 'left'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                yaxis: {
                    min: this.minCountEver-10 > 0 ? this.minCountEver-10 : 0,
                    max: this.maxCountEver+10,
                    title: {
                        text: this.$ml.get(`MAX_ACTIVE_CARS`),
                    },

                },

                colors,

            }
            return chartOptions
        },
    },
    watch: {
        curCompanyId: function(newVal) {
            if (newVal) {
                this.fetchStats(newVal);
            }
        },

    },
    mounted() {
        if (this.curCompanyId) {
            this.fetchStats(this.curCompanyId);
        }
    },
    data() {
        return {
            curCompany: {},
            statsCount: 0,
            statsList: [],
            chartId: 'line-chart',
            maxCountEver: 50,
            minCountEver: 0,
            priceBrackets: [
                {
                    min: 0,
                    max: 39,
                    price: 1.60
                },
                {
                    min: 40,
                    max: 99,
                    price: 1.52
                },
                {
                    min: 100,
                    max: 499,
                    price: 1.44
                },
                {
                    min: 500,
                    max: 999,
                    price: 1.36
                },
                {
                    min: 1000,
                    max: 1999,
                    price: 1.28
                },
                {
                    min: 2000,
                    max: 9999,
                    price: 1.12
                },
            ],
            series: [
                {
                    name: 'Card Usage',
                    data: []
                }
            ],
        }
    },
    methods : {
        fetchStats() {
            let companyList = [this.curCompanyId];

            this.$store.dispatch('getClientStats', companyList).then(response => {
                this.statsCount = response.length;

                // Sort stats by year and month before processing
                let sortedResponse = response.sort((a, b) => {
                    let dateA = new Date(a.attributes.year, a.attributes.month - 1); // -1 because months are 0-indexed in JavaScript Date
                    let dateB = new Date(b.attributes.year, b.attributes.month - 1);
                    return dateA - dateB;
                });

                // Map the sorted stats to statsList
                this.statsList = sortedResponse.map((stat) => {
                    return {
                        monthOnly: stat.attributes.month,
                        month: stat.attributes.year + '-' + stat.attributes.month,
                        count: stat.attributes.cardCount,
                        reachedOn: stat.attributes.reachedOn,
                    };
                });

                // Calculate max and min counts ever
                let maxCounts = this.statsList.map(stat => stat.count);
                this.maxCountEver = Math.max(...maxCounts);
                this.minCountEver = Math.min(...maxCounts);

                // Prepare data points for the chart
                let monthNames = {
                    '1': 'January',
                    '2': 'February',
                    '3': 'March',
                    '4': 'April',
                    '5': 'May',
                    '6': 'June',
                    '7': 'July',
                    '8': 'August',
                    '9': 'September',
                    '10': 'October',
                    '11': 'November',
                    '12': 'December'
                };

                let dataPoints = this.statsList.map((stat) => {
                    return {
                        x: monthNames[stat.monthOnly],
                        y: stat.count
                    };
                });

                // Update series data for the chart
                this.series = [
                    { name: 'Card Usage', data: dataPoints }
                ];
            });
        },


        isCurrentMonthRow(stat){
            if(moment(stat.month).format('MM') == moment().format('MM')) {
                return true;
            }else {
                return false;
            }
        },
        calcRate(stat){
            let rate = this.priceBrackets.find((bracket) => {
                return bracket.min <= stat.count && bracket.max >= stat.count;
            });
            return rate.price.toFixed(2);
        },
        calcRowTotal(stat){
            let rate = this.calcRate(stat);
            let total = rate * stat.count;
            return total.toFixed(2);
        },
        calcTotal(stats){
            let total = 0;
            stats.forEach((stat) => {
                let rate = this.calcRate(stat);
                total += rate * stat.count;
            });
            return total.toFixed(2);
        },
        nextMultipleOfTen(x) {
            return x % 10 === 0 ? x + 10 : x + (10 - x % 10);
        },

    }
}
</script>

<style scoped>

.placeholder-no-data {
    color: var(--color-grey-dark);
    font-size: 1.2em;
    font-style: italic;
}

.text-grey-dark {
    color: var(--color-grey-dark);
}

.cur-month-row {
    color: var(--color-primary);
}

.cur-month-row .more-info {
    position: initial;
}

.total-row td {
    font-size:1.1em;
    color: black;
}

.text-align-right {
    text-align: right;
}
</style>
