import Parse from 'parse';

export const ProtectedDocsService = {
	getAvailableDocuments(context) {
		const query = new Parse.Query("ProtectedDoc");
		return new Promise((resolve, reject) => {
			query.find().then((results) => {
				context.commit("setProtectedDocuments", results);
				resolve(results);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	saveDocument(context, payload) {
		let protectedDoc = {}

		if(payload.id){
			protectedDoc = context.state.modalData;
		} else {
			let ProtectedDocs = Parse.Object.extend("ProtectedDoc");
			protectedDoc = new ProtectedDocs();
		}

		protectedDoc.set("name", payload.name);
		protectedDoc.set("description", payload.description);

		if(payload.newFile) {
			let newAttachment = new Parse.File(payload.name, payload.newFile.file);
			protectedDoc.set("attachment", newAttachment);
		}

		protectedDoc.set("description", payload.description);
		protectedDoc.set("language", payload.language);
		protectedDoc.set("type", payload.type);
		protectedDoc.set("revision", parseInt(payload.revision));

		return new Promise((resolve, reject) => {
			protectedDoc.save().then((result) => {
				context.dispatch("getAvailableDocuments");
				resolve(result);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	deleteDocument(context, payload) {
		return new Promise((resolve, reject) => {
			payload.destroy().then((result) => {
				context.commit("removeProtectedDocument", payload.id);
				resolve(result);
			}).catch((error) => {
				reject(error);
			});
		});
	},
}
