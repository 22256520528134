<template>
    <form class="auth-form"
          @submit.prevent="onboard">
        <div class="row">
            <div class="col text-center login-logo-row">
                <img src="../../../assets/qfecard-logo-white.svg" alt="logo">
            </div>
        </div>
        <transition>
            <div v-if="dataLoaded" class="form-fields">

                <div v-if="linkValid" id="link-valid">
                    <div class="row justify-content-center">
                        <div class="col-10">
                            <p class="text-center">Hello and welcome to Qfecard!</p>
                            <p class="welcome-message text-center">Below is your basic profile data. <br>You may make changes to it now or after signing-up.<br>
                                You will also need to provide a password to be able to login later.</p>
                        </div>
                        <div v-if="company" class="col-10 text-center">
                            <img class="company-logo" :src="company.logo" width="70px" height="70px">
                            <h3 class="mt-2">{{company.name}}</h3>
                        </div>
                        <div class="space"></div>
                        <div class="welcome-separator"></div>
                        <div class="space"></div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5">
                            <input-field name="firstName"
                                         id="firstName"
                                         :value="formData.firstName"
                                         v-model="formData.firstName">{{$ml.get('FIRST_NAME')}}
                            </input-field>
                        </div>
                        <div class="col-5">
                            <input-field name="lastName"
                                         id="lastName"
                                         :value="formData.lastName"
                                         v-model="formData.lastName">{{$ml.get('LAST_NAME')}}
                            </input-field>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10">
                            <input-field name="username"
                                         id="username"
                                         :value="formData.username"
                                         v-model="formData.username">{{$ml.get('EMAIL')}}
                            </input-field>
                        </div>
                    </div>

                    <div class="row justify-content-center mb-3">
                        <div class="col-5">
                            <input-field name="password"
                                         id="password"
                                         type="password"
                                         :required="true"
                                         :value="formData.new_pass"
                                         v-model="formData.new_pass">{{$ml.get('PASSWORD')}}
                            </input-field>
                        </div>

                        <div class="col-5">
                            <input-field name="password2"
                                         id="password2"
                                         type="password"
                                         :required="true"
                                         :value="formData.new_pass2"
                                         v-model="formData.new_pass2">{{$ml.get('PASSWORD_CONFIRM')}}
                            </input-field>
                        </div>
                    </div>
                    <div class="row justify-content-center mb-3">
                        <div class="col-4">
                            <button-field color="secondary"
                                          type="submit"
                                          block
                                          :disabled="loading"
                                          icon="sign-in-alt"
                                          :loading="loading">
                                {{$ml.get('buttons.SIGN_IN')}}
                            </button-field>
                        </div>
                    </div>
                </div>


                <div v-else id="link-invalid" class="text-center">
                    <h3 class="error">Invitation link is not valid!</h3>
                    <font-awesome-icon size="2x" icon="unlink"></font-awesome-icon>
                    <div class="space"></div>
                    <div class="welcome-separator"></div>
                    <p class="text-center">It appears that the link that you are using to get onboard is either wrong or is no longer valid.</p>
                    <p class="text-center">This might happen because you had already completed the onboarding process. If this is the case, you may return to the login page using the link below.</p>
                    <div class="space"></div>
                    <div class="welcome-separator"></div>
                    <div class="space"></div>
                    <div class="row align-items-center">
                        <div class="col text-center small">
                            <router-link :to="{name: 'auth'}">{{$ml.get('BACK_TO_LOGIN')}}</router-link>
                        </div>
                    </div>

                </div>

            </div>
            <div v-else class="form-loader">
                <div class="invitation-loading">
                    <font-awesome-icon icon="spinner" spin/> Your invitation is loading
                </div>
            </div>
        </transition>
    </form>
</template>

<script>
    export default {
        name: "AuthenticateLogin",
        data() {
            return {
                loading: false,
                dataLoaded: false,
                company: null,
                linkValid: true,
                formData: {
                    firstName: '',
                    lastName: '',
                    username: '',
                    new_pass: '',
                    new_pass2: null,
                }
            }
        },
        mounted() {
            this.getInitialData();

        },
        methods: {
            getInitialData(){
                let params = {
                    userId : this.$route.params.userId,
                    secret : this.$route.params.secret,
                };

                let self = this;

                this.$store.dispatch('getOnboardData', params).then((userdata) => {
                    self.dataLoaded = true;

                    if(userdata.firstName){
                        self.linkValid = true;
                        self.formData.firstName = userdata.firstName;
                        self.formData.lastName = userdata.lastName;
                        self.formData.username = userdata.username;
                        self.company = userdata.company ? userdata.company : null;

                    }else {
                        self.linkValid = false;
                    }

                }).catch(() => {
                    self.dataLoaded = true;
                    self.linkValid = false;
                });
            },

            onboard() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;

                    this.formData.userId = this.$route.params.userId;
                    this.formData.secret = this.$route.params.secret;

                    this.formData.password = this.formData.new_pass;

                    this.$store.dispatch('onboardUser', this.formData).then(() => {
                        self.$store.dispatch('login', this.formData).then((userdata)=> {
                            self.loading = false;
                            if(userdata){
                                self.$notify({
                                    group: 'notification',
                                    type: 'success',
                                    text: self.$ml.get('notification_login_success')+', '+userdata.attributes.firstName+'!'
                                });
                                userdata.set('lastLoginAt',new Date());
                                userdata.increment('loginCount', 1);
                                userdata.save();


                                this.$store.dispatch('loadUserData').then(() => {
                                    if(userdata.attributes.isAdmin){
                                        self.$router.push({name: 'adminCards'});
                                    }else if(userdata.attributes.company){
                                        self.$router.push({name: 'home'});
                                    } else {
                                        self.$router.push({name: 'client-add'});
                                    }
                                });

                            }else {
                                self.$notify({
                                    group: 'notification',
                                    type: 'error',
                                    text: self.$ml.get('password_required')
                                });
                            }
                        });
                    }).catch(() => {
                        self.loading = false;
                        self.$notify({
                            group: 'notification',
                            type: 'error',
                            text: self.$ml.get('notification_login_error')
                        });
                    });


                }
            }
        }
    }
</script>

<style scoped>

.welcome-separator {
    width: 100%;
    border-top: 1px solid var(--color-secondary);
    padding-top: 5px;
    padding-bottom: 5px;
}

.space {
    width: 100%;
    background: transparent;
    opacity: 0;
    width: 100%;
    height: 10px;
}

.welcome-message {
    font-size: 0.8em;
}

.form-loader, .form-fields {
    min-height: 290px;
}

.invitation-loading {
    width: 100%;
    text-align: center;
    padding-top: 100px;
}
.company-logo{
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #000000;
}
</style>
