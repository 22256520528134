<template>
    <form @submit.prevent="submitForm">
        <h3 class="text-center">
            {{(id===null)?$ml.get('ADD_NEW_CLIENT'):$ml.get('EDIT_CLIENT_COMPANY')}}
        </h3>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-8 text-center">
                <h4 class="mb-1 mt-3" v-html="$ml.get('COMPANY_LOGO_DROPZONE')"></h4>
            </div>
        </div>


        <div class="row">
            <div class="col-3"></div>
            <div class="col-6">
                <div class="dz-profile-pic">
                    <span class="more-info" v-tooltip="$ml.get('tooltip_client_thumbnail')">
                        <font-awesome-icon icon="info-circle"/>
                    </span>
                    <dropzone-field name="profilePicture"
                                    id="profilePicture"
                                    :accept="`image/*`"
                                    v-model="fileTrigger">
                        <img :src="images.logo" v-if="images.logo"/>
                        <template v-else>
                            <div v-html="$ml.get('companyLogoDropzone')"></div>
                        </template>
                    </dropzone-field>
                    <hr/>

                </div>
            </div>
        </div>

        <div class="row">
            <b-col cols="12">
                <b-form-group :label="$ml.get('BILLING_NAME')" >
                    <b-form-input v-model="form.billingName"
                                  :disabled="loading"
                                  :placeholder="$ml.get('BILLING_NAME')">
                    </b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group :label="$ml.get('COMPANY_NAME')" class="required">
                    <b-form-input
                        v-model="form.name"
                        :required="true"
                        @input="validationCollector({name: 'name', valid: !!form.name})"
                        :state="form.name ? true : null"
                        :disabled="loading"
                        :placeholder="$ml.get('COMPANY_NAME')">
                    </b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group :label="$ml.get('VAT_NUMBER')">
                    <b-form-input
                        v-model="form.vatNumber"
                        :disabled="loading"
                        :placeholder="$ml.get('VAT_NUMBER')"></b-form-input>
                </b-form-group>

            </b-col>

            <b-col cols="12">
                <b-form-group :label="$ml.get('BILLING_ADDRESS')">
                    <b-form-input v-model="form.billingAddress"
                                  :required="true"
                                  @input="validationCollector({name: 'billingAddress', valid: !!form.billingAddress})"
                                  :state="form.billingAddress ? true : null"
                                  :disabled="loading"
                                  :placeholder="$ml.get('BILLING_ADDRESS')"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group :label="$ml.get('RESPONSIBLE')">
                    <b-form-input v-model="form.responsible"
                                  :required="false"
                                  @input="validationCollector({name: 'responsible', valid: !!form.responsible})"
                                  :state="form.responsible ? true : null"
                                  :disabled="loading"
                                  :placeholder="$ml.get('RESPONSIBLE')"></b-form-input>

                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group :label="$ml.get('TELEPHONE')">
                    <b-form-input v-model="form.telephone"
                                  :required="false"
                                  @input="validationCollector({name: 'telephone', valid: !!form.telephone})"
                                  :state="form.telephone ? true : null"
                                  :disabled="loading"
                                  :placeholder="$ml.get('TELEPHONE')"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group :label="$ml.get('EMAIL')">
                    <b-form-input
                        v-model="form.billingEmail"
                        :required="true"
                        @input="validationCollector({name: 'billingEmail', valid: emailValidator(form.billingEmail)})"
                        :state="emailValidator(form.billingEmail) ? true : null"
                        :disabled="loading"
                        :placeholder="$ml.get('EMAIL')"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="6">
                <b-form-group :label="$ml.get('WEBSITE')">
                    <b-form-input v-model="form.website"
                                  :required="false"
                                  @input="validationCollector({name: 'website', valid: !!form.website})"
                                  :state="form.website ? true : null"
                                  :disabled="loading"
                                  :placeholder="$ml.get('WEBSITE')"></b-form-input>
                </b-form-group>
            </b-col>

        </div>

        <div v-if="false" class="row pt-4 pb-5 justify-content-center">
            <div class="col-12">
                <h4 class="mb-2 mt-3">{{$ml.get('SELF_ONBOARDING')}}</h4>
            </div>
            <div class="col-12">
                <input-field :id="`office-state`"
                             :name="'office-state'"
                             :disabled="true"
                             :value="onboardingLink"
                             v-model="onboardingLink">iFrame URL for self-onboarding of employees
                </input-field>
            </div>
            <div class="col-6">
                <button-field color="primary"
                              :icon="`link`"
                              :disabled="resetLoading"
                              @click="copyFrameOnboardLink"
                              block>{{$ml.get('COPY_LINK')}}
                </button-field>
            </div>
            <div class="col-6">
                <button-field color="dark"
                              :icon="`redo`"
                              :loading="resetLoading"
                              :disabled="resetLoading"
                              @click="resetLink"
                              block>{{$ml.get('RESET_ONBOARDING_LINK')}}
                </button-field>
            </div>
        </div>


        <div class="row pt-5 justify-content-center">
            <div class="col-6">
                <button-field color="secondary"
                              :icon="(id===null)?'plus':'save'"
                              type="submit"
                              :disabled="isFormDisabled"
                              block>{{(id===null)?$ml.get('ADD'):$ml.get('SAVE')}}
                </button-field>
            </div>
        </div>


    </form>
</template>

<script>
    import moment from "moment";

    export default {
        name: "DashboardClientsForm",
        computed: {
            source(){
                let theSource = this.$store.state.dashboardModalComponent.data.dataSet ? this.$store.state.dashboardModalComponent.data.dataSet : 'agencyClients';
                return theSource;
            },
            agencyId(){
                return this.$store.state.agencyId;
            },
            isAdmin() {
              return this.$store.state.user.attributes.isAdmin;
            },
            minDate() {
                return moment().format('Y-MM-DD');
            },
            id() {
                return this.$store.state.dashboardModalComponent.data.id
            },
            selectedClient() {
                return this.$store.getters.selectedModalData(this.source);
            },
            onboardingLink(){
                let invitationLink = '';
                let client = this.selectedClient;
                if(this.id && client){
                    invitationLink = window.location.protocol + '//' + window.location.host
                        +'/addme/'+this.id+'/'+client.attributes.frameSecret;
                }
                return invitationLink
            },
            availableTemplates() {
                let templates = [];
                if(this.$store.state.cardTemplates){
                    this.$store.state.cardTemplates.forEach((template) => {
                        let element = {
                            value: template.id,
                            text: template.attributes.name
                        };
                        templates.push(element);
                    })
                }
                return templates;
            },
            emailValidator(){
                return function(value) {
                    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(String(value).toLowerCase());
                }
            },
            vatValidator() {
                return function(value) {
                    let re = /(AT?U[0-9]{8}$|BE?0[0-9]{9}$|BG?[0-9]{9,10}$|HR?[0-9]{11}$|CY?[0-9]{8}[A-Z]$|CZ?[0-9]{8,10}$|DE?[0-9]{9}$|DK?[0-9]{8}$|EE?[0-9]{9}$|EL?[0-9]{9}$|ES[A-Z][0-9]{7}(?:[0-9]|[A-Z])$|FI?[0-9]{8}$|FR?[0-9A-Z]{2}[0-9]{9}$|GB?([0-9]{9}([0-9]{3}?|[A-Z]{2}[0-9]{3}))$|HU?[0-9]{8}$|IE?[0-9]{7}[A-Z]{1,2}$|IE?[0-9][A-Z][0-9]{5}[A-Z]$|IT?[0-9]{11}$|LT?([0-9]{9}|[0-9]{12})$|LU?[0-9]{8}$|LV?[0-9]{11}$|MT?[0-9]{8}$|NL?[0-9]{9}B[0-9]{2}$|PL?[0-9]{10}$|PT?[0-9]{9}$|RO?[0-9]{2,10}$|SE?[0-9]{12}$|SI?[0-9]{8}$|SK?[0-9]{10})$/;
                    let result = re.test(String(value));
                    return result;
                };
            },
            zipCodeValidator() {
              return function (value) {
                  let re = /[\w]*[\w-]*[\w ]*\d{4,}/;
                  return re.test(value);
              }
            },
            isFormDisabled(){
                return this.errors.length > 0;
            },
            isCalendlyDisabled(){
                return this.calendlyErrors.length > 0;
            }

        },
        data() {
            let dataSet = this.$store.state.dashboardModalComponent.data.dataSet ? this.$store.state.dashboardModalComponent.data.dataSet : 'agencyClients';

            let initialAttr = this.$store.getters.selectedModalData(dataSet);

            return {
                fileTrigger: {},
                images: {
                    logo: (initialAttr && initialAttr.attributes.logo) ? initialAttr.attributes.logo._url : ''
                },
                imgFiles: {
                    logo: null
                },
                form: {
                    name: initialAttr ? initialAttr.attributes.name : '',
                    vatNumber:  initialAttr ? initialAttr.attributes.vatNumber : '',
                    billingAddress:  initialAttr ? initialAttr.attributes.billingAddress : '',
                    billingName: initialAttr ? initialAttr.attributes.billingName : '',
                    responsible:  initialAttr ? initialAttr.attributes.responsible : '',
                    billingEmail: initialAttr ? initialAttr.attributes.billingEmail : '',
                    telephone: initialAttr ? initialAttr.attributes.telephone : '',
                    website: initialAttr ? initialAttr.attributes.website : '',
                    //billingName: initialAttr ? initialAttr.attributes.billingName : '',
                    //cardTemplate: initialAttr ? initialAttr.attributes.billingName : '',
                    logo: initialAttr && initialAttr.attributes.logo ? initialAttr.attributes.logo._url: null,
                    isAgency: initialAttr ? initialAttr.attributes.isAgency: false,
                    canUpgrade: initialAttr ? initialAttr.attributes.canUpgrade: false,
                    hasTrial: false,
                    trialUntil: initialAttr ? initialAttr.attributes.trialUntil : null,
                    officeAddress: {
                        street: '',
                        zip: '',
                        city: '',
                        state: '',
                        country: '',
                        compId: null
                    }
                },
                calendlySentOn: initialAttr && initialAttr.attributes.calendlySentOn ? initialAttr.attributes.calendlySentOn : null,
                calendlyEmail: initialAttr ? initialAttr.attributes.billingEmail : '',
                loading: false,
                resetLoading: false,
                loadingEmail: false,
                errors: [],
                calendlyErrors: [],
                infoBox1Visible: false,
                iconTooltip: '<div class="custom-stuff">' +
                                '<h4>This will be the icon that shows <br>on the device of each employee.</h4>' +
                                '<p>Use a 512 x 512 PNG image</p>' +
                             '</div>',
                logoTooltip: '<div class="custom-stuff">' +
                                '<h4>The logo that will appear on the <br> business card design.</h4>' +
                                '<p>Use a 512 x 512 PNG or an SVG image</p>' +
                             '</div>',
            }
        },
        mounted() {
            // if (this.selectedClient !== null) {
            //     this.form = {
            //         ...this.form,
            //         ...this.selectedClient.attributes
            //     }
            // }
            if(this.form.trialUntil) {
                this.form.hasTrial = true;
                this.form.trialUntil = moment(this.form.trialUntil).format('Y-MM-DD');
            }

        },
        methods: {
            niceDate(date){
                return moment(date).format('DD/MM/Y');
            },
            resetLink(){
                this.resetLoading = true;
                this.$store.dispatch('regenerateFrameLink', this.id).then(()=>{
                    this.resetLoading = false;
                });
            },
            copyFrameOnboardLink(){

                navigator.clipboard.writeText(this.onboardingLink);

                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('notification_onboard_link_copied')
                });

            },
            showMoreInfo(){
                this.infoBox1Visible=true;
            },
            sendCalendlyEmail(){
                let self = this;
                self.loadingEmail = true;
                this.$store.dispatch('sendCalendlyEmail', {
                    id: this.id,
                    calendlyEmail: this.calendlyEmail}).then((resp) => {
                    self.calendlySentOn = this.niceDate(resp.data.calendlySentOn.date);
                    self.$notify({
                        group: 'notification',
                        type: 'success',
                        text: self.$ml.get('notification_success_calendly'),
                    });
                    self.loadingEmail = false;
                    self.$store.commit('toggleDashboardModal');
                }).catch(() => {
                    self.$notify({
                        group: 'notification',
                        type: 'error',
                        text: self.$ml.get('notification_error_calendly'),
                    });
                    self.loadingEmail = false;
                });
            },
            validationCollector(inputValidation){
                Array.prototype.remove = function() {
                    var what, a = arguments, L = a.length, ax;
                    while (L && this.length) {
                        what = a[--L];
                        while ((ax = this.indexOf(what)) !== -1) {
                            this.splice(ax, 1);
                        }
                    }
                    return this;
                };

                if(inputValidation.valid){
                    this.errors.remove(inputValidation.name);
                }else {
                    if(this.errors.indexOf(inputValidation.name) === -1){
                        this.errors.push(inputValidation.name);
                    }
                }
            },

            validationCalendly(inputValidation){
                Array.prototype.remove = function() {
                    var what, a = arguments, L = a.length, ax;
                    while (L && this.length) {
                        what = a[--L];
                        while ((ax = this.indexOf(what)) !== -1) {
                            this.splice(ax, 1);
                        }
                    }
                    return this;
                };

                if(inputValidation.valid){
                    this.calendlyErrors.remove(inputValidation.name);
                }else {
                    if(this.calendlyErrors.indexOf(inputValidation.name) === -1){
                        this.calendlyErrors.push(inputValidation.name);
                    }
                }
            },

            submitForm() {
                let self = this;
                //this.validateFields(this.form, this.errors);
                if (!self.loading) {
                    self.loading = true;

                    self.$store.dispatch('saveClient', {id: self.id, ...self.form, dataSet: this.source}).then(() => {
                        //Use Company data to pre-fill default address

                            if(this.source == 'agencyClients'){
                                self.$store.dispatch('getClientsOfAgency', {agencyId: this.agencyId});
                            }else {
                                self.$store.dispatch('getAllAgencies');
                            }

                            self.$notify({
                                group: 'notification',
                                type: 'success',
                                text: self.$ml.get('notification_success_saved'),
                            });

                            self.$store.commit('toggleDashboardModal')
                            self.loading = false;
                    });
                }
            }
        },
        watch: {
            fileTrigger: {
                handler(newVal) {
                    if(newVal){
                        this.imgFiles[newVal.field] = newVal.file;
                        this.images[newVal.field] = newVal.url;
                        this.form.logo = newVal;
                    }
                },
                deep: true,
            },
        }
    }
</script>

<style scoped>
    .more-info {
        position: absolute;
        right: 18px;
        top: 3px;
        z-index: 100;
    }
    .form-label {
        position: relative;
        width: 100%;
        color: var(--color-dark);
        /* transition: all 0.25s ease-in-out; */
        text-transform: uppercase;
        font-size: 10px;
        cursor: text;
        pointer-events: none;
    }
</style>
