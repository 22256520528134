<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="table-top">
                <div class="table-top-buttons">
                    <button-field @click="addressForm" icon="plus" color="primary">{{$ml.get('ADD_ADDRESS')}}</button-field>
                </div>
            </div>
            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <b-table
                        :fields="tableColumns"
                        :items="companyAddresses"
                        :empty-html="emptyHtml"
                        class="font-small-2 table-vertical-align-middle table-list"
                    >
                        <template #cell(attributes.country)="data">
                            <div>{{data.item.attributes.street}}, {{data.item.attributes.zip}} {{data.item.attributes.city}}, <span v-if="data.item.attributes.state">{{data.item.attributes.state}},</span> {{data.item.attributes.country}}</div>
                        </template>

                        <template #cell(actions)="data">
                            <div class="table-action-btn-gorup">
                                <button-field @click="addressForm(data.item.id)"
                                              color="primary"
                                              circle
                                              outline
                                              v-tooltip="$ml.get('EDIT_ADDRESS')"
                                              icon="edit"
                                              class="table-action-btn"></button-field>
                                <button-field @click="deleteAddress(data.item.id)"
                                              color="error"
                                              circle
                                              outline
                                              v-tooltip="$ml.get('DELETE_ADDRESS')"
                                              icon="trash-alt"
                                              class="table-action-btn bg-red"></button-field>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: "DashboardCompanyCoordinates",
        computed: {
            companyAddresses() {
                return this.$store.state.officeAddresses;
            }
        },
        data() {
            return {
                tableRows: [],
                tableColumns: [
                    { key: 'attributes.officeLabel', label: this.$ml.get('ADDRESS_LABEL'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    { key: 'attributes.officePhone', label: this.$ml.get('OFFICE_PHONE'),  thClass: 'font-bebas-neue font-small-4', sortable: false},
                    { key: 'attributes.website', label: this.$ml.get('WEBSITE'),  thClass: 'font-bebas-neue font-small-4', sortable: false},
                    { key: 'attributes.country', label: this.$ml.get('ADDRESS'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    {
                        key: 'actions',
                        label: this.$ml.get('ACTIONS'),
                        thClass: 'font-bebas-neue text-center font-small-4',
                        tdClass: 'text-right',
                        stickyColumn: true,
                        sortable: false
                    }
                ],
                emptyHtml: '<div class="text-center"><font-awesome-icon icon="user" size="3x" color="gray" /><p class="mt-2">No data found</p></div>',
            }
        },
        mounted() {
            this.getCompanyAddresses();
            this.$store.commit('closeRightModal');
        },
        methods: {
            getCompanyAddresses() {
                let self = this;
                this.$store.dispatch('getCompany').then(() => {
                    this.$store.dispatch('getOfficeAddresses').then(() => {
                    }).catch(function(e){
                        return Promise.reject(e);
                    });
                }).catch(function(e2){
                    self.$checkTokenExp(e2);
                });
            },
            toggleModal() {
                this.$store.commit('toggleDashboardModal')
            },
            addressForm(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardCompanyCoordinatesForm', data: {id: id}})
            },
            deleteAddress(id) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardCompanyCoordinatesDelete', data: {id: id}})
            },
            generateRows(rows) {
                let allItems = Array()
                let one_row = {
                    address_label: 'Sofia Office',
                }
                let i = 0
                while (i < rows) {
                    allItems[i] = one_row
                    i++
                }
                return allItems
            }
        }
    }
</script>

<style scoped>

</style>
