<template>
    <div class="global-loader">
        <div class="text-primary"><font-awesome-icon icon="spinner" spin/> {{dynamicMessage}}</div>
    </div>
</template>

<script>
    export default {
        name: "GlobalLoader",
        computed: {
            dynamicMessage() {
                return this.$store.state.globalLoaderMessage ? this.$store.state.globalLoaderMessage : this.$ml.get('Loading');
            }
        }
    }
</script>

<style scoped>
    .global-loader {
        position: fixed;
        background: rgba(0,0,0,0.8);
        top: 40%;
        bottom: 40%;
        left: 0;
        right: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        z-index: 9999;

    }
</style>
