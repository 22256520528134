<template>
    <div>
        <form @submit.prevent="submitForm" id="agency-settings-form">
            <b-row>
                <b-col cols="12">
                    <h3 class="text-center">
                        {{$ml.get('ADMIN_SETTINGS')}}
                    </h3>
                    <div class="text-center">
                        <b-img :src="compLogo" thumbnail class="my-2 mx-auto" width="150"/>
                        <h4 class="mb-0 mt-1">{{compName}}</h4>
                    </div>
                </b-col>
            </b-row>
            <!-- CLIENT OF -->
            <b-row class="pt-3">
                <b-col cols="12">
                    <b-form-group :label="$ml.get('client_of')" class="bootstrapped-v-select">
                        <Select2
                            v-model="clientOf.id"
                            :options="companies"
                            :placeholder="$ml.get('COMPANY')"
                            :disabled="isAgency"
                            :settings="{
                            templateResult: companyPreview,
                            templateSelection: selectionPreview,
                            escapeMarkup: function(stuff){return stuff}
                        }"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <div class="row">
                <div class="col-6">
                    <div class="row pt-2" v-if="isAdmin">
                        <div class="col-12 mb-2">
                            <checkbox-field color="secondary"
                                            :value="form.canUpgrade"
                                            v-model="form.canUpgrade"
                                            :disabled="form.isAgency"
                            >{{$ml.get('this_client_can_upgrade')}}
                            </checkbox-field>
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <div class="row pt-2" v-if="isAdmin">
                        <div class="col-12 mb-2">
                            <checkbox-field color="secondary"
                                            :value="isAgency"
                                            v-model="isAgency"
                            >{{$ml.get('this_client_is_an_agency')}}
                            </checkbox-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="isAdmin">
                <div class="col-6">
                    <div class="row pt-3">
                        <div class="col-12 mb-2">
                            <checkbox-field color="secondary"
                                            :value="form.hasTrial"
                                            v-model="form.hasTrial"
                            >{{$ml.get('this_is_trial_account')}}
                            </checkbox-field>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row pt-1" v-if="form.hasTrial">
                        <div class="col-12 mb-2">
                            <b-form-group :label="$ml.get('trial_until')">
                                <b-form-datepicker
                                    id="issue-date"
                                    locale="en"
                                    :start-weekday="1"
                                    :hide-header="true"
                                    v-model="form.trialUntil"
                                    :disabled="!form.hasTrial"
                                    :date-format-options="{
                                        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        weekday: 'short'
                                    }"
                                    ></b-form-datepicker>

                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6" v-if="form.isAgency">
                    <div class="dz-profile-pic">
                        <span class="more-info" v-tooltip="$ml.get('tooltip_agency_panel_logo')">
                            <font-awesome-icon icon="info-circle"/>
                        </span>
                        <dropzone-field name="profilePicture"
                                        id="profilePicture"
                                        :accept="`image/*`"
                                        :required="true"
                                        @validation-result="validationCollector"
                                        v-model="fileTrigger">
                            <img :src="images.logo" v-if="images.logo"/>
                            <template v-else>
                                <div v-html="$ml.get('AGENCY_LOGO_DROPZONE')"></div>
                            </template>
                        </dropzone-field>
                    </div>
                </div>

                <div class="col-6" v-if="form.isAgency">
                    <div class="row pt-2" v-if="isAdmin">
                        <div class="col-12 mb-2">
                            <checkbox-field color="secondary"
                                            :value="form.verified"
                                            v-model="form.verified"
                            >{{$ml.get('Verified')}}
                            </checkbox-field>
                            <div v-if="form.verified && form.verifiedOn" class="trial-subscript text-secondary">
                                <span>{{$ml.get(`Verified_on`)}}: {{form.verifiedOn | dateFormat}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="isAdmin">
                        <div class="col-12 mb-2">
                            <checkbox-field color="secondary"
                                            :value="form.lightLogoBg"
                                            v-model="form.lightLogoBg"
                            >{{$ml.get('agency_use_light_logo_bg')}}
                            </checkbox-field>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <hr/>
                </div>
            </div>

            <!-- SAVE -->
            <div class="row pt-2 justify-content-center">
                <div class="col-6">
                    <button-field color="secondary"
                                  :icon="'save'"
                                  type="submit"
                                  block>{{$ml.get('SAVE')}}
                    </button-field>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Select2 from "v-select2-component";

export default {
    name: "DashboardAgencySettingsForm",
    components: {
        Select2
    },
    data() {
        let dataSet = this.$store.state.dashboardModalComponent.data.dataSet ? this.$store.state.dashboardModalComponent.data.dataSet : 'masterAgencies';

        let initialAttr = this.$store.getters.selectedModalData(dataSet);

        return {
            fileTrigger: {},
            images: {
                logo: initialAttr && initialAttr.attributes.agencyPanelLogo ? initialAttr.attributes.agencyPanelLogo._url : ''
            },
            imgFiles: {
                logo: null
            },
            clientOf: initialAttr ? initialAttr.attributes.clientOf : {},
            compName: initialAttr ? initialAttr.attributes.name : '',
            compLogo: initialAttr && initialAttr.attributes.logo ? initialAttr.attributes.logo._url : '',
            isAgency: initialAttr ? initialAttr.attributes.isAgency: false,

            form: {
                logo: initialAttr && initialAttr.attributes.agencyPanelLogo ? initialAttr.attributes.agencyPanelLogo : {},
                clientOf: initialAttr ? initialAttr.attributes.clientOf : {},
                name: initialAttr ? initialAttr.attributes.name : '',
                isAgency: initialAttr ? initialAttr.attributes.isAgency: false,
                lightLogoBg: initialAttr ? initialAttr.attributes.lightLogoBg: false,
                hasTrial: initialAttr.attributes.trialUntil ? true : false,
                canUpgrade: initialAttr ? initialAttr.attributes.canUpgrade : false,
                verified: initialAttr ? initialAttr.attributes.verified : false,
                verifiedOn: initialAttr && initialAttr.attributes.verifiedOn ? initialAttr.attributes.verifiedOn : null,
                trialUntil: initialAttr ? initialAttr.attributes.trialUntil : null,
            },
            loading: false,
            errors: [],
            defaultAgency: {},
        }
    },
    computed: {
        source(){
            let theSource = this.$store.state.dashboardModalComponent.data.dataSet ? this.$store.state.dashboardModalComponent.data.dataSet : 'agencyClients';
            return theSource;
        },
        isAdmin() {
            return this.$store.state.user.attributes.isAdmin;
        },
        id() {
            return this.$store.state.dashboardModalComponent.data.id;
        },
        companies() {
            let self = this;
            let companies = [{
                id: 0,
                text: "None",
                fullData: {
                    name: "None",
                    logo: {
                        _url: require('../../assets/noun-empty.svg')
                    }
                }
            }];
            if(this.$store.state.masterAgencies.length > 0){
                let clientList = [...this.$store.state.masterAgencies];
                clientList.push(this.$store.state.userCompany);
                this.$store.state.masterAgencies.forEach((comp) => {
                    if(comp.attributes.isAgency){
                        if(comp.attributes.name == 'qfecard Master') {
                            self.defaultAgency = comp;
                        }
                        let element = {
                            id: comp.id,
                            text: comp.attributes.name,
                            fullData: comp.attributes
                        };
                        companies.push(element);
                    }
                })
                if(this.$store.state.userCompany.attributes.core){
                    let element = {
                        id: this.$store.state.userCompany.id,
                        text: this.$store.state.userCompany.attributes.name,
                        fullData: this.$store.state.userCompany.attributes
                    };
                    companies.push(element);
                }
            }
            return companies;
        },
    },
    created(){
        this.$store.dispatch('getMasterAgencies');
    },
    methods: {
        companyPreview(choice){
            let markup = '';
            if(choice.fullData){
                let item = choice.fullData;

                if(item.suspended){
                    markup = `<div class="company suspended">`;
                }else {
                    markup = `<div class="company">`;
                }
                markup += `<span class="profile-avatar">`;
                if(item.logo){
                    markup += `<img class="comp-logo" src="`+item.logo._url+`" />`;
                }else {
                    markup += `<img class="comp-logo" src="`+require('../../assets/noun-empty.svg')+`" />`;
                }

                markup += `</span>`;
                markup += `<h3>`+item.name+`</h3>`;
                markup +=`</div>`;
            }
            return markup;
        },
        selectionPreview(selection){
            let markup = '';
            if(selection.fullData){
                let item = selection.fullData;
                markup = `<div class="company selection">`;
                markup += `<span class="profile-avatar">`;
                markup += `<img class="comp-logo" src="`+item.logo._url+`" />`;
                markup += `</span>`;
                markup += `<h3>`+item.name+`</h3>`;
                markup +=`</div>`;
            }
            return markup;
        },
        validationCollector(inputValidation){
            Array.prototype.remove = function() {
                var what, a = arguments, L = a.length, ax;
                while (L && this.length) {
                    what = a[--L];
                    while ((ax = this.indexOf(what)) !== -1) {
                        this.splice(ax, 1);
                    }
                }
                return this;
            };

            if(inputValidation.valid){
                this.errors.remove(inputValidation.name);
            }else {
                if(this.errors.indexOf(inputValidation.name) === -1){
                    this.errors.push(inputValidation.name);
                }
            }
        },
        submitForm() {
            let self = this;
            if (!self.loading) {
                self.loading = true;

                self.$store.dispatch('saveAdminClient', {id: self.id, ...self.form, dataSet: this.source}).then(() => {

                    self.$notify({
                        group: 'notification',
                        type: 'success',
                        text: self.$ml.get('notification_success_saved'),
                    });
                    self.$store.commit('toggleDashboardModal');

                });
            }
        }
    },
    watch: {
        isAgency: {
            handler(newVal) {
                this.form.isAgency = newVal;
                if(newVal){
                    this.clientOf = this.$store.state.userCompany;
                    this.form.clientOf = this.$store.state.userCompany;
                    this.form.canUpgrade = true;
                }else {
                    this.form.canUpgrade = true

                    this.form.verified = false;
                    this.form.verifiedOn = null;
                    this.clientOf = this.defaultAgency;
                    this.form.clientOf = this.defaultAgency;
                }
            },
            deep: true,
        },
        clientOf: {
            handler(newVal) {
                this.form.clientOf = newVal;
            },
            deep: true,
        },
        fileTrigger: {
            handler(newVal) {
                if(newVal){
                    this.imgFiles[newVal.field] = newVal.file;
                    this.images[newVal.field] = newVal.url;
                    this.form.logo = newVal;
                }
            },
            deep: true,
        },
    }
}
</script>

<style scoped>
.select2-container{
    width: 100% !important;
    padding: 20px 0;
}
.selection {
    padding-top: 5px;
}
.select2-container--default .select2-selection--single{
    min-height: 50px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 36px;
}
.more-info {
    position: absolute;
    right: 18px;
    top: 3px;
    z-index: 100;
}
.form-label {
    position: relative;
    width: 100%;
    color: var(--color-dark);
    text-transform: uppercase;
    font-size: 10px;
    cursor: text;
    pointer-events: none;
}
</style>
