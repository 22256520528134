<template>
    <div>
        <h3 class="text-center">
            {{$ml.get('DELETE_INVOICE')}}
        </h3>
        <div class="font-bebas-neue">
            {{$ml.get('SELECTED_INVOICE')}}
        </div>
        <ul class="bulk-list delete">
          <li v-if="selectedInvoice">
            <span>{{$ml.get('INVOICE_N')}}: {{selectedInvoice.attributes.number}}</span>
          </li>
        </ul>
        <div class="pt-4">
            <checkbox-field color="secondary"
                            name="confirm"
                            id="confirm"
                            :value="confirm"
                            v-model="confirm">
                {{$ml.get('delete_invoice_confirm_checkbox')}}
            </checkbox-field>
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="error"
                              icon="trash-alt"
                              :loading="loading"
                              :disabled="loading || !confirm"
                              block
                              @click="deleteInvoice">{{$ml.get('DELETE')}}
                </button-field>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardUserDelete",
        computed: {
            selectedInvoice() {
                return this.$store.getters.selectedModalData('selectedInvoices');
            },
            cardCompanyId() {
                if(this.$store.state.cardCompany){
                    return this.$store.state.cardCompany.id;
                }else{
                    return null;
                }
            },
        },
        data() {
            return {
                confirm: false,
                loading: false,
            }
        },
        methods: {
            deleteInvoice() {
                let self = this;
                let invoiceId = this.selectedInvoice.id;

                this.$store.dispatch('deleteInvoice', invoiceId).then(() => {

                    this.$store.dispatch('getClientInvoices', {id: this.cardCompanyId, year: new Date().getFullYear()}).then(() => {
                        this.$store.commit('toggleDashboardModal');
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_saved'),
                        });
                    }).catch(function(e){
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e)
                    });

                }).catch(function(e){
                    self.$checkTokenExp(e)
                });
            },
        }
    }
</script>

<style scoped>
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
        padding-top: 8px;
        padding-bottom: 2px;
        border-bottom: 1px solid var(--bg-grey);
    }
    ul.bulk-list li > span{
      justify-content: flex-start;
      padding-left: 20px;
      padding-right: 20px;
    }
    ul.bulk-list li{
      min-width: 100%;
    }
</style>
