<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="eco-content-scroll relative">
                <trial-wizard
                    :agencyId="agencyId"
                ></trial-wizard>
            </div>
        </div>
    </div>
</template>

<script>
    // import ClientWizard from "@/components/wizzard/clientWizard";
    import TrialWizard from "@/components/public/wizzard/addClientWizard.vue";
    export default {
        name: "addClient",
        components: {
            // ClientWizard,
            TrialWizard
        },
        data() {
            return {
                sharing: false,
                wizzardOpened: true,
                agencyId: this.$route.params.agencyId ? this.$route.params.agencyId : '',
            }
        },
        mounted() {
            // this.$store.dispatch('getCompany').then(() => {
            //     this.$store.dispatch('getCardTemplates');
            //     this.$store.dispatch('getAgencyClients').then(() => {
            //     });
            // });
            this.$store.commit('closeRightModal');
        },
    }
</script>

<style scoped>
    .per-page-select {
        width: 110px;
    }
    .hasCursor {
        cursor: pointer;
    }
</style>
