<template>
    <div class="form-component" :class="assignClasses">
        <input class="form-field"
               type="date"
               :name="name"
               :required="required"
               :id="id"
               :value="formattedValue"
               :disabled="disabled"
               :autocomplete="autocomplete"
               @input="updateValue($event.target.value)">
        <label class="form-label" :for="id">
            <slot></slot>
            <span v-if="required" class="asterix">*</span>
        </label>
        <span class="form-error font-bebas-neue">{{ error }}</span>
    </div>
</template>

<script>
export default {
    name: "DateField",
    props: {
        name: String,
        autocomplete: {
            type: String,
            default: 'on'
        },
        error: {
            type: String,
            default: ''
        },
        id: String,
        value: {
            type: [String,Date],
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        formattedValue() {
            // Assuming the value is always in YYYY-MM-DD format or a Date object
            if (this.value) {
                if (this.value instanceof Date) {
                    return this.value.toISOString().substring(0, 10);
                }
                return this.value; // It should already be in the correct format (YYYY-MM-DD)
            }
            return '';
        },
        assignClasses() {
            let resultClasses = '';
            if (this.value) {
                resultClasses += 'form-has-value ';
            }
            if (this.error) {
                resultClasses += 'color-error ';
            }
            return resultClasses.trim();
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value);
            this.$emit('update-date', value);
        }
    }
}
</script>

<style>

    .asterix {
        color: var(--color-error);
    }

    .form-component {
        min-height: 50px;
        position: relative;
        padding-top: 14px;

        /*border: 1px solid red;*/
    }

    .form-field {
        width: 100%;
        border: 0px solid var(--color-grey);
        border-bottom-width: 1px;
        background: transparent;
        font-size: 14px;
        color: var(--color-dark);
        /* transition: all 0.25s ease-in-out; */
    }

    .form-field:focus {
        box-shadow: none;
        outline: none;
        color: var(--color-dark);
        border-bottom-color: var(--color-dark);
    }

    .form-label {
        position: absolute;
        top: 15px;
        left: 0;
        width: 100%;
        color: var(--color-dark);
        /* transition: all 0.25s ease-in-out; */
        text-transform: uppercase;
        font-size: 12px;
        cursor: text;
        pointer-events: none;
    }

    .color-error > .form-label,
    .form-has-value > .form-label {
        top: 0;
        font-size: 10px;
    }

    .form-field:focus ~ .form-label {
        top: 0;
        font-size: 10px;
    }

    .form-error {
        opacity: 0;
        font-size: 12px;
        text-transform: uppercase;
        color: var(--color-error);
        /* transition: all 0.25s ease-in-out; */
    }

    .color-error > .form-field:focus ~ .form-label,
    .color-error > .form-label,
    .color-error > .form-field,
    .color-error > .form-error {
        color: var(--color-error);
    }

    .color-error > .form-field {
        border-bottom-color: var(--color-error);
    }

    .color-error > .form-error {
        opacity: 1;
    }

    .color-error > .form-field:focus ~ .form-error,
    .color-error > .form-field:focus ~ .form-label {
        color: var(--color-error-dark);
    }

    .color-error > .form-field:focus {
        color: var(--color-error-dark);
        border-bottom-color: var(--color-error-dark);
    }

    .color-primary > .form-field:focus ~ .form-label,
    .color-primary > .form-label,
    .color-primary > .form-field,
    .color-primary > .form-error {
        color: var(--color-primary);
    }

    .color-primary > .form-field {
        border-bottom-color: var(--color-primary);
    }

    .color-primary > .form-field:focus ~ .form-label {
        color: var(--color-primary-dark);
    }

    .color-primary > .form-field:focus {
        color: var(--color-primary-dark);
        border-bottom-color: var(--color-primary-dark);
    }

    .color-secondary > .form-field:focus ~ .form-label,
    .color-secondary > .form-label,
    .color-secondary > .form-field,
    .color-secondary > .form-error {
        color: var(--color-secondary);
    }

    .color-secondary > .form-field {
        border-bottom-color: var(--color-secondary);
    }

    .color-secondary > .form-field:focus ~ .form-label {
        color: var(--color-secondary-dark);
    }

    .color-secondary > .form-field:focus {
        color: var(--color-secondary-dark);
        border-bottom-color: var(--color-secondary-dark);
    }

</style>
