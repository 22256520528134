import Parse from 'parse';

export const NotesService = {
    getClientNotes(context, payload) {
        let notesQuery = new Parse.Query('Note');
        let companyPointer = {"__type": "Pointer", "className": "Company", "objectId": payload.companyId};
        notesQuery.equalTo('relatedCompany', companyPointer);
        notesQuery.descending('date');
        notesQuery.include('author');

        return new Promise((resolve, reject) => {
            notesQuery
                .find()
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    saveNote(context, payload) {
        let Note = Parse.Object.extend('Note');
        let note = new Note();
        if(payload.id) {
            note.set('objectId', payload.id);
        }
        let companyPointer = {"__type": "Pointer", "className": "Company", "objectId": payload.companyId};
        let userPointer = {"__type": "Pointer", "className": "_User", "objectId": payload.author};
        note.set('relatedCompany', companyPointer);
        note.set('author', userPointer);
        note.set('date', payload.date);
        note.set('content', payload.content);

        if(payload.attachment){
            let file = new Parse.File(payload.attachment.name, payload.attachment);
            note.set('attachment', file);
        }
        note.set('channel', payload.channel);
        return new Promise((resolve, reject) => {
            note
                .save()
                .then(response => {
                    //Update latest note in company if new note:
                    if(!payload.id) {
                        let company = Parse.Object.extend('Company');
                        let companyObj = new company();
                        companyObj.set('objectId', payload.companyId);
                        companyObj.set('latestNote', response);
                        companyObj.save();
                    }
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    deleteNote(context, note) {
        return new Promise((resolve, reject) => {
            note
                .destroy()
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
};
