<template>
    <div class="eco-sidebar admin-sidebar">
        <div class="eco-sidebar-wrapper">
            <ul class="eco-sidebar-nav font-bebas-neue">
                <li v-if="isCore">
                    <router-link :to="{name: 'admin-dashboard'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon :icon="['fas', 'chart-line']" /></span> {{$ml.get('DASHBOARD')}} (BETA)
                    </router-link>
                </li>
                <li v-if="isCore">
                    <router-link :to="{name: 'agencies'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon :icon="['fas', 'drafting-compass']" /></span> {{$ml.get('AGENCIES')}}
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{name: 'clients'}" :class="$route.name === 'clients' ? 'router-link-exact-active active': ''">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="briefcase"/></span> {{$ml.get('CLIENTS')}}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'users'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon icon="user-tag"/></span> {{$ml.get('USERS')}}
                    </router-link>
                </li>
                <li v-if="isCore">
                    <router-link :to="{name: 'admin-promos'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon :icon="['fas', 'star']" /></span> {{$ml.get('PROMOS')}}
                    </router-link>
                </li>
                <li v-if="isCore">
                    <router-link :to="{name: 'admin-billing'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon :icon="['fas', 'dollar-sign']" /></span> {{$ml.get('BILLING')}} (BETA)
                    </router-link>
                </li>
                <li v-if="isCore">
                    <router-link :to="{name: 'admin-documents'}">
                        <span class="eco-sidebar-nav-icon"><font-awesome-icon :icon="['fas', 'file-download']" /></span> {{$ml.get('PROTECTED_DOCS')}}
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="goToClients">
            <button-field icon="arrow-left" color="dark"
                          @click="navTo('home')"
            >{{$ml.get('BACK')}}</button-field>
        </div>

        <div class="version">v.{{version}}</div>

    </div>
</template>

<script>
    import Parse from 'parse';
    export default {
        name: "DashboardLayoutNav",
        data(){
          return {
              ClientPopupActive: false,
              userCompany: this.$store.state.userCompany,
              mode: process.env.VUE_APP_MODE,
              isAdmin: false,
          }
        },
        mounted() {
            let curUser = Parse.User.current();
            if(curUser && curUser.attributes){
                this.isAdmin = curUser.attributes.isAdmin;
            }
        },
        computed: {
            isInWizzard(){
              if(this.$route.name == 'client-add'){
                  return true;
              }else {
                  return false;
              }
            },
            isInClients(){
                if(this.$route.name == 'clients'){
                    return true;
                }else {
                    return false;
                }
            },
            isCore(){
                if(this.$store.state.userCompany && this.$store.state.userCompany.attributes){
                    return this.$store.state.userCompany.attributes.core;
                }else {
                    return false;
                }
            },
            version () {
                return process.env.VUE_APP_VERSION;
            }
        },
        methods: {
            navTo(destination) {
                if(destination == 'home' && this.isAdmin) {
                    this.$router.push({name: 'adminCards'});
                }else {
                    this.$router.push({name: destination});
                }

            }
        }
    }
</script>

<style scoped>

/* ORANGE NAV FOR ADMIN PANEL */

.admin-sidebar {
    background: var(--color-primary-dark);
}

.admin-sidebar .eco-sidebar-nav li a.router-link-exact-active {
    -webkit-box-shadow: 0 2px var(--color-dark), 0 -2px var(--color-dark);
    box-shadow: 0 2px var(--color-dark), 0 -2px var(--color-dark);
    color: var(--color-dark);
}

.admin-sidebar .eco-sidebar-nav a.router-link-exact-active .eco-sidebar-nav-icon,
.admin-sidebar .eco-sidebar-nav a:hover > span.eco-sidebar-nav-icon {
    background: var(--color-dark);
    color: var(--color-white);
}

.admin-sidebar .eco-sidebar-nav li:hover a{
    color: var(--color-dark);
}

.admin-sidebar .eco-sidebar-nav a,
.admin-sidebar .eco-sidebar-nav a:visited {
    color: var(--color-white);
}

.admin-sidebar  .eco-sidebar-nav a .eco-sidebar-nav-icon,
.admin-sidebar  .eco-sidebar-nav a:visited .eco-sidebar-nav-icon {
    color: var(--color-white);
}





    .goToClients{
        margin-top: auto;
        padding: 25px 15px;
    }
    .version {
        text-align: center;
        color: #777777;
        font-size: 10px;
        position: absolute;
        bottom: 0;
        padding: 6px 16px;
    }
</style>
