<template>
    <div class="eco-content-space h100">
        <div class="employee-cards h100">
            <div class="table-top d-block">
                <b-row>
                    <b-col cols="12">
                        <div class="table-top-buttons">
                            <button-field @click="addNewUser()"
                                          icon="plus"
                                          color="primary">{{$ml.get('ADD_USER')}}
                            </button-field>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="pt-2">
                    <b-col cols="3">
                        <b-form-group label="Search">
                            <b-form-input
                                v-model="searchString"
                                :placeholder="$ml.get('search_by_name_company')"
                                class="font-small-2"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group label="Filter by status">
                            <b-form-select
                                v-model="filterStatus"
                                :options="optionsStatus"
                                class="font-small-2"
                                placeholder="Filter by status">
                            </b-form-select>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3" v-if="isCore">
                        <b-form-group label="Agency" class="bootstrapped-v-select filter-v-select">
                            <Select2
                                v-model="selectedClientOfId"
                                :options="generalSelectCompanies"
                                :placeholder="$ml.get('COMPANY')"
                                :settings="{
                                    templateResult: companyPreview,
                                    templateSelection: selectionPreview,
                                    escapeMarkup: function(stuff){return stuff}
                                }"
                            />
                        </b-form-group>
                    </b-col>

                </b-row>


            </div>
            <div class="eco-content-scroll">
                <div class="table-list-wrapper">

                    <b-table
                        :fields="tableColumns"
                        :items="tableRows"
                        :empty-html="emptyHtml"
                        class="font-small-2 table-vertical-align-middle table-list"
                    >
                        <template #row-details="data" :class="suspendedRow(data.item)"></template>

                        <template #cell(attributes.avatar)="data">
                            <div class="position-relative">
                                <span class="profile-avatar text-center hasCursor" @click="editUser(data.item.id)">
                                    <img v-if="data.item.attributes.avatar"
                                         :src="data.item.attributes.avatar._url"
                                         :alt="data.item.attributes.firstName + ' ' + data.item.attributes.lastName"
                                    >
                                    <font-awesome-icon v-else color="gray" icon="user" />
                                </span>
                            </div>
                        </template>

                        <template #cell(attributes.firstName)="data">
                            <div @click="editUser(data.item.id)" class="td-elipsis-100 hasCursor mr-2">
                                {{data.item.attributes.firstName}} {{data.item.attributes.lastName}}
                            </div>
                        </template>

                        <template #cell(attributes.onboarded)="data">
                            <div class="ellipsis" :class="data.item.attributes.onboarded ? 'onboarded' : 'pending'"><span class="status-text">{{niceStatus(data.item)}}</span><span v-if="hasTrial(data.item)" class="trial-subscript">{{$ml.get('trial_until')}}: {{niceDate(data.item.attributes.trialUntil)}}</span></div>
                        </template>

                        <template #cell(attributes.company.attributes.name)="data">
                            <div v-if="data.item.attributes.company"
                                 class="hasCursor d-flex align-items-center justify-content-start"
                                 @click="openEditCompany(data.item.attributes.company.id)"
                            >
                                <img v-if="data.item.attributes.company.attributes.logo" :src="data.item.attributes.company.attributes.logo._url" class="ml-2 company-logo"/>
                                <div class="d-flex flex-column">
                                    <div  class="td-elipsis-200 overflow-hidden font-small-2">{{data.item.attributes.company.attributes.name}}</div>
                                    <span class="font-small-1 text-muted">{{data.item.attributes.company.attributes.clientOf.attributes.name}}</span>
                                </div>
                            </div>
                        </template>

                        <template #cell(attributes.username)="data">
                            <div class="td-elipsis-150 overflow-hidden">
                                <a :href="`mailto:`+data.item.attributes.username">
                                    {{data.item.attributes.username}}
                                </a>
                            </div>

                        </template>

                        <template #cell(attributes.createdAt)="data">
                            <div class="ellipsis">{{niceDate(data.item.attributes.createdAt)}}</div>
                        </template>

                        <template #cell(attributes.lastLoginAt)="data">
                            <div class="ellipsis">{{niceDate(data.item.attributes.lastLoginAt)}}</div>
                        </template>

                        <template #cell(actions)="data">
                            <div class="table-action-btn-gorup">
                                <button-field @click="copyLink(data.item)"
                                              circle
                                              outline
                                              v-tooltip="`Copy invitation link`"
                                              icon="link"
                                              class="table-action-btn"
                                              color="secondary"
                                              :disabled="data.item.attributes.onboarded"
                                ></button-field>

                                <button-field @click="editUser(data.item.id)"
                                              circle
                                              outline
                                              v-tooltip="`Edit user`"
                                              icon="edit"
                                              class="table-action-btn"
                                              color="primary"></button-field>
                                <button-field @click="suspendUser(data.item.id)"
                                              circle
                                              outline
                                              :activated="isSuspended(data.item)"
                                              v-tooltip="suspendMesssage(data.item)"
                                              icon="moon"
                                              color="dark"
                                              class="table-action-btn"></button-field>
                                <button-field @click="deleteUser(data.item.id)"
                                              circle
                                              outline
                                              v-tooltip="$ml.get('tooltip_DELETE_USER')"
                                              icon="trash-alt"
                                              class="table-action-btn"
                                              :disabled="forbidDelete(data.item)"
                                              color="error"></button-field>
                            </div>
                        </template>

                    </b-table>

                </div>

            </div>
            <div class="table-bottom d-flex justify-content-between">
                <div class="table-bottom-buttons">
                    <button-field @click="bulkDelete"
                                  v-if="false"
                                  icon="trash-alt"
                                  color="error"
                                  :disabled="!selectedRowsTrue.length">{{$ml.get('DELETE_SELECTED')}}
                        ({{selectedRowsTrue.length}})
                    </button-field>
                </div>

                <div class="table-pagination">
                    <a href="#" @click="changeCurPage('prev')" :class="{ 'disable-click' : curPageValue==1 }">« {{$ml.get('Prev')}}</a>
                    <select-field :name="`curPage`"
                                  :id="`curPage`"
                                  :options="curPageOptions"
                                  :value="curPageValue"
                                  v-model="curPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PAGE')}}:
                    </select-field>
                    <a href="#" @click="changeCurPage('next')" :class="{ 'disable-click' : curPageValue==maxPages }">{{$ml.get('Next')}} »</a>
                </div>

                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import Parse from "parse";
    import Select2 from 'v-select2-component';
    import {VBTooltip} from "bootstrap-vue";

    export default {
        name: "DashboardClients",
        components: {
            Select2
        },
        directives: {
            'b-tooltip': VBTooltip
        },
        computed: {
            curUser() {
                return Parse.User.current();
            },
            generalSelectCompanies() {
                let selectData = [];

                if (this.generalChoices) {
                    this.generalChoices.forEach(oneAgency => {
                        let oneLine = {
                            id: oneAgency.id,
                            text: oneAgency.attributes.name,
                            fullData: oneAgency.attributes,
                            companyObj: oneAgency,
                            isAgency: true
                        };
                        selectData.push(oneLine);
                    });
                }

                let allLine = {
                    id: 'all',
                    text: 'All',
                    fullData: {
                        name: 'All',
                        isAgency: true,
                    },
                    companyObj: {
                        id: null,
                        attributes: {
                            name: 'All',
                            isAgency: true,
                        }
                    },
                    isAgency: true
                };

                selectData.unshift(allLine);

                return selectData;
            },
            isCore(){
                if(this.$store.state.userCompany && this.$store.state.userCompany.attributes){
                    return this.$store.state.userCompany.attributes.core;
                }else {
                    return false;
                }
            },
            tableRows() {
                return this.$store.state.visibleUsers;
            },
            totalResults() {
                return this.$store.state.usersTotalCount;
            },
            maxPages() {
                return Math.ceil(this.totalResults/this.resultsPerPage);
            },
            resultsPerPage() {
                return this.$store.state.usersPP;
            },
            curPageOptions() {
                let maxPageCount = Math.ceil(this.totalResults/this.resultsPerPage);
                let options = [
                    {
                        value: 1,
                        text: 1
                    }];
                let i = 2;
                while (i <= maxPageCount) {
                    options.push({
                        value: i,
                        text: i
                    });
                    i++;
                }
                return options;
            },
            selectedRowsTrue() {
                let selected = [];
                for (const key in this.selectedRows) {
                    if (this.selectedRows[key]) {
                        selected.push(key);
                    }
                }
                return selected;
            }
        },
        data() {
            return {
                filterStatus: null,
                optionsStatus: [
                    { value: null, text: 'All' },
                    { value: 'onboarded', text: 'Onboarded' },
                    { value: 'pending', text: 'Invitation pending' },
                ],

                selectedClientOfId: null,
                generalChoices: [],
                companyPlaceholder: require('../../assets/icon_bw.png'),

                tableColumns: [
                    { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
                    { key: 'attributes.avatar', label: '', thClass: 'tbl-pic font-bebas-neue',tdClass:'td-avatar', sortable: false},
                    { key: 'attributes.firstName', label: this.$ml.get('NAMES'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    { key: 'attributes.company.attributes.name', label: this.$ml.get('COMPANY'),  thClass: 'font-bebas-neue font-small-4', sortable: true},

                    { key: 'attributes.username', label: this.$ml.get('EMAIL'),  thClass: 'font-bebas-neue font-small-4', sortable: false},
                    { key: 'attributes.onboarded', label: this.$ml.get('STATUS'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    { key: 'attributes.createdAt', label: this.$ml.get('CREATED_ON'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    { key: 'attributes.loginCount', label: this.$ml.get('LOGIN_COUNT'),  thClass: 'font-bebas-neue font-small-4 text-center', tdClass:"text-center", sortable: true},
                    { key: 'attributes.lastLoginAt', label: this.$ml.get('LAST_LOGGED_ON'),  thClass: 'font-bebas-neue font-small-4', sortable: true},
                    {
                        key: 'actions',
                        label: this.$ml.get('ACTIONS'),
                        thClass: 'font-bebas-neue text-center font-small-4',
                        tdClass: 'text-right',
                        stickyColumn: true,
                        sortable: false
                    }
                ],
                emptyHtml: '<div class="text-center"><font-awesome-icon icon="user" size="3x" color="gray" /><p class="mt-2">No data found</p></div>',

                perPageOptions: [
                    {
                        value: 10,
                        text: 10
                    },
                    {
                        value: 25,
                        text: 25
                    },
                    {
                        value: 50,
                        text: 50
                    },
                    {
                        value: 100,
                        text: 100
                    },
                ],
                curPageValue: 1,
                perPageValue: 25,
                selectedRows: {},
                sharing: false,
                wizzardOpened: true,
                searchString: null,
                timeout: null
            }
        },
        created() {
            this.getAllAgencies();
        },
        mounted() {
            this.fetchUsers();
        },
        methods: {
            getAllAgencies() {
                if(this.isCore) {
                    let compQuery = new Parse.Query('Company');
                    compQuery.equalTo('isAgency', true);
                    compQuery.notEqualTo('core', true);
                    compQuery.descending('priority');

                    if(!this.showSuspended){
                        compQuery.notEqualTo('suspended', true);
                    }

                    compQuery.include('relatedCompanies');
                    compQuery.find().then((agencies) => {

                        // Sort the agencies array
                        const sortedAgencies = agencies.sort((a, b) => {
                            // Get priority values
                            const priorityA = a.get('priority') || 0;
                            const priorityB = b.get('priority') || 0;

                            // Compare priorities
                            if (priorityA !== priorityB) {
                                return priorityB - priorityA; // Descending order by priority
                            }

                            // If priorities are the same or undefined, sort alphabetically by name
                            const nameA = a.get('name') ? a.get('name').toLowerCase() : '';
                            const nameB = b.get('name') ? b.get('name').toLowerCase() : '';

                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            return 0; // Names are equal
                        });

                        // Log sorted agencies
                        sortedAgencies.forEach(agency => {
                            let agencyObj = agency.attributes;
                            // Get and sort related companies (clients) alphabetically by name
                            let sortedClients = (agencyObj.relatedCompanies || []).sort((clientA, clientB) => {
                                const nameA = clientA.get('name') ? clientA.get('name').toLowerCase() : '';
                                const nameB = clientB.get('name') ? clientB.get('name').toLowerCase() : '';

                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }
                                return 0; // Names are equal
                            });

                            agency.sortedClients = sortedClients;


                        });
                        this.generalChoices = sortedAgencies;
                        this.selectedClientOfId = sortedAgencies[0].id;
                    });

                }
                else {
                    let agencyObj = this.userCompany.attributes;
                    let sortedClients = (agencyObj.relatedCompanies || []).sort((clientA, clientB) => {
                        const nameA = clientA.get('name') ? clientA.get('name').toLowerCase() : '';
                        const nameB = clientB.get('name') ? clientB.get('name').toLowerCase() : '';

                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0; // Names are equal
                    });

                    this.userCompany.sortedClients = sortedClients;
                    this.generalChoices = [this.userCompany];
                }
            },
            selectionPreview(selection) {
                let markup = '';

                if (selection.children && selection.children.length > 0) {
                    // It's an agency with children
                    let agency = selection.companyObj.attributes;
                    if (!agency.logo) {
                        agency.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    markup += `<div class="agency selection">`;
                    markup +=   `<span class="profile-avatar">`;
                    markup +=       `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                    markup +=   `</span>`;
                    markup +=   `<h4>` + selection.text + `</h4>`;
                    markup += `</div>`;
                } else if (selection.fullData) {
                    let item = selection.fullData;

                    if (!item.logo) {
                        item.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    markup += selection.fullData.suspended ? `<div class="company selection suspended">` : `<div class="company selection">`;

                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                    markup += `</span>`;
                    if(item.clientOf && !selection.fullData.isAgency){
                        markup += `<div class="item-name">`;
                        markup += `<h4>` + item.name + `</h4>`;
                        markup += `<div class="client-of-name">`+item.clientOf.attributes.name+`</div>`;
                        markup += `</div>`;
                    }else {
                        markup += `<h4>` + item.name + `</h4>`;
                    }
                    markup += `</div>`;
                }

                return markup;
            },
            companyPreview(choice) {
                let markup = '';

                if (choice.isAgency) {
                    let agency = choice.fullData;
                    if (!agency.logo) {
                        agency.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    markup += `<div class="agency" data-agency-id="${choice.id}">`;
                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                    markup += `</span>`;
                    markup += `<h4 class="d-inline">` + choice.text + `</h4>`;
                    markup += `</div>`;
                } else if (choice.fullData) {
                    let item = choice.fullData;

                    if (!item.logo) {
                        item.logo = {
                            _url: this.companyPlaceholder
                        }
                    }

                    if (item.suspended) {
                        markup = `<div class="company suspended">`;
                    } else {
                        markup += choice.isOwnCompany ? `<div class="company own">` : `<div class="company">`;
                    }

                    markup += `<span class="profile-avatar">`;
                    markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                    markup += `</span>`;

                    if(item.clientOf && !item.isAgency){
                        markup += `<div class="item-name">`;
                        markup += `<h4>` + item.name + `</h4>`;
                        markup += `<div class="client-of-name">`+item.clientOf.attributes.name+`</div>`;
                        markup += `</div>`;
                    }else {
                        markup += `<h4>` + item.name + `</h4>`;
                    }
                    markup += `</div>`;
                }

                return markup;
            },
            fetchUsers(){
                this.$store.dispatch('getTotalUserCount', {
                    isAdmin: this.curUser.attributes.isAdmin,
                    agencyId: this.selectedClientOfId == 'all' ? null : this.selectedClientOfId,
                    status: this.filterStatus,
                    searchTerm: this.searchString
                }).then(() => {
                    this.$store.dispatch('getAvailableUsers',
                        {
                                    isAdmin: this.curUser.attributes.isAdmin,
                                    agencyId: this.selectedClientOfId == 'all' ? null : this.selectedClientOfId,
                                    status: this.filterStatus,
                                    searchTerm: this.searchString
                                }
                    );
                });
            },

            changeCurPage(direction) {

                if(direction == 'next') {
                    if(this.curPageValue < this.maxPages){
                        this.curPageValue++;
                    }
                }else if (direction == 'prev') {
                    if(this.curPageValue > 1){
                        this.curPageValue--;
                    }
                }

            },
            doSearch(searchString){
                this.$store.dispatch('getAvailableUsers', {
                    searchTerm : searchString,
                    isAdmin: this.curUser.attributes.isAdmin,
                    agencyId: this.selectedClientOfId == 'all' ? null : this.selectedClientOfId,
                    status: this.filterStatus,
                })
            },
            hasTrial(item){
                return !!item.attributes.trialUntil;
            },
            copyLink(item){

                let invitationLink = window.location.protocol + '//' + window.location.host
                +'/auth/onboard/'+item.id+'/'+item.attributes.passwordReset;

                navigator.clipboard.writeText(invitationLink);

                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('INVITATION_LINK_COPIED')
                });

            },
            niceDate(date){
                return moment(date).format('DD/MM/Y');
            },
            niceStatus(item){
                if(item.attributes.suspended) {
                    return this.$ml.get('USER_SUSPENDED');
                }else {
                    if(item.attributes.onboarded){
                        return this.$ml.get('ONBOARDED');
                    }else {
                        return this.$ml.get('INVITATION_PENDING');
                    }
                }
            },
            suspendedRow(item){
                if(this.isSuspended(item)){
                    return 'suspended';
                }else{
                    return '';
                }
            },
            suspendMesssage(item){
                if(this.isSuspended(item)){
                    return this.$ml.get('RESTORE_USER');
                }else {
                    return this.$ml.get('SUSPEND_USER');
                }
            },
            isSuspended(item){
                return item.attributes.suspended;
            },
            openEditCompany(compId){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardClientsForm', data: {id: compId}})
            },
            suspendUser(id){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserSuspend', data: {ids: [id]}})
            },
            forbidDelete(item){
                return Boolean(item.id == this.curUser.id);
            },
            toggleModal(componentName) {
                this.$store.commit('toggleDashboardModal', {name: componentName})
            },
            addNewUser(){
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserForm', data: {id: null}})
            },
            editUser(id = null) {
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserForm', data: {id: id}})
            },
            deleteUser(id) {
                // DashboardEmployeeCardsDelete
                this.$store.commit('toggleDashboardModal', {name: 'DashboardUserDelete', data: {ids: [id]}})
            },
            bulkDelete() {
                this.$store.commit('toggleDashboardModal', {
                    name: 'DashboardUserDelete',
                    data: {ids: this.selectedRowsTrue}
                })
            },
        },
        watch : {
            selectedClientOfId(){
                this.fetchUsers();
            },
            filterStatus(){
                this.fetchUsers();
            },
            perPageValue(newPerPage) {
                this.$store.commit('setUsersPP', newPerPage);
                if(this.curPageValue > 1) {
                    this.curPageValue = 1;
                } else {
                    this.fetchUsers();
                }
            },
            curPageValue(newPageVal){
                this.$store.commit('setUsersPage', newPageVal);
                this.fetchUsers();
            },
            searchString(newSearch) {
                clearTimeout(this.timeout);
                let self = this;
                self.timeout = setTimeout(() => {
                    self.doSearch(newSearch);
                }, 300);
            },
        }
    }
</script>

<style scoped>

    span.status-text {
        position: relative;
        display: block;
    }

    .onboarded {
        color: var(--color-secondary);
    }

    .pending {
        color: var(--color-primary);
    }

    .per-page-select {
        width: 110px;
    }
    .suspended {
        opacity: 0.5;
    }
    .avatar {
        max-height: 32px;
        max-width: 32px;
    }
    .company-logo {
        max-height: 28px;
        max-width: 45px;
        float: left;
        margin-right: 10px;
        margin-top: -1px;
    }
</style>
