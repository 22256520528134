<template>
    <div class="company-selectors">
        <div v-if="isCore & visible" id="agency_selector" class="m-0 selector-holder">
            <Select2
                ref="agencySelector"
                v-model="activeAgencyId"
                :options="selectAgencies"
                :settings="{
                        templateResult: companyPreview,
                        templateSelection: selectionPreview,
                        escapeMarkup: function(stuff){return stuff},
                        //initSelection: bindOptGroupClickEvent
                    }"
                @select="agencyPicked($event)"
            />
        </div>

        <div v-if="visible && !agencyOnly" id="company_selector" class="m-0 selector-holder">
            <Select2
                ref="select2Component"
                v-model="activeCompanyId"
                :options="selectCompanies"
                :settings="{
                        templateResult: companyPreview,
                        templateSelection: selectionPreview,
                        escapeMarkup: function(stuff){return stuff},
                        //initSelection: bindOptGroupClickEvent
                    }"
                @select="companyPicked($event)"
            />
        </div>


    </div>


</template>

<script>

import Select2 from 'v-select2-component';


export default {
    components: {
        Select2
    },
    props: {
      visible: {
          type: Boolean,
          default: true
      },
      agencyOnly: {
          type: Boolean,
          default: false
      },
      companyId: {
          type: String,
          default: null
      },
      clientOf: {
          type: String,
          default: null
      },
      allowBlank: {
          type: Boolean,
          default: false
      }
    },
    data(){
        return {
            activeCompanyId: '',
            activeAgencyId: '',

            activeAgency: {},

            isCore: false,
        }
    },
    computed: {
        userCompany(){
            let comp = this.$store.state.userCompany ? this.$store.state.userCompany : {id: null, attributes: {isAgency: false}};
            return comp;
        },
        allClientCompanies() {
            return this.$store.state.agencyClients;
        },
        masterAgencies() {
            return this.$store.state.masterAgencies;
        },

        selectCompanies() {
            let selectData = [];

            if (this.allClientCompanies) {
                this.allClientCompanies.forEach(oneAgency => {
                    let oneLine = {
                        id: oneAgency.id,
                        text: oneAgency.attributes.name,
                        fullData: oneAgency.attributes,
                        companyObj: oneAgency,
                        isAgency: false
                    };
                    selectData.push(oneLine);
                });
            }

            let ownLine = {};
            if(this.userCompany && !this.userCompany.attributes.core){
                ownLine = {
                    id: this.userCompany.id,
                    text: this.userCompany.attributes.name,
                    fullData: this.userCompany.attributes,
                    companyObj: this.userCompany,
                    suspended: this.userCompany.attributes.suspended
                };
            }else {
                if(this.activeAgency.id){
                    ownLine = {
                        id: this.activeAgency.id,
                        text: this.activeAgency.attributes.name,
                        fullData: this.activeAgency.attributes,
                        companyObj: this.activeAgency,
                        suspended: this.activeAgency.attributes.suspended
                    };
                }

            }

            if(ownLine.id) {
                selectData.unshift(ownLine);
            }

            selectData.sort((a, b) => {
                // Assuming fullData.name is a string
                const nameA = a.fullData.name.toUpperCase(); // Convert to uppercase to make the comparison case-insensitive
                const nameB = b.fullData.name.toUpperCase();

                if (nameA < nameB) {
                    return -1; // nameA comes first
                }
                if (nameA > nameB) {
                    return 1; // nameB comes first
                }
                return 0; // names are equal
            });

            if(this.allowBlank){
                let blankLine = {
                    id: 0,
                    text: "None",
                    fullData: {
                        name: "None",
                        logo: {
                            _url: require('../../../assets/noun-empty.svg')
                        }
                    }
                }
                selectData.unshift(blankLine)
            }

            return selectData;

        },
        selectAgencies(){
            let selectData=[];

            if (this.masterAgencies) {
                this.masterAgencies.forEach(oneAgency => {
                    let oneLine = {
                        id: oneAgency.id,
                        text: oneAgency.attributes.name,
                        fullData: oneAgency.attributes,
                        companyObj: oneAgency,
                        isAgency: true
                    };
                    selectData.push(oneLine);
                });
            }
            return selectData;
        },

    },

    async created() {
        console.log('Params on load');

        console.log("Company ID:", this.companyId);
        console.log("Agency ID: ", this.clientOf);

        await this.$store.dispatch('getCompany').then((userCompany) => {

            if(userCompany.attributes.core){

                this.isCore = true;
                this.$store.dispatch('getAllAgencies').then((masterAgencies) => {

                    let primaryAgency = masterAgencies.find(agency => agency.attributes.priority == 100);

                    if(!this.clientOf){
                        this.activeAgencyId =  primaryAgency.id;
                    }

                    if(!this.companyId){
                        if(userCompany.attributes.impersonating && userCompany.attributes.impersonating.id && this.idIsInList(userCompany.attributes.impersonating.id)){
                            this.activeCompanyId = userCompany.attributes.impersonating.id;
                        } else {
                            this.activeCompanyId = primaryAgency ? primaryAgency.id : this.$store.state.userCompany.id;
                        }
                    } else {
                        this.activeCompanyId = this.companyId;
                    }

                });

            }

            else {

                this.$store.dispatch('getAgencyClients').then(() => {
                    if(!this.companyId){
                        if(userCompany.attributes.impersonating && userCompany.attributes.impersonating.id && this.idIsInList(userCompany.attributes.impersonating.id)){
                            this.activeCompanyId = userCompany.attributes.impersonating.id;
                        } else {
                            this.activeCompanyId = this.$store.state.userCompany ? this.$store.state.userCompany.id : null;
                        }
                    }else {
                        this.activeCompanyId = this.companyId;
                    }
                });

            }
        });
    },

    mounted(){
        if(this.companyId){
            this.activeCompanyId = this.companyId;
        }
        if(this.clientOf){
            this.activeAgencyId = this.clientOf;
        }
    },

    methods: {
        getImpersonatedCompanyId(userCompany, fallbackCompany) {
            if (userCompany.attributes.impersonating &&
                userCompany.attributes.impersonating.id &&
                this.idIsInList(userCompany.attributes.impersonating.id)) {
                return userCompany.attributes.impersonating.id;
            } else {
                return fallbackCompany ? fallbackCompany.id : null;
            }
        },
        idIsInList(id){
            return this.allClientCompanies.find(company => company.id == id);
        },
        agencyPicked(selectData){
            this.$emit('agencyPicked', selectData);
            this.activeAgencyId = selectData.id;
        },
        companyPicked(selectData){
            this.$emit('companyPicked', selectData);
            this.activeCompanyId = selectData.id;
        },
        companyPreview(choice) {
            let markup = '';

            if (choice.isAgency) {
                let agency = choice.fullData;
                if (!agency.logo) {
                    agency.logo = {
                        _url: this.companyPlaceholder
                    }
                }

                markup += `<div class="agency" data-agency-id="${choice.id}">`;
                markup += `<span class="profile-avatar">`;
                markup += `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                markup += `</span>`;
                markup += `<h4 class="d-inline">` + choice.text + `</h4>`;
                markup += `</div>`;

            } else if (choice.fullData) {
                let item = choice.fullData;

                if (!item.logo) {
                    item.logo = {
                        _url: this.companyPlaceholder
                    }
                }

                if (item.suspended) {
                    markup = `<div class="company suspended">`;
                } else {
                    markup += choice.isOwnCompany ? `<div class="company own">` : `<div class="company">`;
                }

                markup += `<span class="profile-avatar">`;
                markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                markup += `</span>`;

                if(item.clientOf && !item.isAgency){
                    markup += `<div class="item-name">`;
                    markup += `<h4>` + item.name + `</h4>`;
                    markup += `<div class="client-of-name">`+item.clientOf.attributes.name+`</div>`;
                    markup += `</div>`;
                }else {
                    markup += `<h4>` + item.name + `</h4>`;
                }
                markup += `</div>`;
            }

            return markup;
        },

        selectionPreview(selection) {
            let markup = '';

            if (selection.children && selection.children.length > 0) {
                // It's an agency with children
                let agency = selection.companyObj.attributes;
                if (!agency.logo) {
                    agency.logo = {
                        _url: this.companyPlaceholder
                    }
                }

                markup += `<div class="agency selection">`;
                markup += `<span class="profile-avatar">`;
                markup += `<img class="comp-logo" src="` + agency.logo._url + `" />`;
                markup += `</span>`;
                markup += `<h4>` + selection.text + `</h4>`;
                markup += `</div>`;
            } else if (selection.fullData) {
                let item = selection.fullData;

                if (!item.logo) {
                    item.logo = {
                        _url: this.companyPlaceholder
                    }
                }
                markup += selection.isOwnCompany ? `<div class="company selection own">` : `<div class="company selection">`;

                markup += `<span class="profile-avatar">`;
                markup += `<img class="comp-logo" src="` + item.logo._url + `" />`;
                markup += `</span>`;
                if(item.clientOf && !selection.fullData.isAgency){
                    markup += `<div class="item-name">`;
                    markup += `<h4>` + item.name + `</h4>`;
                    markup += `<div class="client-of-name">`+item.clientOf.attributes.name+`</div>`;
                    markup += `</div>`;
                }else {
                    markup += `<h4>` + item.name + `</h4>`;
                }
                markup += `</div>`;
            }

            return markup;
        },

    },
    watch: {
        activeAgencyId(newVal){
            if(newVal){
                this.$store.dispatch('getClientsOfAgency', {agencyId: newVal}).then(() => {
                    if(!this.idIsInList(this.companyId) && this.allClientCompanies.length > 0){
                        this.activeCompanyId = this.allClientCompanies[0].id;
                    }
                });
                this.$store.commit('setAgencyId', newVal);
                this.activeAgency = this.masterAgencies.find(agency => agency.id == newVal);
            }
        },
    }
}


</script>

<style scoped>

.selector-holder {
    display: inline-block;
    vertical-align: middle;
}

#company_selector .select2-container--default .select2-selection--single .select2-selection__rendered,
#agency_selector .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 12px;
}

.company .item-name h4 {

}

.client-of-name {
    font-size: 10px;
    color: #6c757d;
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1.25em;
}

.select2-results__option .agency {
    font-size: 12px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
}

.agency h4 {
    margin: auto 0;
    color: black;
}

.company h4 {
    margin: auto 0;
    font-size: 12px;
    font-weight: inherit;
}

li.select2-results__option .company {
    padding-left: 1.25em;
}

#company_selector, #agency_selector{
    width: 250px;
}

#company_selector > div,
#agency_selector > div{
    width: 100%;
}

#company_selector .select2-container,
#agency_selector .select2-container {
    width: 100% !important;
}
</style>
