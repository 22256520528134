export const validatorPositive = value => {
	if (value >= 0) {
		return true
	}
	return false
}

export const validatorPassword = password => {
	/* eslint-disable no-useless-escape */
	const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.~%^!@#$%&*()]).{8,}/
	/* eslint-enable no-useless-escape */
	const validPassword = regExp.test(password)
	return validPassword
}

export const validatorCreditCard = creditnum => {
	/* eslint-disable no-useless-escape */
	const cRegExp = /^(?:3[47][0-9]{13})$/
	/* eslint-enable no-useless-escape */
	const validCreditCard = cRegExp.test(creditnum)
	return validCreditCard
}

export const validatorUrlValidator = val => {
	if (val === undefined || val === null || val.length === 0) {
		return true
	}
	/* eslint-disable no-useless-escape */
	const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
	/* eslint-enable no-useless-escape */
	return re.test(val)
}

export const validatorPhoneNumber = (value) => {
	if (!value) {
		return true; // Allow empty values, use 'required' rule if the field is mandatory
	}

	// First, check if the original input contains any invalid characters
	if (/[^\d+\s().-]/g.test(value)) {
		return false; // Invalid characters found
	}

	// Remove all non-digit characters except '+' and spaces
	const cleanedNumber = value.replace(/[^\d+\s]/g, '');

	// Check if the number starts with a '+'
	const hasCountryCode = cleanedNumber.startsWith('+');

	// Define the regex pattern
	let pattern;
	if (hasCountryCode) {
		// For numbers with country code: +XX XXXXXXXXX
		// This pattern requires a '+', 1-3 digits for country code, then 6-14 digits, allowing spaces in between
		pattern = /^\+\d{1,3}(\s?\d){6,14}$/;
	} else {
		// For numbers without country code: XXXXXXXXX
		// This pattern requires 6-14 digits, allowing spaces in between
		pattern = /^(\d\s?){6,14}$/;
	}

	// Test if the cleaned number matches the pattern
	return pattern.test(cleanedNumber);
};


