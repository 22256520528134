<template>
    <div class="eco-content-space h100 overflow-y-auto">
        <div class="white-box">
            <div class="table-top d-block">
                <b-row>
                    <b-col cols="12">
                        <div class="table-top-buttons">
                            <button-field v-b-toggle.promo-form-modal
                                          icon="plus"
                                          color="primary">{{$ml.get('ADD_PROMO')}}
                            </button-field>
                        </div>
                    </b-col>
                </b-row>

                <!--<div class="table-top-search">-->
                <!--{{$ml.get('Search')}}-->
                <!--</div>-->

                <b-row class="pt-2">
                    <b-col cols="3">
                        <b-form-group label="Search">
                            <b-form-input
                                v-model="searchString"
                                :placeholder="$ml.get('search_by_promo_code')"
                                class="font-small-2"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-checkbox
                            v-model="showExpired"
                            switch size="md"
                            class="mr-2 mt-2 pt-1"
                            id="showSuspended"
                            name="showSuspended"
                        >{{$ml.get('show_expired_promos')}}</b-form-checkbox>
                    </b-col>

                </b-row>
            </div>

            <div class="eco-content-scroll">
                <div class="table-list-wrapper">
                    <b-table
                        :fields="tableColumns"
                        :items="tableRows"
                        :empty-html="emptyHtml"
                        class="font-small-2 table-vertical-align-middle table-list"
                    >

                        <template #cell(attributes.description)="data">
                            <p class="mb-0" v-html="data.item.attributes.description"></p>
                        </template>

                        <template #cell(attributes.emailText)="data">
                            <p class="mb-0" v-html="data.item.attributes.emailText"></p>
                        </template>

                        <template #cell(capabilities)="data">
                            <div class="d-flex justify-content-start py-1">

                                <b-badge
                                    :variant="data.item.attributes.allowTrial ? `success` : `gray`"
                                    class="mr-1 color-white"
                                    >
                                    Trial period
                                </b-badge>
                                <b-badge
                                    :variant="data.item.attributes.allowAgencyUpgrade ? `success` : `gray`"
                                    class="mr-1 color-white">
                                    Agency upgrade
                                </b-badge>
                            </div>
                        </template>

                        <template #cell(attributes.validUntil)="data">
                            <div class="d-inline">
                                <p class="text-muted mb-0">{{data.item.attributes.validUntil | dateFormat}}</p>
                                <p v-if="hasExpired(data.item)" class="font-small-1 text-danger mb-0">Promo has expired</p>
                            </div>
                        </template>


                        <template #cell(actions)="data">
                            <div class="table-action-btn-gorup">
                                <button-field @click="copyLink(data.item)"
                                              circle
                                              outline
                                              v-tooltip="`Copy promo link`"
                                              icon="link"
                                              class="table-action-btn"
                                              color="secondary"
                                              :disabled="hasExpired(data.item)"
                                ></button-field>
                                <button-field @click="downloadQR(data.item)"
                                              circle
                                              outline
                                              icon="download"
                                              class="table-action-btn"
                                              v-tooltip="'Download QR code'"
                                              color="dark"></button-field>
                                <button-field
                                            v-b-toggle.promo-form-modal
                                              @click="editPromo(data.item)"
                                              circle
                                              outline
                                              icon="edit"
                                              class="table-action-btn"
                                              v-tooltip="'Edit information'"
                                              color="primary"></button-field>
                                <button-field @click="deletePromo(data.item.id)"
                                              circle
                                              outline
                                              icon="trash-alt"
                                              class="table-action-btn"
                                              v-tooltip="'Delete card'"
                                              color="error"></button-field>
                            </div>
                        </template>

                    </b-table>
                </div>
            </div>

            <div class="table-bottom" v-if="false">
                <div class="table-pagination">
                    <a href="#" @click="changeCurPage('prev')" :class="{ 'disable-click' : curPageValue==1 }">« {{$ml.get('Prev')}}</a>
                    <select-field :name="`curPage`"
                                  :id="`curPage`"
                                  :options="curPageOptions"
                                  :value="curPageValue"
                                  v-model="curPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PAGE')}}:
                    </select-field>
                    <a href="#" @click="changeCurPage('next')" :class="{ 'disable-click' : curPageValue==maxPages }">{{$ml.get('Next')}} »</a>
                </div>
                <div class="table-result-per-page">
                    <select-field :name="`selectField`"
                                  :id="`selectField`"
                                  :options="perPageOptions"
                                  :value="perPageValue"
                                  v-model="perPageValue"
                                  class="per-page-select">{{$ml.get('RESULTS_PER_PAGE')}}
                    </select-field>
                </div>
            </div>
        </div>

        <b-sidebar
                id="promo-form-modal"
                ref="promo-form-modal"
                class="bg-white"
                title="Edit Promo"
                right
                width="500px"
                shadow
                backdrop
                @hidden="onModalClose">
            <div class="form-group">
                <validation-observer ref="promo-form">

                    <b-form-group
                        label="Promo code"
                        label-for="promo-code"
                        class="required"
                    >
                        <validation-provider rules="required" name="Promo code" v-slot="{ errors }">
                            <b-form-input
                                id="promo-code"
                                v-model="currentPromo.code"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : ''"
                            ></b-form-input>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group
                        label="Description (visible in orange stripe)"
                        label-for="promo-code"
                        class="required"
                    >
                        <validation-provider rules="required" name="Promo code" v-slot="{ errors }">
                            <b-form-textarea
                                id="promo-code"
                                rows="3"
                                v-model="currentPromo.description"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : ''"></b-form-textarea>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group
                        label="Subtitle text in marketing card"
                        label-for="promo-code"
                    >
                        <b-form-textarea
                            id="promo-code"
                            rows="2"
                            v-model="currentPromo.cardText"
                        ></b-form-textarea>
                    </b-form-group>

                    <b-form-group
                        label="Promo text for e-mail"
                        label-for="promo-code"
                        class="required"
                    >
                        <validation-provider rules="required" name="Promo code" v-slot="{ errors }">
                            <b-form-textarea
                                id="promo-code"
                                rows="8"
                                v-model="currentPromo.emailText"
                                :state="errors.length > 0 ? false : null"
                                :class="errors.length > 0 ? 'is-invalid' : ''"></b-form-textarea>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                        </validation-provider>
                    </b-form-group>

                    <b-row>
                        <b-col cols="6">
                            <b-form-group
                                label="Valid until"
                                label-for="promo-code"
                                class="required"
                            >
                                <validation-provider rules="required" name="Promo code" v-slot="{ errors }">
                                    <b-form-datepicker
                                        id="promo-code"
                                        v-model="currentPromo.validUntil"
                                        :state="errors.length > 0 ? false : null"
                                        :class="errors.length > 0 ? 'is-invalid' : ''"></b-form-datepicker>
                                    <div class="invalid-feedback">{{ errors[0] }}</div>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="6">
                            <b-form-group
                                label="Trial duration (in days)"
                                label-for="trial-duration"
                                class="required"
                            >
                                <b-form-input type="number"
                                              id="trial-duration"
                                              :disabled="!currentPromo.allowTrial"
                                              v-model="currentPromo.trialDuration">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>


                    <b-form-group
                        label="Capabilities"
                        label-for="promo-code"
                    >
                        <b-row>
                            <b-col cols="12">
                                <b-form-checkbox v-model="currentPromo.allowAgency"  class="d-inline-block mr-2">Allow agency upgrade</b-form-checkbox>
                            </b-col>
                            <b-col cols="6">
                                <b-form-checkbox v-model="currentPromo.allowTrial" @change="currentPromo.trialDuration = currentPromo.allowTrial ? 14 : 0 " class="d-inline-block mr-2">Allow trial period</b-form-checkbox>
                            </b-col>

                        </b-row>
                    </b-form-group>




                </validation-observer>
                <div class="text-center">
                    <button-field
                        @click="savePromo"
                        color="secondary"
                        icon="save"
                        class="mt-2">{{$ml.get('buttons.SAVE_PROMO')}}</button-field>
                </div>

            </div>
        </b-sidebar>

    </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
    name: "PromoList",
    data(){
        return {
            something: false,

            showExpired: false,
            searchString: '',
            currentPromo: {
                id: null,
                code: '',
                cardText: '',
                description: '',
                emailText: '',
                validUntil: '',
                trialDuration: 0,
                allowTrial: false,
                allowAgency: false,
                capabilities: '',
            },
            emptyHtml: '<div class="empty-table">No results found</div>',
            selectAllStatus: false,
            selectedRows: {},
            tableColumns: [
                { key: 'attributes.code', label: 'Promo code', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'attributes.description', label: 'Promo text', thClass: 'font-bebas-neue font-small-4', tdClass: 'td-wrap td-max-200', sortable: true},
                { key: 'attributes.emailText', label: 'Email text', thClass: 'font-bebas-neue font-small-4', tdClass: 'td-wrap td-max-300', sortable: true},
                { key: 'attributes.validUntil', label: 'Valid until', thClass: 'font-bebas-neue font-small-4',  tdClass:'cell-vertical-align-middle', sortable: true},
                { key: 'attributes.useCount', label: 'Use count', thClass: 'font-bebas-neue font-small-4', sortable: true},
                { key: 'capabilities', label: 'Capabilities', thClass: 'font-bebas-neue font-small-4', sortable: false},
                {
                    key: 'actions',
                    label: 'Actions',
                    thClass: 'font-bebas-neue text-center font-small-4',
                    tdClass: 'text-right',
                    sortable: false
                }
            ],

            /* PAGINATION STUFF */
            curPageValue: 1,
            totalResults: 0,
            perPageOptions: [
                {
                    value: 5,
                    text: 5
                },
                {
                    value: 10,
                    text: 10
                },
                {
                    value: 25,
                    text: 25
                },
                {
                    value: 50,
                    text: 50
                },
                {
                    value: 100,
                    text: 100
                },
            ],
            perPageValue: 10,


        }
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    created(){
        this.fetchPromos();
    },
    computed: {
        tableRows() {
            return this.$store.state.promos;
        },
        resultsFrom() {
            if(this.$store.state.employeeCardCount == 0) {
                return 0;
            }
            else {
                return ((this.curPageValue-1)*this.perPageValue)+1;
            }

        },
        resultsTo() {
            let pageMaxResults = this.curPageValue*this.perPageValue;

            if(pageMaxResults > this.$store.state.employeeCardCount){
                return this.$store.state.employeeCardCount;
            }else{
                return pageMaxResults;
            }
        },
    },
    methods: {
        resetCurrentPromo() {
            this.currentPromo = {
                id: null,
                code: '',
                cardText: '',
                description: '',
                emailText: '',
                validUntil: '',
                trialDuration: 0,
                allowTrial: false,
                allowAgency: false,
                capabilities: '',
            }
        },

        savePromo() {
            this.$refs['promo-form'].validate().then(success => {
                if(success){
                    this.$store.dispatch('savePromo', this.currentPromo).then(() => {
                            this.$notify({
                                group: 'notification',
                                type: 'success',
                                text: this.$ml.get('notification.promo_saved')
                            });
                            this.$refs['promo-form-modal'].hide();
                        }
                    ).catch(e => {
                        console.log(e);
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            text: e.message ? e.message : this.$ml.get('notification.error')
                        });
                    });
                }
            });
        },

        downloadQR(promo, format = 'png') {
            let targetUrl = this.composePromo(promo);
            let backendQRUrl = process.env.VUE_APP_API_URL+'/helpers/utils/qrFileGenerator.php?filename=qfecard_promo_qr_'+promo.attributes.code+'&outputType='+format+'&qrURL='+targetUrl;
            window.open(backendQRUrl+targetUrl, '_blank')
        },
        composePromo(promo) {
            if(promo && promo.attributes){
                return window.location.origin + '/signup/?promo=' + promo.attributes.code;
            } else {
                return window.location.origin + '/signup/';
            }
        },
        copyLink(promo){
            let invitationLink = this.composePromo(promo);
            navigator.clipboard.writeText(invitationLink);

            this.$notify({
                group: 'notification',
                type: 'success',
                text: this.$ml.get('PROMO_LINK_COPIED')
            });
        },
        hasExpired(item) {
            return new Date(item.attributes.validUntil) < new Date();
        },
        onSelectAll(checked) {
            this.selectedRows = {};
            if (checked) {
                this.tableRows.forEach(row => {
                    this.selectedRows[row.id] = row.id;
                });
            }
        },

        onModalClose() {
            this.resetCurrentPromo();
            this.fetchPromos();
        },

        fetchPromos() {
            this.$store.dispatch('getPromos', {promoCode: this.searchString, showExpired: this.showExpired});
        },
        editPromo(promo) {
            this.currentPromo = {...promo.attributes}
            this.currentPromo.id = promo.id;
        },
        showDeleteModal(){
            this.$bvModal.show('delete-modal');
        },
        deletePromo(id) {
            this.$store.dispatch('deletePromo', id).then(() => {
                this.$notify({
                    group: 'notification',
                    type: 'success',
                    text: this.$ml.get('notification.promo_deleted')
                });
                this.fetchPromos();
            }).catch(e => {
                this.$notify({
                    group: 'notification',
                    type: 'error',
                    text: e.response.data.message ? e.response.data.message : this.$ml.get('notification.error')
                });
            });
        },


        /*PAGINATION */
        maxPages() {
            return Math.ceil(this.totalResults/this.resultsPerPage);
        },
        curPageOptions() {
            let maxPageCount = Math.ceil(this.totalResults/this.resultsPerPage);
            let options = [{
                    value: 1,
                    text: 1
                }];
            let i = 2;
            while (i <= maxPageCount) {
                options.push({
                    value: i,
                    text: i
                });
                i++;
            }
            return options;
        },
        changeCurPage(direction) {

            if(direction == 'next') {
                if(this.curPageValue < this.maxPages){
                    this.curPageValue++;
                }
            }else if (direction == 'prev') {
                if(this.curPageValue > 1){
                    this.curPageValue--;
                }
            }

        },
    },
    watch: {
        showExpired: {
            handler: function () {
                this.fetchPromos();
            },
            immediate: true
        },
        searchString: {
            handler: function () {
                this.fetchPromos();
            },
            immediate: true
        },
    }

}
</script>

<style>
.td-wrap {
    white-space: wrap !important;
}

.td-max-200 {
    max-width: 200px    ;
}

.td-max-300 {
    max-width: 300px;

}

</style>
