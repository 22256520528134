<template>
    <div class="eco-content-scroll">
        <div class="eco-content-space mh100">
            <b-row>
                <b-col cols="5">
                </b-col>
                <b-col cols="7">
                    <div class="d-flex justify-content-between mb-3">

                        <div class="button-group mt-0">
                            <button-field icon="history" color="dark" @click="revertSettings()" :disabled="this.revertDisabled">{{$ml.get('REVERT_CHANGES')}}</button-field>
                            <button-field icon="save" color="secondary" @click="submitForm()"
                                          :disabled="loading"
                                          :loading="loading">{{$ml.get('SAVE_CHANGES')}}</button-field>
                        </div>

                        <button-field icon="palette" color="primary"  v-b-toggle.template-selector>{{$ml.get('buttons.SELECT_TEMPLATE')}}</button-field>
                    </div>
                    <hr/>
                </b-col>
            </b-row>

            <div class="row">
                <!-- PREVIEW iFRAME -->
                <div class="col-5">
                    <div class="card-designs">
                        <div class="wrapper-phone iphone sc06">
                            <iframe v-if="frameVisible"
                                    class="cardPreview"
                                    name="cardPreview"
                                    :src="`${apiUrl}cards/render/?pr=1&comp=${cardCompany.id}&t=${currentTemplate}&d=${useDefaults}`"  frameborder="0"></iframe>
                        </div>
                    </div>

                    <!-- APP ICONS -->
                    <div class="app-icons-holder">
                        <div class="ios-icon-block icon-block">
                            <img v-if="images.appIcon && images.appIcon._url" :src="images.appIcon._url" class="ios-icon icon-img"/>
                            <img v-else :src="defaultAppIcon" class="ios-icon icon-img"/>
                            <div class="ios-icon-label">iOS Icon</div>
                        </div>
                        <div class="android-icon-block icon-block">
                            <img v-if="images.appIcon && images.appIcon._url" :src="images.appIcon._url" class="android-icon icon-img"/>
                            <img v-else :src="defaultAppIcon" class="android-icon icon-img"/>
                            <div class="ios-icon-label">Android Icon</div>
                        </div>
                    </div>

                    <div class="text-center pt-2">
                        <button-field icon="palette" color="primary" @click="applyDefaults()">{{$ml.get('APPLY_DEFAULT_SETTINGS')}}</button-field>
                    </div>

                    <div class="text-center pt-2 mt-2" v-if="isCore && designNotReady">
                        <hr/>
                        <b-link
                            target="_blank"
                            v-if="$store.state.cardCompany && $store.state.cardCompany.attributes.website"
                            :href="properLink($store.state.cardCompany.attributes.website)">{{$store.state.cardCompany.attributes.website}}</b-link>

                        <b-form-checkbox v-model="confirmReady" class="pt-1">
                            {{$ml.get('buttons.DESIGN_READY')}}
                        </b-form-checkbox>

                        <button-field icon="cloud-upload-alt"
                                      color="secondary"
                                      class="mt-2"
                                      :disabled="!confirmReady"
                                      @click="$bvModal.show('confirmDesignReady')">
                            {{$ml.get('buttons.UPLOAD_AND_NOTIFY_CLIENT')}}
                        </button-field>
                    </div>
                </div>

                <!-- CUSTOMIZATION FIELDS -->
                <div class="col-7">
                    <form :action="`${apiUrl}cards/preview/?pr=1&d=${defaultsClicked}&comp=${cardCompany.id}&t=${selectedTemplateSlug}`"
                          method="post"
                          target="cardPreview"
                          ref="cardPreview">
                        <input type="hidden" name="preview_data" value="1"/>
                        <input type="hidden" name="d" :value="this.defaultsClicked"/>
                    <div class="row">
                        <div v-if="isAdminUser && hasCodemirror" class="col-12 mb-3">

                            <div v-b-toggle.code-accordion class="w-100 border p-2 rounded bg-grey d-flex justify-content-between">
                                <label class="form-label qf-label-clone">Custom code</label>
                                <span><font-awesome-icon :icon="['fas', 'chevron-down']"/></span>
                            </div>

                            <b-collapse id="code-accordion" class="bg-grey p-2" accordion="code-accordion" role="tabpanel" >
                                <b-row>
                                    <b-col cols="6">
                                        <label for="customCSS" class="custom-label">CSS</label>
                                        <div class="d-block position-relative">
                                            <codemirror
                                                ref="customCSSMirror"
                                                name="customCSS"
                                                v-model="form.customCSS"
                                                :options="cmOptions"
                                                class="mirror-wrapper"
                                            />
                                        </div>
                                    </b-col>

                                    <b-col cols="6">
                                        <label for="customSVG" class="custom-label">SVG Background</label>
                                        <div class="d-block position-relative">
                                            <codemirror
                                                ref="customSVGMirror"
                                                name="customSVG"
                                                v-model="form.customSVG"
                                                :options="cmJSOptions"
                                                class="mirror-wrapper"
                                            />
                                        </div>
                                    </b-col>

                                </b-row>

                            </b-collapse>



                        </div>
                        <div v-if="isAdminUser && hasCustomSVG" class="col-12 mb-3">
                            <codemirror
                                ref="customSVGMirror"
                                name="customSVG"
                                v-model="form.customSVG"
                                :options="cmOptions"
                                class="mirror-wrapper"
                            />
                        </div>

                        <div class="col-6">
                            <label class="form-label min-height-label">{{$ml.get('SHORTCUT_ICON')}}</label>
                        </div>
                        <div class="col-6">
                            <label class="form-label min-height-label">{{$ml.get('CLIENT_LOGO')}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 relative">
                            <span class="more-info" v-tooltip="$ml.get('tooltip_app_icon')">
                                <font-awesome-icon icon="info-circle"/>
                            </span>
                            <dropzone-field name="appIcon"
                                            id="appIcon"
                                            :accept="`image/*`"
                                            v-model="images.DropzoneField">
                                <template v-if="images.appIcon && images.appIcon._url">
                                    <img :src="images.appIcon._url">
                                </template>
                                <template v-else>
                                    <div v-html="$ml.get('clientIconDropzone')"></div>
                                </template>

                            </dropzone-field>
                        </div>

                        <template v-for="(inputField, index) in fieldSet" >
                            <div :class="inputField.col" :key="'f-'+index">
                                <!-- TO-DO: Proper functionality to clear image controls -->
                                <span v-if="false && inputField.fieldType == 'DropzoneField' && images[inputField.field] && images[inputField.field]._url"
                                      class="clear-control"
                                      @click="clearDropzone(inputField.field)"
                                >
                                    <font-awesome-icon icon="times"/>
                                </span>
                                <span v-if="inputField.fieldType == 'DropzoneField' &&
                                            (inputField.field == 'imgBgFull' || inputField.field == 'imgBgTop')"
                                      class="more-info" v-tooltip="$ml.get('tooltip_background')">
                                    <font-awesome-icon icon="info-circle"/>
                                </span>
                                <span v-else-if="inputField.fieldType == 'DropzoneField'"
                                      class="more-info" v-tooltip="$ml.get('tooltip_comp_logo')">
                                    <font-awesome-icon icon="info-circle"/>
                                </span>

                                <dropzone-field v-if="inputField.fieldType == 'DropzoneField'" v-bind:key="index"
                                                :name="inputField.field"
                                                :id="inputField.field"
                                                :accept="`image/*`"
                                                v-model="images[inputField.fieldType]">
                                    <template v-if="images[inputField.field] && images[inputField.field]._url">
                                        <img :src="images[inputField.field]._url"
                                             v-if="images[inputField.field]._url !== undefined">
                                    </template>
                                    <template v-else>
                                        <div v-html="$ml.get(inputField.label)"></div>
                                    </template>
                                </dropzone-field>
                                <div
                                    id="font-selector"
                                    v-else-if="inputField.fieldType == 'FontFamily'" v-bind:key="inputField.label"
                                >
                                    <Select2
                                            v-model="form['relatedFont']"
                                            :options="selectFonts"
                                            :placeholder="$ml.get(inputField.label)"/>
                                    <input type="hidden" :value="form['relatedFont']" name="relatedFont" />
                                </div>
                                <b-form-group
                                    v-else-if="inputField.fieldType == 'SliderField'"
                                    :label="$ml.get(inputField.label)+` (`+form[inputField.field]+`%)`" v-bind:key="inputField.field">
                                    <b-form-input type="range"
                                                  v-model="form[inputField.field]"
                                                  step="1"
                                                  :name="inputField.field"
                                                  :value="form[inputField.field]"
                                                  :min="inputField.min ? parseInt(inputField.min) : 0"
                                                  :max="inputField.min ? parseInt(inputField.max) : 100"
                                    >

                                    </b-form-input>
                                </b-form-group>

                                <b-form-group
                                    v-else-if="inputField.fieldType == 'ColorField'"
                                    :label="$ml.get(inputField.label)"
                                >
                                    <div class="d-flex flex-gap">
                                        <b-form-input type="color"
                                                      v-model="form[inputField.field]"
                                                      :name="inputField.field"
                                        >

                                        </b-form-input>
                                        <b-form-input type="text"
                                                      v-model="form[inputField.field]"
                                                      :name="inputField.field">

                                        </b-form-input>
                                    </div>

                                </b-form-group>

                                <b-form-group
                                    v-else-if="inputField.fieldType == 'InputField'"
                                    :label="$ml.get(inputField.label)">
                                    <b-form-input type="text"
                                                  v-model="form[inputField.field]"
                                                  :name="inputField.field"
                                    >

                                    </b-form-input>
                                </b-form-group>

                                <b-form-group
                                    v-else-if="inputField.fieldType == 'SelectField'"
                                    :label="$ml.get(inputField.label)"
                                >
                                    <b-form-select
                                          :id="inputField.field"
                                          v-model="form[inputField.field]"
                                          :name="inputField.field"
                                          :options="inputField.options"
                                    >

                                    </b-form-select>
                                </b-form-group>

                                <hr v-else-if="inputField.field == 'Separator'">
                            </div>
                        </template>





                    </div>
                    </form>

                </div>

            </div>
        </div>

        <!-- LIST OF DESIGNS -->
        <b-sidebar id="template-selector"
                   :title="$ml.get('modals.cardDesign.template_picker')"
                   right shadow backdrop width="330px">
            <div v-if="availableCards.length > 0"
                 class="cards-grid" v-bind:class="{ active: !isPreview }">
                    <div v-for="(cardTemplate,index) in hiddenCardStack"
                         class="wrapper-phone iphone template hidden-card"
                         :class="cardTemplate.id==selectedTemplate.id ? 'picked' : ''"
                         @click="selectCard(cardTemplate.attributes.nice_name, cardTemplate)"
                         v-bind:key="index">
                        <transition enter-active-class="animated fadeIn"
                                    leave-active-class="animated fadeOut"
                                    mode="out-in">
                            <font-awesome-icon
                                v-if="cardTemplate.id==selectedTemplate.id"
                                icon="check-circle"/>
                        </transition>
                        <img v-if="cardTemplate.attributes.screenshot"
                             :src="cardTemplate.attributes.screenshot._url" />
                        <h3 class="font-bebas-neue"><span><font-awesome-icon icon="star"/>{{cardTemplate.attributes.name}}</span></h3>
                    </div>

                    <div v-for="(cardTemplate,index) in availableCards"
                         class="wrapper-phone iphone template"
                         :class="cardTemplate.id==selectedTemplate.id ? 'picked' : ''"
                         @click="selectCard(cardTemplate.attributes.nice_name, cardTemplate)"
                         v-bind:key="`card-`+index">
                        <transition enter-active-class="animated fadeIn"
                                    leave-active-class="animated fadeOut"
                                    mode="out-in">
                            <font-awesome-icon
                                v-if="cardTemplate.id==selectedTemplate.id"
                                icon="check-circle"/>
                        </transition>
                        <img v-if="cardTemplate.attributes.screenshot"
                             :src="cardTemplate.attributes.screenshot._url" />
                        <h3 class="font-bebas-neue"><span>{{cardTemplate.attributes.name}}</span></h3>
                    </div>


                </div>
        </b-sidebar>

        <b-modal id="confirmDesignReady"
                 :title="$ml.get('buttons.DESIGN_READY')"
                 :ok-variant="'secondary'"
                 :cancel-variant="'dark'"
                 @ok="markDesignReady">
            <div class="text-center">
                <h5>Primary design</h5>
            </div>

            <div class="dropzone-holder dropzone-primary mb-3">
                <vue-dropzone ref="primaryDesign"
                              id="dropzone-primary"
                              :options="dropzoneOptions"

                >
                </vue-dropzone>
            </div>
            <div class="text-center">
                <h5>Additional variants</h5>
            </div>
            <div class="dropzone-holder dropzone-variants mb-3">
                <vue-dropzone ref="variantDesigns"
                              id="dropzone-variants"
                              :options="dropzoneVariantOptions"
                >
                </vue-dropzone>
            </div>


            <b-row>
                <b-col cols="6">
                    <b-form-checkbox v-model="sendSMS">Send welcome SMS</b-form-checkbox>
                </b-col>

                <b-col cols="6">
                    <b-form-input v-model="sendPhone" :readonly="!sendSMS"></b-form-input>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Add notification message">
                        <b-form-textarea v-model="additionalNote" :placeholder="$ml.get('forms.cardDesign.design_notes_placeholder')"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>


            <div class="ecb-attachments-holder">
                <div v-for="oneAttachment in attachments" :key="oneAttachment.url">
                    <b-img :src="oneAttachment.url" fluid thumbnail></b-img>
                </div>
            </div>

        </b-modal>

    </div>
</template>

<script>
    import Select2 from 'v-select2-component';
    import Parse from 'parse';
    import Api from "@/store/services/Api";

    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'


    //import dedent from 'dedent'
    import { codemirror } from 'vue-codemirror'

    // // require styles
    import 'codemirror/lib/codemirror.css'

    // language
    //import 'codemirror/mode/css/css.js'

    // theme css
    import 'codemirror/theme/cobalt.css'

    // require active-line.js
    import'codemirror/addon/selection/active-line.js'

    export default {
        name: "DashboardCardDesign",
        components: {
            Select2,
            codemirror,
            vueDropzone: vue2Dropzone
        },

        data() {
            return {
                sendPhone: '',
                sendSMS: true,


                dropzoneOptions: {
                    url: 'https://example.com/dummy-url',
                    thumbnailWidth: 150,
                    thumbnailHeight: 280,
                    addRemoveLinks: true,
                    autoProcessQueue: false,
                    dictDefaultMessage: this.$ml.get('forms.cardDesign.dropzone_placeholder_main'),
                    maxFiles: 1,
                    acceptedFiles: 'image/*',
                },

                dropzoneVariantOptions: {
                    url: 'https://example.com/dummy-url',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 140,
                    thumbnailHeight: 140,
                    maxFiles: 3,
                    acceptedFiles: 'image/*',
                    dictDefaultMessage: this.$ml.get('forms.cardDesign.dropzone_placeholder_additional'),
                },

                attachments: [],
                additionalNote: '',
                confirmReady: false,
                code: '.css { color: red; }',
                cmOptions: {
                    tabSize: 3,
                    styleActiveLine: true,
                    lineNumbers: true,
                    line: true,
                    mode: 'css',
                    theme: 'cobalt'
                },

                cmJSOptions: {
                    tabSize: 3,
                    styleActiveLine: true,
                    lineNumbers: true,
                    line: true,
                    mode: 'js',
                    theme: 'cobalt'
                },

                revertDisabled: true,
                revertClicked: false,

                defaultsClicked: 0,
                useDefaults: 0,

                defaultAppIcon: process.env.VUE_APP_BASE_API_URL+'/img/icon.png',

                currentTemplate: '00_blank',

                cardStack: [],
                hiddenCardStack: [],

                /* DATA FOR THE FORM */
                form: {},
                images: {
                    appIcon: {},
                    imgLogo: null,
                    imgBgTop: null,
                    imgBgFull: null,
                },
                imgFiles: {
                    appIcon: null,
                    imgLogo: null,
                    imgBgTop: null,
                    imgBgFull: null,
                },

                //TRANSITION PARAMS
                loading: false,
                timeout: null,
                isPreview: false,
                frameVisible: true,

                apiUrl: process.env.VUE_APP_BASE_API_URL,
                selectedTemplateId: this.$store.state.selectedTemplate.id

            };

        },
        mounted() {
            this.prepareCardData();
            this.$store.dispatch('getFontFamilies');
        },
        methods: {
            fetchSMSNumber(company){
                this.$store.dispatch('getFirstCardForClient', company.id).then((card) => {
                    this.sendPhone = card.attributes.mobilePhone;
                }).finally(()=>{
                    this.$store.commit('setGlobalLoader', false);
                });
            },

            properLink(link){
               if(link && link.includes('https')) {
                   return link;
               }else if(link && link.includes('http')){
                   return link.replace('http', 'https');
               }else {
                   return 'https://'+link;
               }
            },

            markDesignReady() {
                // Get the files from both primary and variant dropzones
                const primaryFiles = this.$refs.primaryDesign.dropzone.getAcceptedFiles();
                const variantFiles = this.$refs.variantDesigns.dropzone.getAcceptedFiles();

                // Process files and collect all promises
                const primaryUploadPromises = this.processFiles(primaryFiles, 'Primary');
                const variantUploadPromises = this.processFiles(variantFiles, 'Variant');

                // Wait for all uploads to complete using Promise.all
                Promise.all([...primaryUploadPromises, ...variantUploadPromises])
                    .then(() => {
                        console.log('All files uploaded successfully.');

                        // Trigger the API call to send the notification email
                        this.sendDesignReadyEmail();
                    })
                    .catch(error => {
                        console.error('Error uploading files:', error);
                    });
            },

            processFiles(files, type) {
                // Map through files and return an array of promises for each file upload
                return files.map(file => {
                    return new Promise((resolve, reject) => {
                        const DesignVariant = Parse.Object.extend('DesignVariant');
                        const newVariant = new DesignVariant();

                        // Use Parse.File to store the file
                        const parseFile = new Parse.File(file.name, file);

                        // Set attributes for the DesignVariant object
                        newVariant.set('attachment', parseFile);  // Assuming the field is 'designFile'
                        newVariant.set('designType', type);       // Example field to distinguish primary/variant
                        newVariant.set('relatedCompany', this.cardCompany);  // Link to the current card

                        // Save the DesignVariant object and resolve/reject the promise
                        newVariant.save()
                            .then(savedVariant => {
                                console.log(`${type} Design variant saved successfully:`, savedVariant);
                                resolve(savedVariant);
                            })
                            .catch(error => {
                                console.error(`Error saving ${type} design variant:`, error);
                                reject(error);
                            });
                    });
                });
            },

            sendDesignReadyEmail() {
                // Prepare the payload for the API call
                let formData = new FormData();
                formData.append('action', 'sendDesignReadyEmail');

                let payload = {
                    compId: this.cardCompany.id,  // Include the company ID in the payload
                    additionalNote: this.additionalNote,
                    senderId: this.$store.state.user.id,
                };

                if(this.sendSMS){
                    payload.phone = this.sendPhone;
                }

                formData.append('data', JSON.stringify(payload));
                this.$store.commit('setGlobalLoader', true);

                // Make the API call to send the email notification
                Api().post('helpers/api.php', formData)
                    .then(response => {
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_saved'),
                        });

                        this.$store.dispatch('refreshCardCompany').then(()=>{
                            console.log("Refreshed")
                        });
                        //this.prepareCardData();
                        console.log('Design ready email sent successfully:', response);
                    })
                    .catch(error => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            text: this.$ml.get('notification_error_general'),
                        });
                        console.error('Error sending design ready email:', error);
                    }).finally(()=>{

                        this.$store.commit('setGlobalLoader', false);

                    });
            },

            clearDropzone(zone){
                this.images[zone] = {};
                this.imgFiles[zone] = null;
            },
            submitForm() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.$store.dispatch('saveCardConfig', {
                        id: this.id,
                        form: this.form,
                        imgFiles: this.imgFiles,
                        templateId: this.selectedTemplateId,
                        designVer: self.$store.state.cardConfig.attributes.designVer
                    }).then(() => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'success',
                            text: this.$ml.get('notification_success_saved'),
                        });
                    }).catch((e) => {
                        this.loading = false;
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e);
                    });
                }
            },
            prepareCardData(){
                let self = this;

                let allPromises = [
                    self.$store.dispatch('getCardTemplates'),
                    self.$store.dispatch('getCardConfig'),
                ];
                Promise.all(allPromises)
                    .then(function(e){
                        self.revertDisabled = true;
                        self.revertClicked = true;
                        let curCompany = self.$store.state.cardCompany;
                        let userCompany = self.$store.getters.getUserCompany;
                        self.fetchSMSNumber(curCompany);
                        self.setupCards(e[0], e[1], userCompany);
                        if(e[1].attributes.template){
                            self.currentTemplate = e[1].attributes.template.attributes.nice_name;
                            self.$store.commit('setActiveTemplate', e[1].attributes.template);
                            self.useDefaults = 0;
                        }
                        if(e[1].attributes.appIcon){
                            self.images.appIcon = e[1].attributes.appIcon;
                        } else if(curCompany.attributes.logo){
                            self.images.appIcon = curCompany.attributes.logo;
                        }else {
                            self.images.appIcon = {};
                        }
                    })
                    .catch(function(err){
                        self.$checkTokenExp(err);
                    });

            },
            setupCards(allCards, cardConfig, currComp) {
                let filteredCards = [];
                let hiddenCards = [];

                let self = this;

                if(currComp){

                    if( currComp.attributes.isAgency && currComp.attributes.isAgency == 1 || self.isAdminUser){

                        if(allCards.length > 0){
                            allCards.forEach(function(item){

                                if(!item.attributes.hidden || (item.attributes.hidden && item.attributes.allowedFor.includes(currComp.id)) ){
                                    filteredCards.push(item);
                                } else if(item.attributes.hidden && self.isAdminUser) {
                                    hiddenCards.push(item);
                                }
                            });
                        }

                    } else {
                        //Regular companies with users that can edit design but will see just their current design
                        if(allCards.length > 0 && cardConfig) {
                            allCards.forEach(function (item) {
                                if (item.id == cardConfig.attributes.template.id) {
                                    filteredCards.push(item);
                                }
                            });
                        }

                    }
                }

                this.cardStack = filteredCards;
                this.hiddenCardStack = hiddenCards;
            },

            selectCard(templateName, templateObject){
                if(this.cardConfig){
                    if(templateName == this.cardConfig.attributes.template.attributes.nice_name){
                        this.useDefaults = 0;
                    }
                    this.$store.commit('setActiveTemplate', templateObject);
                    this.selectedTemplateId = templateObject.id;
                    this.currentTemplate = templateName;

                    this.isPreview = !this.isPreview;
                    this.revertClicked = false;

                    //this.frameVisible = true;
                }
            },
            applyDefaults(){
                let theDefaults = this.originalParams;
                this.form = { ...theDefaults };
                this.defaultsClicked = 1;
                this.revertDisabled = false;
            },
            refreshPreview(fast) {
                //console.log("Submitting: ", this.form);

                clearTimeout(this.timeout);
                if(fast){
                    this.$refs.cardPreview.submit();
                    this.defaultsClicked = 0;
                }else{
                    this.timeout = setTimeout(() => {
                        if(this.$refs.cardPreview)
                        this.$refs.cardPreview.submit();
                        this.defaultsClicked = 0;
                    }, 300);
                }
            },
            revertSettings(){
                this.$store.commit('setActiveTemplate', this.initialTemplate);
                this.revertClicked = true;
                this.form = { ...this.curSettings };

                this.defaultsClicked = 0;
            }
        },
        computed: {
            designNotReady(){
                return this.cardCompany && this.cardCompany.attributes.status === '01_new';
            },
            isCore(){
                if(this.$store.state.userCompany && this.$store.state.userCompany.attributes){
                    return this.$store.state.userCompany.attributes.core;
                }else {
                    return false;
                }
            },
            hasCodemirror() {
                //console.log("hasCodemirror: ", this.fieldSet);
                return this.isAdminUser && this.fieldSet.some(param => param.field === 'customCSS');
            },
            hasCustomSVG() {
                if(!this.fieldSet) return false;
                return this.isAdmin && this.fieldSet.some(param => param.field === 'customSVG');
            },
            isAdminUser(){
                let curUser = Parse.User.current();
                return curUser.attributes.isAdmin;
            },

            //TEMPLATE
            selectedTemplate(){ //template committed to store
                return this.$store.state.selectedTemplate;
            },
            selectedTemplateSlug(){
                return this.$store.state.selectedTemplate.attributes.nice_name;
            },
            fieldSet() { //fields for currently selected template
                return this.$store.state.selectedTemplate.attributes.availableParams;
            },

            //CURRENT CLIENT
            cardCompany(){
                return this.$store.state.cardCompany;
            },

            //CARD CONFIG
            cardConfig() { //config of currently selected client
                return this.$store.state.cardConfig;
            },
            originalParams(){
                let defaults = {}
                let self = this;
                if(this.fieldSet){
                    defaults.logo = this.form.logo.slice();
                    this.fieldSet.forEach(function(oneField){
                        switch (oneField.field) {
                            case "imgLogo":
                                defaults.imgLogo = self.images.imgLogo;
                                break;

                            case "imgBgFull":
                            case "imgBgTop":
                                defaults[oneField.field] = null;
                                self.images[oneField.field] = null;
                                self.imgFiles[oneField.field] = null;
                                break;
                            //
                            // case "imgBgTop":
                            //     defaults[oneField.field] = null;
                            //     self.images[oneField.field] = null;
                            //     self.imgFiles[oneField.field] = null;
                            //     break;
                            case "FontFamily":
                                defaults.relatedFont = oneField.defaultValue;
                                break;

                            case "Separator":
                                break;

                            default:
                                defaults[oneField.field] =  oneField.defaultValue;
                                break;
                        }
                    });
                }
                return defaults;
            },

            //CARD STACK
            availableCards() {
                return this.cardStack;
            },

            selectFonts(){
                let selectData = [];
                if(this.allFontFamilies){
                    this.allFontFamilies.forEach(function(oneFont){
                        let oneLine = {
                            id: oneFont.id,
                            text:  oneFont.attributes.name,
                            fullData: oneFont.attributes,
                        }
                        selectData.push(oneLine);
                    });
                }
                return selectData;
            },
            allFontFamilies() {
                return this.$store.state.fontFamilies;
            },
        },
        watch: {
            cardCompany: {
                handler(newVal, oldVal) {
                    if(newVal.id !== oldVal.id){
                        this.curSettings = null;
                        this.prepareCardData();
                    }
                },
            },
            form: {
                handler(newVal) {
                    this.refreshPreview();
                    //Form loading new values, check if promise resolved
                    if(newVal.sizeLogo) {
                        //Form changed because of external factor -> allow revert
                        if(this.revertClicked){
                            this.revertDisabled = true;
                            this.revertClicked = false;
                        } else {
                            this.revertDisabled = false;
                        }

                    }

                },
                deep: true,
            },
            images: {
                handler(data) {

                    if(data && data.DropzoneField){
                        this.imgFiles[data.DropzoneField.field] = data.DropzoneField;
                        this.images[data.DropzoneField.field]._url = data.DropzoneField.url
                        this.defaultsClicked = 0;
                        this.refreshPreview();
                    }
                },
                deep: true,
            },
            fieldSet: {
                deep: true,
                handler(newVal){
                    let self = this;
                    let selectedTemplate = this.$store.state.selectedTemplate;
                    let formFields = {};
                    if(newVal){
                        newVal.forEach(function(oneField){
                            switch (oneField.field) {
                                case "imgLogo":
                                    self.images.imgLogo = self.cardConfig.attributes.imgLogo ? self.cardConfig.attributes.imgLogo : selectedTemplate.attributes.imgLogo;
                                    break;

                                case "imgBgTop":
                                    self.images.imgBgTop = self.cardConfig.attributes.imgBgTop ? self.cardConfig.attributes.imgBgTop :  {_url: null};
                                    break;

                                case "imgBgFull":
                                    self.images.imgBgFull = self.cardConfig.attributes.imgBgFull ? self.cardConfig.attributes.imgBgFull : {_url: null};
                                    break;

                                case "relatedFont":
                                    if(self.cardConfig.attributes.relatedFont){
                                      formFields.relatedFont = self.cardConfig.attributes.relatedFont.id;
                                    }
                                    break;

                                case "Separator":
                                    break;

                                default:
                                    if(self.$store.state.dashboardModalComponent.data && self.$store.state.dashboardModalComponent.data.useDef){
                                        formFields[oneField.field] =  oneField.defaultValue;
                                    }else{
                                        formFields[oneField.field] =  self.cardConfig.attributes[oneField.field] ? self.cardConfig.attributes[oneField.field]: oneField.defaultValue;
                                    }

                            }
                        });
                    }

                    this.form = formFields;
                    this.form.logo = this.cardCompany ? this.cardCompany.attributes.logo._url : null;

                    if(!this.curSettings){
                        this.curSettings = {...formFields};
                        this.initialTemplate = Object.assign({}, selectedTemplate);
                        this.initialTemplate.attributes = selectedTemplate.attributes;
                    }

                }
            }
        }
    }
</script>

<style scoped>

.qf-label-clone {
    position: relative;
    display: block;
    min-height: auto;
    top: 6px;
}

.flex-gap {
    column-gap: 10px;
}

.min-height-label {
    min-height: 35px;
    display: block;
    position: relative;
}

.custom-label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    position: relative;
    display: block;
}

.mirror-wrapper {
    border: 1px solid var(--color-grey);
    font-size: 12px;
}

.wrapper-phone h3 svg {
    color: var(--color-primary);
    margin-right: 7px;
}
.eco-content-scroll{padding: 10px}
.eco-content-space{background: #FFFFFF;padding: 25px;}
.wrapper-phone.template {
    cursor: pointer;
    box-sizing: content-box;
    padding-bottom: 25px;
    width: 108px;
    height: 220px;
    margin: 15px;
    opacity: 0.85;
    transition: all 0.3s ease-out;
    cursor: pointer;
}

.wrapper-phone.template.picked,
.wrapper-phone.template:hover{
    opacity: 1;
}

.wrapper-phone.iphone.template img{
    top: 4px;
    left: 5px;
    width: 195px;
    height: auto;
    border-radius: 20px;
}
.wrapper-phone.iphone.template:after {
    background-size: 108px;
}
.wrapper-phone.template h3 span {
    background: #2b2b30;
    padding: 4px 10px 3px;
    border-radius: 50px;
    color: #fff;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}
.wrapper-phone.template h3 {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: 0;
    font-size: 13px;
    display: flex;
    justify-content: center;
}
.cards-grid{
    display: flex;
    flex-wrap: wrap;
}
.card-designs {
    transition: all .3s ease;
}
.right-modal + .eco-content-container .card-designs{
    margin: 0 30vw 0 auto;
}
/*
.cards-grid:not(.active),
.cards-grid.active ~ .card-designs{
    display: none;
}
*/
.wrapper-phone {
    width: 432px;
    height: 878px;
    overflow: hidden;
    position: relative;
    box-sizing: content-box;
    width: 216px;
    height: 439px;
    margin: 0 auto;
}
.iphone:after {
    content: '';
    background-image: url('/img/iphone-wrapper.png');
    background-repeat: no-repeat;
    background-size: 216px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
}
.iphone.template::after {
    pointer-events: auto;
}
.iphone > iframe,
.iphone img{
    position: relative;
    transform: scale(0.5);
    transform-origin: 0 0;
    box-sizing: content-box;
    top: 12px;
    left: 14px;
    width: 376px;
    height: 815px;
    /*background: #000;*/
}
.sc06.wrapper-phone{
    width: 262px;
    height: 536px;
}
.sc06.iphone:after{
    background-size: 260px;
}
.sc06.iphone > iframe,
.sc06.iphone img{
    transform: scale(0.6);
    top: 11px;
    left: 14px;
    width: 386px;
    height: 836px;
}
.template > svg {
    position: absolute;
    z-index: 2;
    font-size: 48px;
    color: var(--color-secondary);
    top: calc(50% - 24px - 20px);
    right: calc(50% - 24px);
    background: #fff;
    border-radius: 50%;
    border: 3px solid #ffffff;
    display: block;
}
.iphone::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 3px solid #efa633;
    border-right: 3px solid transparent;
    animation: spinner .6s linear infinite;
    z-index: 0;
}
@keyframes spinner {
    to {
        transform: rotate(360deg)
    }
}
.button-group-wrapper {
    flex-wrap: wrap;
    width: 100%;
}

.button-group-wrapper > div,
.button-group-wrapper button {
    width: 100%;
}

.button-group {
    display: flex;
    margin-top: 10px;
}
</style>

<style>

.dropzone-holder {
    margin: 0 auto;
}

.dropzone-holder .dropzone {
    padding: 5px;
    border: 2px dashed var(--color-grey);
}

.dropzone-holder .dropzone .dz-preview {
    margin: 5px;
}


.dropzone-primary {
    width: 195px;
    height: 300px;
}

.dropzone-primary .dropzone {
    height: 100%;
}

.dropzone-holder .dz-preview .dz-error-message {
    top: 0;
}


.cm-s-cobalt.CodeMirror,
.cm-s-cobalt .CodeMirror-gutters {
    background: #2b2b30 !important;
}

#template-selector .b-sidebar-header {
    background: #0b0c0e;
    color: white;
    text-align: center;
}

#template-selector .b-sidebar-header strong {
    margin-top: 3px;
    font-size: 15px;
}

#template-selector .b-sidebar-header .close {
    margin-right: 20px;
    opacity: 1;
}
#template-selector .b-sidebar-header .close svg {
    fill: var(--color-primary) !important;
}
</style>
