<template>
    <label :for="id"
           class="checkbox-field font-bebas-neue"
           :class="labelClass">
        <input type="checkbox"
               :id="id"
               :name="name"
               :value="value"
               :disabled="disabled"
               v-model="realValue">
        <span class="checkbox-tick"><font-awesome-icon icon="check"/></span>
        <slot></slot> <span v-if="required" class="asterix">*</span>
        <span class="form-error font-bebas-neue">{{error}}</span>
    </label>
</template>

<script>
    export default {
        name: "CheckboxField",
        props: {
            name: String,
            error: {
                type: String,
                default: ''
            },
            id: String,
            value: Boolean,
            color: {
                type: String,
                default: ''
            },
            required: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            labelClass() {
                if (this.error.length) {
                    return 'color-error'
                }

                switch (this.color) {
                    case 'primary':
                        return 'color-primary';
                    case 'secondary':
                        return 'color-secondary';
                    case 'error':
                        return 'color-error';
                    default:
                        return '';
                }
            },
            realValue: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    this.$emit('input', newValue);
                }
            }
        },
        methods: {
            internalValidator(value) {
                let result = true;
                if (this.required && !value) {
                    result = false;
                }
                return result;
            },
            updateValue(value) {
                this.$emit('input', value)
                this.$emit('validation-result', {'name': this.name, 'valid': this.internalValidator(value)});
            },
        }
    }
</script>

<style scoped>
    .checkbox-field {
        position: relative;
        cursor: pointer;
        padding-left: 25px;
        text-transform: uppercase;
        color: var(--color-dark);
        /* transition: all 0.25s ease-in-out; */
    }

    .checkbox-field:focus,
    .checkbox-field:hover {
        color: var(--color-secondary);
    }

    .checkbox-field > input[type=checkbox] {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }

    .checkbox-tick {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        border: 1px solid var(--color-grey);
        border-radius: 5px;
        font-size: 80%;
        text-align: center;
        padding-top: 1px;
        color: var(--color-white);
        /* transition: all 0.25s ease-in-out; */
    }

    .checkbox-field:focus > .checkbox-tick,
    .checkbox-field:hover > .checkbox-tick {
        border: 1px solid var(--color-dark);
    }

    .checkbox-tick > svg {
        opacity: 0;
        /* transition: all 0.25s ease-in-out; */
    }

    .checkbox-field > input[type=checkbox]:checked ~ .checkbox-tick {
        background: var(--color-grey);
    }

    .checkbox-field:focus > input[type=checkbox]:checked ~ .checkbox-tick,
    .checkbox-field:hover > input[type=checkbox]:checked ~ .checkbox-tick {
        background: var(--color-dark);
    }

    .checkbox-field > input[type=checkbox]:checked ~ .checkbox-tick > svg {
        opacity: 1;
    }


    /*.checkbox-field.color-primary {*/
    /*    color: var(--color-primary);*/
    /*}*/

    /*.checkbox-field.color-primary:focus,*/
    /*.checkbox-field.color-primary:hover {*/
    /*    color: var(--color-primary-dark);*/
    /*}*/

    .checkbox-field.color-primary > .checkbox-tick {
        border: 1px solid var(--color-primary);
    }

    .checkbox-field.color-primary:focus > .checkbox-tick,
    .checkbox-field.color-primary:hover > .checkbox-tick {
        border: 1px solid var(--color-primary-dark);
    }

    .checkbox-field.color-primary > input[type=checkbox]:checked ~ .checkbox-tick {
        background: var(--color-primary);
    }

    .checkbox-field.color-primary:focus > input[type=checkbox]:checked ~ .checkbox-tick,
    .checkbox-field.color-primary:hover > input[type=checkbox]:checked ~ .checkbox-tick {
        background: var(--color-primary-dark);
    }


    /*.checkbox-field.color-secondary {*/
    /*    color: var(--color-secondary);*/
    /*}*/

    /*.checkbox-field.color-secondary:focus,*/
    /*.checkbox-field.color-secondary:hover {*/
    /*    color: var(--color-secondary-dark);*/
    /*}*/

    .checkbox-field.color-secondary > .checkbox-tick {
        border: 1px solid var(--color-secondary);
    }

    .checkbox-field.color-secondary:focus > .checkbox-tick,
    .checkbox-field.color-secondary:hover > .checkbox-tick {
        border: 1px solid var(--color-secondary-dark);
    }

    .checkbox-field.color-secondary > input[type=checkbox]:checked ~ .checkbox-tick {
        background: var(--color-secondary);
    }

    .checkbox-field.color-secondary:focus > input[type=checkbox]:checked ~ .checkbox-tick,
    .checkbox-field.color-secondary:hover > input[type=checkbox]:checked ~ .checkbox-tick {
        background: var(--color-secondary-dark);
    }

    /*.checkbox-field.color-error {*/
    /*    color: var(--color-error);*/
    /*}*/

    /*.checkbox-field.color-error:focus,*/
    /*.checkbox-field.color-error:hover {*/
    /*    color: var(--color-error-dark);*/
    /*}*/

    .checkbox-field.color-error > .checkbox-tick {
        border: 1px solid var(--color-error);
    }

    .checkbox-field.color-error:focus > .checkbox-tick,
    .checkbox-field.color-error:hover > .checkbox-tick {
        border: 1px solid var(--color-error-dark);
    }

    .checkbox-field.color-error > input[type=checkbox]:checked ~ .checkbox-tick {
        background: var(--color-error);
    }

    .checkbox-field.color-error:focus > input[type=checkbox]:checked ~ .checkbox-tick,
    .checkbox-field.color-error:hover > input[type=checkbox]:checked ~ .checkbox-tick {
        background: var(--color-error-dark);
    }
    .checkbox-field:has(input[disabled]) {
        filter: grayscale(1);
        opacity: .5;
        cursor: no-drop;
    }
</style>
