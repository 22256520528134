<template>
    <form @submit.prevent="submitForm">
        <h3 class="text-center">
            {{(id===null)?$ml.get('ADD_NEW_EMPLOYEE_CARDS'):$ml.get('EDIT_EMPLOYEE_CARDS')}}
        </h3>

        <div class="dz-profile-pic">
            <span class="more-info" v-tooltip="$ml.get('tooltip_profile_pic')">
                <font-awesome-icon icon="info-circle"/>
            </span>
            <dropzone-field name="profilePicture"
                            id="profilePicture"
                            :accept="`image/*`"
                            :required="false"
                            v-model="fileTrigger">
                <img :src="images.profilePicture" v-if="images.profilePicture"/>
                <template v-else>
                    <div v-html="$ml.get('profilePictureDropzone')"></div>
                </template>
            </dropzone-field>
            <hr/>

        </div>

        <div>
            <input-field :id="`first-name`"
                         :name="'first-name'"
                         :value="form.firstName"
                         :required="true"
                         @validation-result="validationCollector"
                         v-model="form.firstName">{{$ml.get('FIRST_NAME')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`last-name`"
                         :name="'last-name'"
                         :value="form.lastName"
                         :required="true"
                         @validation-result="validationCollector"
                         v-model="form.lastName">{{$ml.get('LAST_NAME')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`position`"
                         :name="'position'"
                         :value="form.position"
                         :required="true"
                         @validation-result="validationCollector"
                         v-model="form.position">{{$ml.get('POSITION')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`department`"
                         :name="'department'"
                         :value="form.department"
                         v-model="form.department">{{$ml.get('DEPARTMENT')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`email`"
                         :name="'email'"
                         :value="form.email"
                         :required="true"
                         @validation-result="validationCollector"
                         v-model="form.email">{{cardConfig.attributes.labelEmail}}
            </input-field>
        </div>
        <div>
            <input-field :id="`phone`"
                         :name="'phone'"
                         :value="form.mobilePhone"
                         v-model="form.mobilePhone">{{cardConfig.attributes.labelPhone1}}
            </input-field>
        </div>
        <div>
            <input-field :id="`office-phone`"
                         :name="`office-phone`"
                         :value="form.officePhone"
                         v-model="form.officePhone">{{cardConfig.attributes.labelPhone2}}
            </input-field>
        </div>

        <div>
            <input-field :id="`linkedIn`"
                         :name="'linkedIn'"
                         :value="form.socialLinkedIn"
                         v-model="form.socialLinkedIn">{{$ml.get('linked_in')}}
            </input-field>
        </div>
        <div>
            <input-field :id="`twitter`"
                         :name="'twitter'"
                         :value="form.socialPersonalTwitter"
                         v-model="form.socialPersonalTwitter">{{$ml.get('twitter_link')}}
            </input-field>
        </div>
        <div class="row">
            <div class="col">
                <select-field
                         :id="`officeAddress`"
                         :name="`officeAddress`"
                         :value="form.officeAddress.id"
                         :options="form.select.options"
                         :required="true"
                         @validation-result="validationCollector"
                         v-model="form.officeAddress.id">{{cardConfig.attributes.labelAddress}}
                </select-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-component">
                  <label class="form-label">{{$ml.get('NOTES')}}</label>
                  <textarea id="notes" name="notes" class="form-control" v-model="form.notes" rows="3" placeholder="Notes"></textarea>
                </div>
            </div>
        </div>

        <div>
            <!--<checkbox-field color="secondary"-->
                            <!--:value="form.customAddress"-->
                            <!--v-model="form.customAddress"-->
            <!--&gt;{{$ml.get('USE_CUSTOM_ADDRESS')}}-->
            <!--</checkbox-field>-->
        </div>
        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="secondary"
                              :icon="(id===null)?'plus':'save'"
                              type="submit"
                              :disabled="isFormDisabled"
                              block>{{(id===null)?$ml.get('ADD'):$ml.get('SAVE')}}
                </button-field>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "DashboardEmployeeCardsForm",
        computed: {
            id() {
                return this.$store.state.dashboardModalComponent.data.id
            },
            selectedEmployee() {
                return this.$store.getters.selectedModalData('employeeCards');
            },
            cardConfig() {
                return this.$store.state.cardConfig;
            },
            addresses() {
                let addresses = [];
                if(this.$store.state.officeAddresses){
                    this.$store.state.officeAddresses.forEach((addr) => {
                        let element = {
                            value: addr.id,
                            text: addr.attributes.officeLabel
                        };
                        addresses.push(element);
                    })
                }
                return addresses;
            },
            isFormDisabled(){
                return this.errors.length > 0;
            }
        },
        data() {

            let initialAttr = this.$store.getters.selectedModalData('employeeCards');
            return {
                fileTrigger: {},
                images: {
                    profilePicture: initialAttr && initialAttr.attributes.profilePicture ? initialAttr.attributes.profilePicture._url : ''
                },
                imgFiles: {
                    profilePicture: null
                },
                form: {
                    firstName: initialAttr ? initialAttr.attributes.firstName : '',
                    lastName:  initialAttr ? initialAttr.attributes.lastName : '',
                    position:  initialAttr ? initialAttr.attributes.position : '',
                    department:  initialAttr ? initialAttr.attributes.department : '',
                    mobilePhone: initialAttr ? initialAttr.attributes.mobilePhone : '',
                    officePhone: initialAttr ? initialAttr.attributes.officePhone : '',
                    email: initialAttr ? initialAttr.attributes.email : '',
                    address: initialAttr ? initialAttr.attributes.address : '',
                    socialLinkedIn: initialAttr ? initialAttr.attributes.socialLinkedIn : '',
                    socialPersonalTwitter: initialAttr ? initialAttr.attributes.socialPersonalTwitter : '',
                    customAddress: false,
                    cardVer: initialAttr ? initialAttr.attributes.cardVer : 0,
                    officeAddress: {
                        id: initialAttr ? initialAttr.attributes.officeAddress.id : ''
                    },
                    notes: initialAttr ? initialAttr.attributes.notes : '',
                    randString: '',
                    select: {
                        selectError: '',
                        options: [
                            {
                                value: 'initState',
                                text: '',
                                disabled: true
                            }
                        ],
                        value: null
                    }
                },
                loading: false,
                errors: []
            }
        },
        mounted() {
            this.$store.dispatch('getCardConfig');
            this.form.select.options = this.addresses;

            if (this.selectedEmployee !== null) {
                this.form = {...this.form, ...this.selectedEmployee.attributes}
                this.form.select.value = this.form.officeAddress.id;
            }

            //Set default address if new item
            if(!this.form.select.value) {
                this.form.select.value = this.form.select.options[0].value;
                this.form.officeAddress.id = this.form.select.options[0].value;
            }
        },
        methods: {
            validationCollector(inputValidation){
                Array.prototype.remove = function() {
                    var what, a = arguments, L = a.length, ax;
                    while (L && this.length) {
                        what = a[--L];
                        while ((ax = this.indexOf(what)) !== -1) {
                            this.splice(ax, 1);
                        }
                    }
                    return this;
                };

                if(inputValidation.valid){
                    this.errors.remove(inputValidation.name);
                }else {
                    if(this.errors.indexOf(inputValidation.name) === -1){
                        this.errors.push(inputValidation.name);
                    }
                }
            },

            generateRandomString(length) {
                let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
                let result = '';
                for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
                return result;
            },

            submitForm() {
                let self = this;
                if (!this.loading) {
                    this.loading = true;
                    this.form.randString = this.generateRandomString(20);
                    this.form.imgFiles = this.imgFiles;
                    this.$store.dispatch('saveEmployeeCards', {id: this.id, ...this.form}).then(() => {
                        this.$store.dispatch('getEmployeeCards').then(() => {
                            this.loading = false;
                            this.$notify({
                                group: 'notification',
                                type: 'success',
                                text: this.$ml.get('notification_success_saved'),
                            });
                            this.$store.commit('toggleDashboardModal');
                        });
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e);
                        this.loading = false;
                    });
                }
            }
        },
        watch: {
            fileTrigger: {
                handler(newVal) {
                    if(newVal){
                        this.imgFiles[newVal.field] = newVal.file;
                        this.images[newVal.field] = newVal.url;
                        this.defaultsClicked = 0;
                        //this.refreshPreview();
                    }
                },
                deep: true,
            },
        }
    }
</script>

<style scoped>
.dz-profile-pic {
    position: relative;
    width: 50%;
    margin: 0 auto;
}
</style>
