<template>
    <div class="gdpr-notice-wrapper" v-if="gdprNoticeOpen && showNotice">
        <div class="gdpr-notice-box">

            <b-card>
                <div class="notice-info text-center pb-2">
                    <p>To continue using Qfecard, current GDPR regulations require that a representative of your company reviews, accepts and agrees with our privacy policy.</p>
                    <p>You may find a copy of our policy on the link below.</p>
                    <b-link :href="termsLink" target="_blank"><strong>Qfecard Privacy Policy</strong></b-link>

                    <b-form-checkbox size="lg" v-model="okEnabled" name="gdprAccepted" required class="mt-3">
                        I have read and agree with the privacy policy
                    </b-form-checkbox>
                    <hr/>
                </div>
                <div class="gdpr-notice-controls text-center">
                    <b-button @click="logout" variant="outline-dark" class="mr-4">Disagree and log-out</b-button>
                    <b-button @click="confirmAndContinue" :disabled="!okEnabled" variant="secondary">Confirm and Continue</b-button>
                </div>
            </b-card>

        </div>

    </div>

</template>

<script>
import Parse from "parse";
export default {
    name: 'gdpr-notice',
    data() {
        return {
            gdprNoticeOpen: true,
            termsLink: '',
            okEnabled: false,
            lang: 'en',
        }
    },
    created() {
        this.fetchTermsDocument();
    },
    computed: {
        curUser() {
            return this.$store.state.user;
        },
        userCompany() {
            return this.$store.state.userCompany;
        },
        hasAgreed() {
            return this.userCompany && this.userCompany.attributes.gdprAcceptedOn ? true : false;
        },
        showNotice() {
            if (this.curUser && this.userCompany && !this.hasAgreed) {
                return true;
            } else {
                return false;
            }
        },
        country() {
            return this.userCompany ? this.userCompany.attributes.address_country : null;
        },
    },
    methods: {
        confirmAndContinue(){
            this.gdprNoticeOpen = false;
            this.$store.state.userCompany.set('gdprAcceptedOn', new Date());
            this.$store.state.userCompany.save().then(() => {
                console.log('GDPR accepted');
            }).catch((error) => {
                console.error('Error while saving GDPR acceptance', error);
            });
        },
        fetchTermsDocument(lang = 'en') {
            let query = new Parse.Query('ProtectedDoc');
            query.equalTo('type', 'privacy_policy');
            query.equalTo('language', lang);
            query.descending('revision');
            query.first().then((doc) => {
                if (doc) {
                    this.termsLink = doc.attributes.attachment.url();
                }
            }).catch((error) => {
                console.error('Error while fetching terms document', error);
            });
        },
        logout() {
            this.$store.dispatch('logout').then(() => {
                this.$store.commit('setIsAuthenticated', false);
                this.$store.commit('setUser', null);
                this.$router.push('/auth');
            });
        },
    },
    watch: {
        country: {
            handler: function (newCountry) {
                if(newCountry){
                    this.addressData.address_country = newCountry;
                    if(newCountry.includes('Bulgaria')){
                        this.lang='bg';
                    }else if (
                        newCountry.includes('Germany') ||
                        newCountry.includes('Deutschland') ||
                        newCountry.includes('Austria') ||
                        newCountry.includes('Switzerland') ||
                        newCountry.includes('Liechtenstein')
                    ){
                        this.lang = 'de';
                    }else {
                        this.lang = 'en';
                    }
                }
            },
        },
        lang(val) {
            this.fetchTermsDocument(val);
        }
    }
}


</script>

<style>

.gdpr-notice-box {
    max-width: 500px;
}

.gdpr-notice-wrapper {
    position: absolute;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background: rgba(30, 30, 30, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
