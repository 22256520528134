<template>
    <div class="ecobcard-app">
        <notifications group="notification"
                       position="top center"
                       class="my-notification"/>
        <gdpr-notice></gdpr-notice>
        <trial-notice></trial-notice>
        <router-view></router-view>
        <global-loader v-if="$store.state.globalLoader"></global-loader>
    </div>
</template>

<style src="./assets/styles/main.css"></style>

<script>
import gdprNotice from './components/global/subscription/GdprNotice.vue';

export default {
    components: {
        gdprNotice
    }
}

</script>
