<template>
    <div class="pt-4">

        <b-row>
            <b-col cols="12" class="text-center mb-2">
                <h1>{{$ml.get('forms.protectedDocs.title')}}</h1>
            </b-col>
            <b-col cols="12">
                <dropzone-field name="attachment"
                                id="attachment"
                                :accept="`application/pdf`"
                                :required="true"
                                v-if="!form.attachment"
                                v-model="localAttachment">
                    <template>
                        <div v-html="$ml.get('forms.protectedDocs.dropzonePlaceholder')"></div>
                    </template>
                </dropzone-field>

                <div v-else>
                    <b-row>
                        <b-col cols="12" class="d-flex justify-content-center mb-4 mt-2">
                            <a :href="form.attachment.url()" target="_blank">{{trimFileName(form.attachment.name())}}</a>
                        </b-col>
                    </b-row>
                </div>

            </b-col>
        </b-row>

        <b-form @submit.prevent="submitForm">
            <b-row>
                <b-col cols="6">
                    <b-form-group :label="$ml.get('forms.protectedDocs.documentName')" class="required">
                        <b-form-input v-model="form.name" />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group :label="$ml.get('forms.protectedDocs.documentRevision')" class="required">
                        <b-form-input v-model="form.revision" type="number"/>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group :label="$ml.get('forms.protectedDocs.documentType')" class="required">
                        <b-form-select v-model="form.type" :options="documentTypes" />
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group :label="$ml.get('forms.protectedDocs.documentLanguage')" class="required">
                        <b-form-select v-model="form.language" :options="languageOptions" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$ml.get('forms.protectedDocs.documentDescription')">
                        <b-form-textarea v-model="form.description" rows="3"/>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" class="d-flex justify-content-center">
                    <div class="w-50">
                        <button-field color="secondary"
                                      :icon="(form.id === null) ? 'plus' : 'save'"
                                      :disabled="isFormDisabled || loading"
                                      :loading="loading"
                                      type="submit"
                                      block>{{(form.id === null) ? $ml.get('ADD') : $ml.get('SAVE')}}
                        </button-field>
                    </div>
                </b-col>
            </b-row>

        </b-form>


    </div>
</template>

<script>

export default {
    data() {
        return {
            documentTypes: [
                {text: 'Subscription terms', value: 'subscribe_terms' },
                {text: 'Privacy Policy', value: 'privacy_policy'},
                {text: 'Terms of Cancellation', value: 'cancellation_terms'},
            ],
            languageOptions: [
                { text: 'English', value: 'en' },
                { text: 'German', value: 'de' },
                { text: 'Bulgarian', value: 'bg' },
                { text: 'French', value: 'fr' },
                { text: 'Spanish', value: 'es' },
            ],
            selectedDocument: {
                id: null,
                attributes: {}
            },
            loading: false,
            isFormDisabled: false,
            localAttachment: null,
            form: {
                id: null,
                name: '',
                attachment: null,
                type: 'subscribe_terms',
                language: 'en',
            }
        }
    },
    mounted() {
        this.selectedDocument = this.$store.state.modalData;
        if(this.selectedDocument.id) {
            this.form = {
                id: this.selectedDocument.id,
                revision: this.selectedDocument.attributes.revision,
                name: this.selectedDocument.attributes.name,
                language: this.selectedDocument.attributes.language ? this.selectedDocument.attributes.language : 'en',
                attachment: this.selectedDocument.attributes.attachment,
                type: this.selectedDocument.attributes.type,
                description: this.selectedDocument.attributes.description,
            }
        }else {
            this.resetForm();
        }
    },
    methods: {
        resetForm() {
            this.form = {
                id: null,
                name: '',
                attachment: null,
                type: 'subscribe_terms',
            }
        },
        submitForm(e) {
            e.preventDefault();
            if(this.localAttachment){
                this.form.newFile = this.localAttachment;
            }
            this.$store.dispatch('saveDocument', this.form).then(() => {
                this.$store.commit('toggleDashboardModal', {name: 'ProtectedDoc_Form', data: {id: null}});
                this.resetForm();
            }).catch((error) => {
                console.error('Error while saving document', error);
            });

        },
        trimFileName(fileName) {
            // Split the filename by underscore
            const parts = fileName.split('_');

            // If there's more than one part, return everything after the first underscore
            // Otherwise, return the original filename
            return parts.length > 1 ? parts.slice(1).join('_') : fileName;
        }
    },
    watch: {
        localAttachment(newVal) {
            let self = this;
            if(newVal && newVal.name){
                this.form.name = newVal.name.replace('.pdf','').replace('_',' ');
                this.languageOptions.forEach(function(language) {
                    if(self.form.name.includes('_'+language.value)) {
                        self.form.name = self.form.name.replace('_'+language.value,'');
                        self.form.language = language.value;
                    }
                });
            }
        }
    },
}

</script>

<style scoped>

</style>
