import Parse from 'parse';

export const MonthlyStatsService = {
    getBillingData(context, payload) {
        let statsQuery = new Parse.Query('MonthlyStats');
        statsQuery.equalTo('year', payload.year);
        statsQuery.equalTo('month', payload.month);
        statsQuery.include('relatedCompany');
        statsQuery.include('relatedInvoice');
        statsQuery.include('relatedCompany.relatedPricing');

        // Create a subquery for the Company class where clientOf equals the given agencyId
        let clientCompanyQuery = new Parse.Query('Company');
        let compPointer = {"__type": "Pointer", "className": "Company", "objectId": payload.agencyId};
        clientCompanyQuery.equalTo('clientOf', compPointer);

        // Create a subquery for the agency company itself
        let agencyCompanyQuery = new Parse.Query('Company');
        agencyCompanyQuery.equalTo('objectId', payload.agencyId);

        // Combine both queries using `or`
        let combinedCompanyQuery = Parse.Query.or(clientCompanyQuery, agencyCompanyQuery);

        // Use the combined query to filter the statsQuery
        statsQuery.matchesQuery('relatedCompany', combinedCompanyQuery);

        statsQuery.ascending('relatedCompany.name'); // or descending as per your requirement

        return new Promise((resolve, reject) => {
            statsQuery
                .find()
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};
