<template>
    <div class="container">
        <form class="auth-form"
              @submit.prevent="submitForm">
            <div class="row">
                <div class="col text-center login-logo-row">
                    <img src="../../../assets/qfecard-logo-white.svg" alt="logo">
                </div>
            </div>
            <div v-if="!success">
                <div class="text-center mb-5">
                    <h2 class="mt-0 color-secondary font-bebas-neue">Get free 14-days trial</h2>
                    <p class="mt-0">Unlock a free 14-days trial for your company and enjoy the advantage of an unlimited number of employees with digital b-cards. Simply leave your email here-below, and we will send you the trial details. <br/><br/>No credit card details or pre-payments are required!</p>
                    <transition name="fade">
                        <div v-if="form.promo && promo.description" class="py-2 bg-primary color-white mb-1">
                           <small>Using promo code {{ form.promo }}</small> <br/> <p class="wrap-balance my-1" v-html="promo.description"></p>
                        </div>
                    </transition>
                </div>
                <div class="row justify-content-center mb-3">
                    <div class="col-10">
                        <input-field name="email"
                                     id="email"
                                     type="email"
                                     :error="error"
                                     :value="form.email"
                                     v-model="form.email">{{$ml.get('EMAIL')}}
                        </input-field>
                    </div>
                </div>
                <div class="row justify-content-center mb-3">
                    <div>
                        <button-field color="secondary"
                                      type="submit"
                                      icon="paper-plane"
                                      block
                                      :disabled="loading"
                                      :loading="loading">
                            {{$ml.get('REQUEST_TRIAL')}}
                        </button-field>
                    </div>
                </div>
            </div>
            <div v-if="success">
                <div class="row align-items-center">
                    <div class="col text-center">
                        <h3 class="color-secondary">{{$ml.get('request_trial_thanks')}}</h3>
                        <p v-html="$ml.get('request_trial_success')"></p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: "RequestTrial",
        data() {
            return {
                form: {
                    email: '',
                    promo: null
                },
                promo: {
                    code: '',
                    message: '',
                    error: ''
                },
                loading: false,
                success: false,
                error: ''
            }
        },
        mounted() {
            let urlParams = new URLSearchParams(window.location.search);
            let promoCode = urlParams.get('promo');

            if(promoCode){
                this.$store.dispatch('checkPromo', {code: promoCode}).then(response => {
                    if (response.data && response.data.code) {
                        this.promo = response.data;
                        this.form.promo = promoCode;
                    }
                });
            }
        },
        methods: {
            submitForm() {
                if (!this.loading) {
                    this.loading = true;

                    this.error = '';
                    this.$store.dispatch('requestTrial', this.form).then(response => {
                        if (parseInt(response.status) === 200) {
                            this.success = true;
                        } else {
                            this.error = this.$ml.get(`passwordForgottenError${response.status}`)
                        }
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .container {
        min-height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    h3 {
        margin-top: 11px;
        margin-bottom: 16px;
    }
    .auth-form{
        max-width: 700px;
    }
</style>
