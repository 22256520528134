export const globalActions = {
	async loadUserData({ commit, dispatch }) {
		commit('setUserDataLoaded', true);
		try {
			await dispatch('currentUser')
			await dispatch('getCompany');
		} catch (error) {
			throw error;
		}
	}
}
