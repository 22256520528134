import Vue from 'vue';
import { extend } from 'vee-validate';
import {
	required as rule_required,
	email as rule_email,
	min as rule_min,
	min_value as rule_min_value,
	confirmed as rule_confirmed,
	regex as rule_regex,
	between as rule_between,
	alpha as rule_alpha,
	integer as rule_integer,
	digits as rule_digits,
	alpha_dash as rule_alpha_dash,
	alpha_num as rule_alpha_num,
	length as rule_length,
} from 'vee-validate/dist/rules';

import {
	validatorPositive,
	validatorUrlValidator,
	validatorPassword,
	validatorCreditCard,
	validatorPhoneNumber,
} from './validators';

// Create a Vue instance to access $ml
const vm = new Vue({
	methods: {
		translate(key, params = {}) {
			if (this.$ml) {
				const translation = this.$ml.get(key);
				return translation.replace(/{(\w+)}/g, (match, key) => params[key] || match);
			}
			return key;
		}
	}
});

// Function to get translation
const translate = (key, params = {}) => {
	return vm.translate(key, params);
};

// General rules
export const required = extend('required', {
	...rule_required,
	message: (_, values) => translate('field_required', { _field_: values._field_ })
});

export const email = extend('email', {
	...rule_email,
	message: (_, values) => translate('field_invalid', { _field_: values._field_ })
});

export const min = extend('min', rule_min);
export const minValue = extend('min-value', rule_min_value);
export const confirmed = extend('confirmed', rule_confirmed);
export const regex = extend('regex', rule_regex);
export const between = extend('between', rule_between);
export const alpha = extend('alpha', rule_alpha);
export const integer = extend('integer', rule_integer);
export const digits = extend('digits', rule_digits);
export const alphaDash = extend('alpha-dash', rule_alpha_dash);
export const alphaNum = extend('alpha-num', rule_alpha_num);
export const length = extend('length', rule_length);

// Custom rules
export const phoneNumber = extend('phoneNumber', {
	validate: validatorPhoneNumber,
	message: (_, values) => translate('invalid_phone_number', { _field_: values._field_ })
});
export const positive = extend('positive', {
	validate: validatorPositive,
	message: (_, values) => translate('please_enter_positive_number', { _field_: values._field_ })
});

export const credit = extend('credit-card', {
	validate: validatorCreditCard,
	message: (_, values) => translate('invalid_credit_card', { _field_: values._field_ })
});

export const password = extend('password', {
	validate: validatorPassword,
	message: (_, values) => translate('password_requirements', { _field_: values._field_ })
});

export const passwordRule = extend('passwordRule', {
	validate: validatorPassword,
	message: (_, values) => translate('password_requirements', { _field_: values._field_ })
});

export const url = extend('url', {
	validate: validatorUrlValidator,
	message: (_, values) => translate('field_invalid', { _field_: values._field_ })
});
