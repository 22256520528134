<template>
    <div class="send-card-employees">

        <h3 class="text-center">
            {{$ml.get('SEND_EMPLOYEE_CARDS_TITLE')}}
        </h3>

        <div v-if="!loading" class="normal-contents">
            <div class="font-bebas-neue">
                {{$ml.get('EMPLOYEE_RECIPIENTS')}}
            </div>

            <ul class="bulk-list action">
                <li
                    v-for="employee in selectedEmployees"
                    :key="employee.id">

                    <div>
                    <span class="profile-avatar text-center mr-2">
                        <img :src="employee.attributes.profilePicture._url"
                             :alt="employee.attributes.firstName + ' ' + employee.attributes.lastName"
                             v-if="employee.attributes.profilePicture">
                        <font-awesome-icon v-else color="gray" icon="user" />
                    </span>
                        <span>{{employee.attributes.firstName}}&nbsp;{{employee.attributes.lastName}}</span>
                    </div>
                </li>
            </ul>

            <div class="row pt-4 justify-content-center">
                <div class="col-6">
                    <div class="pt-4 pb-4">
                        <checkbox-field color="secondary"
                                        name="confirm"
                                        id="confirm"
                                        :value="confirm"
                                        v-model="confirm">
                            {{$ml.get('SHARE_CARDS_CHECKBOX')}}
                        </checkbox-field>
                    </div>
                    <button-field color="secondary"
                                  icon="share-square"
                                  :loading="loading"
                                  :disabled="loading"
                                  block
                                  @click="sendCards(selectedEmployeeIds)">{{$ml.get('SEND_CARDS')}}
                    </button-field>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div v-if="loading" class="progress text-center">
                <label for="card-progress" class="text-center">{{$ml.get('SENDING_CARDS')}}</label>
                <div id="card-progress" class="progress-bar">
                    <span :style="{width: progress +'%'}"></span>
                </div>

                <div class="text-center" v-html="progressText"></div>
            </div>
        </transition>

    </div>
</template>

<script>
    export default {
        name: "EmployeeCards_Share",
        computed: {
            selectedEmployees() {
                return this.$store.getters.selectedModalData('employeeCards');
            },
            selectedEmployeeIds() {
                let employeeObjects = this.$store.getters.selectedModalData('employeeCards');
                let EmployeeIds = employeeObjects.map(obj => {
                    return obj.id;
                });
                return EmployeeIds;
            }
        },
        data() {
            return {
                confirm: false,
                loading: false,
                progress: 0,
                progressText: "<em>"+this.$ml.get('Preparing_to_send')+ '...'+"</em>"
            }
        },
        methods: {

            async sendCards(allIds) {
                if (!this.loading) {
                    this.loading = true;

                    let completedCount = 0;

                    // Create a helper function to handle sending a single card and updating the progress
                    const sendSingleCard = async (id) => {
                        try {
                            await this.$store.dispatch('shareCard', {id: id, resetLink: this.confirm});
                            completedCount++;
                            this.progress = ((completedCount / allIds.length) * 100).toFixed(0);
                            this.progressText = `${completedCount} out of ${allIds.length} cards sent.`;
                            this.$store.dispatch('getEmployeeCards'); // Refresh the list of cards

                        } catch (e) {
                            this.$notify({
                                group: 'notification',
                                type: 'error',
                                title: this.$ml.get('notification_error_title'),
                                text: `${this.$ml.get('notification_error_general')} - Failed sending ${id}`,
                            });
                            self.$checkTokenExp(e);
                        }
                    };

                    // Process the IDs in batches of 5, sending them in parallel
                    for (let i = 0; i < allIds.length; i += 6) {
                        const batch = allIds.slice(i, i + 6);
                        await Promise.all(batch.map(id => sendSingleCard(id)));
                    }

                    this.$notify({
                        group: 'notification',
                        type: 'success',
                        text: `${completedCount}/${allIds.length} ${this.$ml.get('notification_share_card')}`,
                    });


                    let self = this;
                    setTimeout(() => {
                        self.$store.commit('toggleDashboardModal');
                        self.loading = false;
                    }, 1000);

                }
            }

        }
    }
</script>

<style scoped>
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
        padding-top: 8px;
        padding-bottom: 2px;
        border-bottom: 1px solid var(--bg-grey);
    }
    .send-card-employees{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .progress {
        margin-top: auto;
        margin-bottom: auto;
    }
    .progress-bar {
        background-color: whiteSmoke;
        border-radius: 10px;
        box-shadow: 0 2px 3px rgba(0,0,0,0.1) inset;
        width: 50%;
        margin: 20px auto;
        height: 10px;
        overflow: hidden;
    }

    .progress-bar span {
        background: linear-gradient(90deg, var(--color-secondary-dark) 0%, var(--color-secondary-dark) 100%) !important;
        border-radius: 10px;
        display: block;
        text-indent: -9999px;
        height: 100%;
        position: relative;
        -webkit-transition: width .5s ease;
        -moz-transition: width .5s ease;
        transition: width .5s ease;
    }

    .progress-bar span:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.25, rgba(0, 0, 0, .2)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(0, 0, 0, .2)), color-stop(.75, rgba(0, 0, 0, .2)), color-stop(.75, transparent), to(transparent) );
        background-image: -moz-linear-gradient( -45deg, rgba(0, 0, 0, .2) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .2) 50%, rgba(0, 0, 0, .2) 75%, transparent 75%, transparent );
        z-index: 1;
        -webkit-background-size: 20px 20px;
        -moz-background-size: 20px 20px;
        background-size: 20px 20px;
    }

    ul.bulk-list.action {
        overflow-y: auto;
    }

    .normal-contents {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-height: calc(100% - 80px);
    }

    .row.pt-4.justify-content-center {
        margin-top: auto;
    }
</style>
