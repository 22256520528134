<template>
    <form @submit.prevent="submitForm">
        <h3 class="text-center">
            {{(id == null)?$ml.get('ADD_NEW_INVOICE'):$ml.get('EDIT_INVOICE')}}
        </h3>

        <div v-if="id == null" class="dz-profile-pic">
            <span class="more-info" v-tooltip="$ml.get('tooltip_invoice_file')">
                <font-awesome-icon icon="info-circle"/>
            </span>
            <dropzone-field name="attachment"
                            id="attachment"
                            :accept="`application/pdf`"
                            :required="false"
                            v-model="fileTrigger">
                <template v-if="previewFiles.attachment">
                    <div v-html="$ml.get('invoiceDropzone')">{{previewFiles.attachment.name}}</div>
                </template>
                <template v-else>
                    <div v-html="$ml.get('invoiceDropzone')"></div>
                </template>
            </dropzone-field>
            <hr/>

        </div>

        <div class="row">
            <div class="col-6">
                <input-field :id="`invoice-number`"
                             :name="'invoice-number'"
                             :value="form.number"
                             :required="true"
                             @validation-result="validationCollector"
                             v-model="form.number">{{$ml.get('INVOICE_NUMBER')}}
                </input-field>
            </div>
            <div class="col-6">
                <date-field :id="`issue-date`"
                            :name="'issue-date'"
                            type="date"
                            :value="form.issuedOn"
                            v-model="form.issuedOn">{{$ml.get('ISSUE_DATE')}}
                </date-field>
            </div>

        </div>

        <!-- AMOUNTS -->
        <div class="row">
            <div class="col-6">
                <input-field :id="`invoice-amount`"
                             :name="'invoice-amount'"
                             :type="'text'"
                             :value="form.amount"
                             :required="true"
                             @validation-result="validationCollector"
                             v-model="form.amount">{{$ml.get('INVOICE_AMOUNT_WITH_VAT')}}
                </input-field>
            </div>

            <div class="col-6">
                <input-field :id="`invoice-netAmount`"
                             :name="'invoice-netAmount'"
                             :value="form.netAmount"
                             :type="'text'"
                             :required="true"
                             @validation-result="validationCollector"
                             v-model="form.netAmount">{{$ml.get('INVOICE_NET_AMOUNT')}}
                </input-field>
            </div>
        </div>

        <!-- CURRENCY AND PAID ON DATE -->
        <div class="row">
            <div class="col-6">
                <select-field
                    :id="`invoice-currency`"
                    :name="`invoice-currency`"
                    :value="form.currency"
                    :options="form.select.options"
                    v-model="form.currency">{{$ml.get('CURRENCY')}}
                </select-field>
            </div>

            <div class="col-6">
                <date-field :id="`issue-date`"
                             :name="'issue-date'"
                             type="number"
                             :autocomplete="'off'"
                             :value="form.paidOn"
                             v-model="form.paidOn">{{$ml.get('PAID_ON')}}
                </date-field>
            </div>
        </div>

        <!-- REVOLUT LINK -->
        <div class="row">
            <div class="col-12">
                <input-field :id="`revolut-link`"
                             :name="'revolut-link'"
                             :value="form.revolutLink"
                             v-model="form.revolutLink">{{$ml.get('REVOLUT_LINK')}}
                </input-field>
            </div>
        </div>


        <div class="row pt-4 justify-content-center">
            <div class="col-6">
                <button-field color="secondary"
                              :icon="(id == null)?'plus':'save'"
                              type="submit"
                              :disabled="isFormDisabled"
                              block>{{(id == null)?$ml.get('ADD'):$ml.get('SAVE')}}
                </button-field>
            </div>
        </div>
    </form>
</template>

<script>

    export default {
        name: "InvoiceForm",
        computed: {
            id(){
              let invObj = this.$store.getters.selectedModalData('selectedInvoices');
              if(invObj) {
                  return invObj.id;
              }
              else {
                  return null;
              }
            },
            selectedInvoice() {
                return this.$store.getters.selectedModalData('selectedInvoices');
            },
            addresses() {
                let addresses = [];
                if(this.$store.state.officeAddresses){
                    this.$store.state.officeAddresses.forEach((addr) => {
                        let element = {
                            value: addr.id,
                            text: addr.attributes.officeLabel
                        };
                        addresses.push(element);
                    })
                }
                return addresses;
            },
            isFormDisabled(){
                return this.errors.length > 0;
            },
            clientId(){
                return this.$store.state.cardCompany.id;
            }
        },
        data() {
            let initialAttr = this.$store.getters.selectedModalData('selectedInvoices');
            return {
                fileTrigger: {},
                previewFiles: {
                    attachment: initialAttr && initialAttr.attributes.attachment ? initialAttr.attributes.attachment._url : ''
                },
                imgFiles: {
                    attachment: null
                },
                form: {
                    number: initialAttr ? initialAttr.attributes.number : '',
                    currency:  initialAttr ? initialAttr.attributes.currency : 'EUR',
                    issuedOn:  (initialAttr && initialAttr.attributes.issuedOn) ? initialAttr.attributes.issuedOn.toISOString().slice(0, 10) : '',
                    paidOn:  (initialAttr && initialAttr.attributes.paidOn) ? initialAttr.attributes.paidOn.toISOString().slice(0, 10) : '',
                    notes: initialAttr ? initialAttr.attributes.notes : '',
                    statusOptions: [
                        { value: 'paid', text: 'Paid' },
                        { value: 'unpaid', text: 'Unpaid' },
                        { value: 'overdue', text: 'Overdue' },
                    ],

                    select: {
                        selectError: '',
                        options: [
                            { value: 'EUR', text: 'Euro (€)' },
                            { value: 'BGN', text: 'Bulgarian Leva (BGN)' },
                            { value: 'USD', text: 'United States Dollar ($)' },
                            // { value: 'JPY', text: 'Japanese Yen (¥)' },
                            // { value: 'GBP', text: 'British Pound Sterling (£)' },
                            // { value: 'AUD', text: 'Australian Dollar ($)' },
                            // { value: 'CAD', text: 'Canadian Dollar ($)' },
                            // { value: 'CHF', text: 'Swiss Franc (CHF)' },
                            // { value: 'CNY', text: 'Chinese Yuan (¥)' },
                            // { value: 'SEK', text: 'Swedish Krona (kr)' },
                        ],
                        value: null
                    }
                },
                loading: false,
                errors: []
            }
        },
        created() {
            // this.form.select.options = this.addresses;
            if (this.selectedInvoice !== null) {
                this.form = {...this.form, ...this.selectedInvoice.attributes}
                this.form.select.value = this.form.currency;
            }
            //Set default address if new item
            if(!this.form.select.value) {
                this.form.select.value = this.form.select.options[0].value;
                this.form.currency = this.form.select.options[0].value;
            }
        },
        methods: {
            validationCollector(inputValidation){
                Array.prototype.remove = function() {
                    var what, a = arguments, L = a.length, ax;
                    while (L && this.length) {
                        what = a[--L];
                        while ((ax = this.indexOf(what)) !== -1) {
                            this.splice(ax, 1);
                        }
                    }
                    return this;
                };

                if(inputValidation.valid){
                    this.errors.remove(inputValidation.name);
                }else {
                    if(this.errors.indexOf(inputValidation.name) === -1){
                        this.errors.push(inputValidation.name);
                    }
                }
            },

            generateRandomString(length) {
                let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
                let result = '';
                for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
                return result;
            },

            submitForm() {
                let self = this;
                if (!this.loading) {

                    this.loading = true;

                    this.form.attachment = this.imgFiles.attachment;
                    this.form.company = this.$store.getters.getCardCompany;
                    let invoiceId = this.selectedInvoice ? this.selectedInvoice.id : null;

                    let year = new Date().getFullYear();

                    //console.log("Payload", {id: this.clientId, year: year});

                    this.$store.dispatch('saveInvoice', {id: invoiceId, ...this.form}).then(() => {
                        this.$store.dispatch('getClientInvoices', {id: this.clientId, year: year}).then(() => {
                            this.loading = false;
                            this.$notify({
                                group: 'notification',
                                type: 'success',
                                text: this.$ml.get('notification_success_saved'),
                            });
                            this.$store.commit('toggleDashboardModal');
                        });
                    }).catch((e) => {
                        this.$notify({
                            group: 'notification',
                            type: 'error',
                            title: this.$ml.get('notification_error_title'),
                            text: this.$ml.get('notification_error_general'),
                        });
                        self.$checkTokenExp(e);
                        this.loading = false;
                    });
                }
            }
        },
        watch: {
            // errors(newValue){
            //   //console.log("New errors: ", newValue);
            // },
            fileTrigger: {
                handler(newVal) {
                    if(newVal){
                        this.imgFiles[newVal.field] = newVal.file;
                        this.previewFiles[newVal.field] = newVal.name;
                        this.defaultsClicked = 0;
                    }
                },
                deep: true,
            },
        }
    }
</script>

<style scoped>
.dz-profile-pic {
    position: relative;
    width: 50%;
    margin: 0 auto;
}
</style>
