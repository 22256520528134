var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-selectors"},[(_vm.isCore & _vm.visible)?_c('div',{staticClass:"m-0 selector-holder",attrs:{"id":"agency_selector"}},[_c('Select2',{ref:"agencySelector",attrs:{"options":_vm.selectAgencies,"settings":{
                    templateResult: _vm.companyPreview,
                    templateSelection: _vm.selectionPreview,
                    escapeMarkup: function(stuff){return stuff},
                    //initSelection: bindOptGroupClickEvent
                }},on:{"select":function($event){return _vm.agencyPicked($event)}},model:{value:(_vm.activeAgencyId),callback:function ($$v) {_vm.activeAgencyId=$$v},expression:"activeAgencyId"}})],1):_vm._e(),(_vm.visible && !_vm.agencyOnly)?_c('div',{staticClass:"m-0 selector-holder",attrs:{"id":"company_selector"}},[_c('Select2',{ref:"select2Component",attrs:{"options":_vm.selectCompanies,"settings":{
                    templateResult: _vm.companyPreview,
                    templateSelection: _vm.selectionPreview,
                    escapeMarkup: function(stuff){return stuff},
                    //initSelection: bindOptGroupClickEvent
                }},on:{"select":function($event){return _vm.companyPicked($event)}},model:{value:(_vm.activeCompanyId),callback:function ($$v) {_vm.activeCompanyId=$$v},expression:"activeCompanyId"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }